import React from 'react';
import moment from "moment";
import { useSelector } from 'react-redux';
import { formatIndianCurrency } from '../../../helper/ConvertValue'
import { DisclosureNBFCTableBody, DisclosureNBFCTableHead } from './DisclosureHelper';




export function NBFC_32p7_32p13({ data, subIndex, HandleChange, calculateTotal, dis_index_id }) {
    const reduxProjectData = useSelector((initialState) => initialState?.DisclosureReducer?.projectData || {});

    const GrandTotal = (key, indexes) => {
        if (key === "cy") {
            return (calculateTotal(data, "distribution_cy", { start: indexes?.start, end: indexes?.end }) +
                + calculateTotal(data, "retail_finance_cy", { start: indexes?.start, end: indexes?.end })
                + calculateTotal(data, "wholesale_finance_cy", { start: indexes?.start, end: indexes?.end })
            )
        } else {
            return (calculateTotal(data, "distribution_py", { start: indexes?.start, end: indexes?.end })
                + calculateTotal(data, "retail_finance_py", { start: indexes?.start, end: indexes?.end })
                + calculateTotal(data, "wholesale_finance_py", { start: indexes?.start, end: indexes?.end })
            )
        }
    }

    const calculateProfitBeforeTax = (data, key) => GrandTotal(key, { start: 0, end: 3 }) + calculateTotal(data.filter(ele => ele?.sub_dis_index_id === 4 || ele?.sub_dis_index_id === 5), `total_${key}`);
    const calculateNetProfit = (data, key) => calculateProfitBeforeTax(data, key) - calculateTotal(data.filter(ele => ele?.sub_dis_index_id === 7 || ele?.sub_dis_index_id === 8), `total_${key}`);

    const generateTotalTableRow = (label, start, end) => (
        <tr>
            <td className='text-left pl-3 font-weight-bold text-dark custom-bg-gray-200'>{label}</td>
            <td className='text-right pr-2 font-weight-bold text-dark custom-bg-gray-200'>{formatIndianCurrency(calculateTotal(data, "distribution_cy", { start, end }))}</td>
            <td className='text-right pr-2 font-weight-bold text-dark custom-bg-gray-200'>{formatIndianCurrency(calculateTotal(data, "distribution_py", { start, end }))}</td>
            <td className='text-right pr-2 font-weight-bold text-dark custom-bg-gray-200'>{formatIndianCurrency(calculateTotal(data, "retail_finance_cy", { start, end }))}</td>
            <td className='text-right pr-2 font-weight-bold text-dark custom-bg-gray-200'>{formatIndianCurrency(calculateTotal(data, "retail_finance_py", { start, end }))}</td>
            <td className='text-right pr-2 font-weight-bold text-dark custom-bg-gray-200'>{formatIndianCurrency(calculateTotal(data, "wholesale_finance_cy", { start, end }))}</td>
            <td className='text-right pr-2 font-weight-bold text-dark custom-bg-gray-200'>{formatIndianCurrency(calculateTotal(data, "wholesale_finance_py", { start, end }))}</td>
            <td className='text-right pr-2 font-weight-bold text-dark custom-bg-gray-200'>{formatIndianCurrency(GrandTotal("cy", { start, end }))}</td>
            <td className='text-right pr-2 font-weight-bold text-dark custom-bg-gray-200'>{formatIndianCurrency(GrandTotal("py", { start, end }))}</td>
            <DisclosureNBFCTableBody isEmpty={true} refData={[]} isReference={""} />
        </tr>
    );
    return (
        <>
            <thead>
                <tr>
                    <th className='text-left w-50 pl-3 border-top-0 border-bottom-0' rowSpan={2}>Business Segments </th>
                    <th className='w-auto border-top-0 border-bottom-0' colSpan={2}>Distribution</th>
                    <th className='w-auto border-top-0 border-bottom-0' colSpan={2}>Retail Finance</th>
                    <th className='w-auto border-top-0 border-bottom-0' colSpan={2}>Wholesale Finance</th>
                    <th className='w-auto border-top-0 border-bottom-0' colSpan={2}>Total</th>
                    <DisclosureNBFCTableHead isEmpty={false} />
                </tr>
                <tr>
                    <th className='w-auto border-top-0 border-bottom-0' > Year
                        ended {" "} {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
                    <th className='w-auto border-top-0 border-bottom-0' > Year
                        ended {" "} {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
                    <th className='w-auto border-top-0 border-bottom-0' > Year
                        ended {" "} {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
                    <th className='w-auto border-top-0 border-bottom-0' > Year
                        ended {" "} {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
                    <th className='w-auto border-top-0 border-bottom-0' > Year
                        ended {" "} {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
                    <th className='w-auto border-top-0 border-bottom-0' > Year
                        ended {" "} {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
                    <th className='w-auto border-top-0 border-bottom-0' > Year
                        ended {" "} {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
                    <th className='w-auto border-top-0 border-bottom-0' > Year
                        ended {" "} {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
                    <DisclosureNBFCTableHead isEmpty={true} />
                </tr>
            </thead>
            <tbody>
                {
                    data &&
                    data.length > 0 &&
                    data.map((subDis, subDisIndex) => (
                        <>
                            <tr>
                                <InputCell
                                    value={subDis?.header}
                                    name="header"
                                    onChange={HandleChange}
                                    subIndex={subIndex}
                                    subDisIndex={subDisIndex}
                                    type={"text"}
                                />
                                <InputCell
                                    value={formatIndianCurrency(subDis?.distribution_cy)}
                                    name="distribution_cy"
                                    readOnly={typeof subDis?.distribution_cy !== "number"}
                                    onChange={HandleChange}
                                    subIndex={subIndex}
                                    subDisIndex={subDisIndex}
                                    type="text"
                                />
                                <InputCell
                                    value={formatIndianCurrency(subDis?.distribution_py)}
                                    name="distribution_py"
                                    readOnly={typeof subDis?.distribution_py !== "number"}
                                    onChange={HandleChange}
                                    subIndex={subIndex}
                                    subDisIndex={subDisIndex}
                                    type="text"
                                />
                                <InputCell
                                    value={formatIndianCurrency(subDis?.retail_finance_cy)}
                                    name="retail_finance_cy"
                                    readOnly={typeof subDis?.retail_finance_cy !== "number"}
                                    onChange={HandleChange}
                                    subIndex={subIndex}
                                    subDisIndex={subDisIndex}
                                    type="text"
                                />
                                <InputCell
                                    value={formatIndianCurrency(subDis?.retail_finance_py)}
                                    name="retail_finance_py"
                                    readOnly={typeof subDis?.retail_finance_py !== "number"}
                                    onChange={HandleChange}
                                    subIndex={subIndex}
                                    subDisIndex={subDisIndex}
                                    type="text"
                                />
                                <InputCell
                                    value={formatIndianCurrency(subDis?.wholesale_finance_cy)}
                                    name="wholesale_finance_cy"
                                    readOnly={typeof subDis?.wholesale_finance_cy !== "number"}
                                    onChange={HandleChange}
                                    subIndex={subIndex}
                                    subDisIndex={subDisIndex}
                                    type="text"
                                />
                                <InputCell
                                    value={formatIndianCurrency(subDis?.wholesale_finance_py)}
                                    name="wholesale_finance_py"
                                    readOnly={typeof subDis?.wholesale_finance_py !== "number"}
                                    onChange={HandleChange}
                                    subIndex={subIndex}
                                    subDisIndex={subDisIndex}
                                    type="text"
                                />
                                <td className={`${(subDis?.sub_dis_index_id === 6 || subDis?.sub_dis_index_id === 9) ? "font-weight-bold " : ""}border-top-0 border-bottom-0 text-right pr-2`}>
                                    {
                                        [4, 5, 7, 8].includes(subDis?.sub_dis_index_id) ?
                                            <input
                                                style={{ backgroundColor: "inherit", }}
                                                className="w-100 border-0 text-right ml-2"
                                                type={"text"}
                                                readOnly={typeof subDis?.total_cy !== "number"}
                                                value={formatIndianCurrency(subDis?.total_cy)}
                                                name="total_cy"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            /> :
                                            subDis?.sub_dis_index_id === 6 ? formatIndianCurrency(calculateProfitBeforeTax(data, "cy")) :
                                                subDis?.sub_dis_index_id === 9 ? formatIndianCurrency(calculateNetProfit(data, "cy")) :
                                                    subDis?.sub_dis_index_id === 10 ? null :
                                                    formatIndianCurrency(calculateTotal(data, "dis_55_cy", { type: "type_3", index: subDisIndex }))
                                    }
                                </td>

                                <td className={`${(subDis?.sub_dis_index_id === 6 || subDis?.sub_dis_index_id === 9) ? "font-weight-bold" : ""} border-top-0 border-bottom-0 text-right pr-2`}>
                                    {
                                        [4, 5, 7, 8].includes(subDis?.sub_dis_index_id) ?
                                            <input
                                                style={{ backgroundColor: "inherit", }}
                                                className="w-100 border-0 text-right ml-2"
                                                type={"text"}
                                                readOnly={typeof subDis?.total_py !== "number"}
                                                value={formatIndianCurrency(subDis?.total_py)}
                                                name="total_py"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            /> :
                                            subDis?.sub_dis_index_id === 6 ? formatIndianCurrency(calculateProfitBeforeTax(data, "py")) :
                                                subDis?.sub_dis_index_id === 9 ? formatIndianCurrency(calculateNetProfit(data, "py")) :
                                                    subDis?.sub_dis_index_id === 10 ? null :
                                                    formatIndianCurrency(calculateTotal(data, "dis_55_py", { type: "type_3", index: subDisIndex }))
                                    }
                                </td>
                                <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData} 
                                     isReference={subDis?.isReference}  keyValue={7} index={subIndex}  sub_index={subDisIndex} />
                            </tr>
                            {
                                subDis.sub_dis_index_id === 3 && generateTotalTableRow('Results', 0, 3)
                            }

                            {
                                subDis.sub_dis_index_id === 12 && generateTotalTableRow('Total Assets', 10, 12)
                            }

                            {
                                subDis.sub_dis_index_id === 14 && generateTotalTableRow('Total Liabilities & Equity', 12, 14)
                            }
                        </>
                    ))
                }
            </tbody>
        </>
    );
}

const InputCell = ({
    value,
    name,
    readOnly,
    onChange,
    subIndex,
    subDisIndex,
    type,
}) => (
    <td className='border-top-0 border-bottom-0'>
        <input
            style={{ backgroundColor: "inherit" }}
            className={`w-100 border-0 ${value === "0" ? "text-right" : (type === "text" ? "text-left mt-0 p-0 pl-3" : "text-right pr-3")}`}
            type={type}
            readOnly={readOnly}
            value={value}
            name={name}
            onChange={(event) => onChange(event, subIndex, subDisIndex)}
        />
    </td>

);
