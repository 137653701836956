import React, { useEffect, useState } from "react";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import SearchIcon from "../../assets/Searchone.svg";
import filterIcon from "../../assets/filterone.svg";
import CalendarIcon from "../../assets/Calendar.svg";

import {
  Box,
  Tab,
  Tabs,
  Typography,
  Button,
  Card,
  Avatar,
  MenuItem,
  Select,
} from "@mui/material";
import PropTypes from "prop-types";
import Pagination from "@mui/material/Pagination";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FilterLogsModal from "./FilterModal";
import ExportLogsModal from "./ExportModal";
import Sidebar from "./Sidebar.jsx";
import { useHistory } from "react-router";
import { GetAuditLogs } from "../../WorkSpace/Components/ApiServices/apiHandler.js";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AuditLogs = () => {
  const history = useHistory();
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [exportOpen, setExportOpen] = useState(false);
  const [auditData, setAuditData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleApplyFilter = (filters) => {
    setOpen(false);
  };
  const totalPages = Math.ceil(
    (auditData.auditLogs?.length || 0) / rowsPerPage
  );
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); // Reset to first page when changing rows per page
  };
  const paginatedData =
    auditData.auditLogs?.slice(
      (currentPage - 1) * rowsPerPage,
      currentPage * rowsPerPage
    ) || [];
  useEffect(() => {
    getAllAuditData();
  }, []);

  // get All audit api call
  const getAllAuditData = async () => {
    try {
      const res = await GetAuditLogs();
      setAuditData(res.data);
    } catch (error) {
      console.log(error.message, error);
    }
  };

  return (
    <div>
      <div>
        <Sidebar />
      </div>

      <div className="ml-5 px-3">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <p className=" font-size-24 custom-font-500 font-sans ">
            {" "}
            <KeyboardArrowLeftIcon
              sx={{ width: "30px", height: "30px", color: "#205A46",cursor:"pointer" }}
              onClick={() => history.push('/dashboard')}
            />
            Audit Logs
          </p>
          <Tabs
            value={value}
            onChange={(event, newValue) => setValue(newValue)}
            aria-label="basic tabs example"
            variant="standard"
            className="mt-3"
            sx={{
              "& .MuiTabs-flexContainer": {
                borderRadius: 2,
                overflow: "hidden",
              },
              "& button": {
                color: "black",
                fontWeight: "500",
                opacity: "1",
                minWidth: "120px",
                minHeight: "35px", // Set minimum height to 35px
                height: "35px", // Set explicit height to 35px
                border: "2px solid transparent",
              },
              "& button:first-of-type": {
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
              },
              "& button:last-of-type": {
                borderTopRightRadius: "10px",
                borderBottomRightRadius: "10px",
              },
              "& button.Mui-selected": {
                backgroundColor: "#03565A",
                color: "white",
                opacity: "1",
                borderColor: "#03565A",
              },
              "& button:not(.Mui-selected)": {
                borderColor: "#a9e0aa",
              },
            }}
          >
            <Tab
              className="font-sans"
              sx={{
                textTransform: "none",
                fontWeight: "500",
                fontSize: "13px",
                minHeight: "35px", // Set minimum height to 35px
                height: "35px", // Set explicit height to 35px
              }}
              label="List View"
              {...a11yProps(0)}
            />
            <Tab
              className="font-sans"
              sx={{
                textTransform: "none",
                fontWeight: "500",
                fontSize: "13px",
                minHeight: "35px", // Set minimum height to 35px
                height: "35px", // Set explicit height to 35px
              }}
              label="Timeline View"
              {...a11yProps(1)}
            />
          </Tabs>

          <Box display="flex" alignItems="center" gap="5px">
            {value === 0 && (
              <img
                src={SearchIcon}
                alt="Search Icon"
                style={{ cursor: "pointer", width: "18px", height: "18px" }}
              />
            )}
            <img
              src={filterIcon}
              alt="filter Icon"
              style={{ cursor: "pointer", width: "40px", height: "40px" }}
              onClick={() => setOpen(true)}
            />
            <Button
              variant="contained"
              startIcon={<FileDownloadOutlinedIcon />}
              sx={{ width: "132px", height: "40px" }}
              onClick={() => setExportOpen(true)}
              className="radius-10px font-size-16 custom-font-500  custom-common-theme-bg-color font-inter"
            >
              Export
            </Button>
          </Box>
        </Box>
        <Box>
          <CustomTabPanel value={value} index={0}>
            <div
              style={{
                width: "100%",
                overflowX: "auto",
                padding: "16px",
                minHeight: "83vh",
              }}
            >
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr style={{ backgroundColor: "#f5f5f5" }}>
                    <th
                      style={{ color: "#03565A" }}
                      className="text-left font-inter custom-font-700 font-size-14"
                    >
                      USERNAME
                    </th>
                    <th
                      style={{ color: "#03565A" }}
                      className="text-left font-inter custom-font-700 font-size-14"
                    >
                      MODULE
                    </th>
                    <th
                      style={{ color: "#03565A" }}
                      className="text-left font-inter custom-font-700 font-size-14 text-center"
                    >
                      MESSAGE
                    </th>
                    <th
                      style={{ color: "#03565A" }}
                      className="text-left font-inter custom-font-700 font-size-14 text-center"
                    >
                      DATE
                    </th>
                    <th
                      style={{ color: "#03565A" }}
                      className="text-left font-inter custom-font-700 font-size-14 text-center"
                    >
                      TIME
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedData.length > 0 ? (
                    paginatedData.map((log, index) => (
                      <tr
                        key={log._id || index}
                        style={{ borderBottom: "1px solid #ddd" }}
                      >
                        <td className="text-left px-2">
                          <div className="d-flex flex-row align-items-center gap-2">
                            <img
                              src="https://mdbcdn.b-cdn.net/img/new/avatars/2.webp"
                              className="rounded-circle"
                              alt="Avatar"
                              style={{ width: "30px", height: "30px" }}
                            />
                            <div
                              className="p-1 font-sans font-size-14"
                              style={{ color: "black" }}
                            >
                              {log.username}
                            </div>
                          </div>
                        </td>
                        <td
                          className="font-sans font-size-14 text-left px-2"
                          style={{ color: "black" }}
                        >
                          {log.module}
                        </td>
                        <td className="text-left px-2  ">
                          <div className="d-flex d-flex justify-content-between">
                            <p className="mt-0 mb-0 font-size-14 font-sans custom-font-500">
                              {log.message || "No message available"}
                            </p>
                            <p
                              className="mt-0 mb-0 font-size-14 font-sans custom-font-500 "
                              style={{ color: "#2D8861" }}
                            >
                              View more
                            </p>
                          </div>
                        </td>
                        <td className="font-sans font-size-14 custom-font-500">
                          {new Date(log.createdAt).toLocaleDateString()}
                        </td>
                        <td className="font-sans font-size-14 custom-font-500">
                          {new Date(log.createdAt).toLocaleTimeString()}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center">
                        No audit logs found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <div
              style={{
                overflowY: "auto",
                maxHeight: "550px",
                minHeight: "75vh",
              }}
            >
              {paginatedData.length > 0
                ? paginatedData.map((log, index) => (
                    <div
                      key={index}
                      className="d-flex flex-row justify-content-center align-items-center"
                    >
                      <div className="d-flex align-items-center gap-1">
                        <Typography
                          variant="subtitle1"
                          className="font-sans mr-2 font-size-14  custom-font-500 text-dark"
                        >
                          {log.username}
                        </Typography>
                        <Avatar
                          src="https://mdbcdn.b-cdn.net/img/new/avatars/2.webp"
                          alt="Avatar"
                          sx={{ width: 30, height: 30, marginRight: 1 }}
                        />
                      </div>
                      <div className="d-flex flex-column align-items-center position-relative ml-4">
                        <div
                          style={{
                            width: "2px",
                            height: "35px",
                            backgroundColor: "#B3C0BD",
                            position: "absolute",
                            top: "-16px",
                            left: "50%",
                            transform: "translateX(-50%)",
                          }}
                        ></div>
                        <Card
                          className="shadow-sm "
                          style={{
                            backgroundColor: "#EAEEED",
                            borderRadius: "10px",
                            width: "150px",
                            textAlign: "center",
                            padding: "10px",
                            height: "75px",
                            marginTop: "20px",
                          }}
                        >
                          <div className="d-flex align-items-center ml-3 mt-1">
                            <img
                              src={CalendarIcon}
                              alt="filter Icon"
                              style={{
                                cursor: "pointer",
                                width: "15px",
                                height: "15px",
                                color: "#1A4338",
                              }}
                            />
                            <span className="font-sans custom-font-500 font-size-14 text-dark ml-1">
                              {new Date(log.createdAt).toLocaleDateString()}
                            </span>
                          </div>
                          <div className="d-flex align-items-center mt-1 ml-4 ">
                            <AccessTimeIcon
                              fontSize="small"
                              style={{
                                color: "#1A4338",
                                width: "15px",
                                height: "15px",
                              }}
                            />
                            <span className="font-sans custom-font-500 font-size-14 ml-1 text-dark ">
                              {new Date(log.createdAt).toLocaleTimeString()}
                            </span>
                          </div>
                        </Card>
                      </div>
                      <div className="ml-5">
                        <Card
                          className="radius-12px no-shadow"
                          sx={{
                            mt: 3,
                            boxShadow: 1,
                            backgroundColor: "#E9F5EB",
                            border: "2px solid #C2E6D1",
                            width: 600,
                            minHeight: 87,
                            height: "auto", // Allows height to adjust based on content
                            overflow: "visible", // Ensures content is fully displayed
                            wordWrap: "break-word", // Prevents overflow of long words
                          }}
                        >
                          <div className="px-3 py-2">
                            <p
                              variant="body2"
                              className="font-sans text-black font-size-14 text-dark mt-0 mb-0"
                            >
                              {log.message}
                            </p>
                            <p
                              variant="caption"
                              className="font-sans  custom-font-500 font-size-14 mt-0 mb-0"
                            >
                              Module:{" "}
                              <span
                                className="font-weight-bold font-size-14 custom-font-600"
                                style={{ color: "#349F6F" }}
                              >
                                {log.module}
                              </span>
                            </p>
                          </div>
                        </Card>
                      </div>
                    </div>
                  ))
                : ""}
            </div>
          </CustomTabPanel>
        </Box>
        {/* Pagination  */}
        <div
          className="d-flex flex-row justify-content-between"
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",

            padding: "10px 20px",
          }}
        >
          <div className="d-flex flex-row justify-content-between">
            <div className="d-flex align-items-center">
              <div className="pl-2 " style={{ marginLeft: "50px" }}>
                Rows per pages
              </div>
              <div>
                <Select
                  style={{ width: "65px", height: "30px", }}
                  value={rowsPerPage}
                  onChange={handleRowsPerPageChange}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                </Select>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(event, page) => setCurrentPage(page)}
              shape="rounded"
              sx={{
                "& .MuiPaginationItem-root": {
                  color: "black", // Default text color
                },
                "& .Mui-selected": {
                  backgroundColor: "#E4F2E6 !important", // Active button background color
                  color: "#349F6F", // Text color for active button
                },
                "& .Mui-selected:hover": {
                  backgroundColor: "#C2E6D1 !important", // Hover effect for active button
                },
                "& .MuiPaginationItem-previousNext": {
                  display: "none",
                },
              }}
            />
          </div>
          <div style={{ display: "flex", gap: "5px" }}>
            <div
              onClick={handlePreviousPage}
              style={{
                border: "1px solid #76d276",
                borderRadius: "5px",
                padding: "0px 7px",
              }}
            >
              <ChevronLeft
                className="cursor-pointer"
                style={{ color: "gray" }}
              />
            </div>
            <div
              onClick={handleNextPage}
              style={{
                border: "1px solid #76d276",
                borderRadius: "5px",
                padding: "0px 7px",
              }}
            >
              <ChevronRight
                className="cursor-pointer"
                style={{ color: "gray" }}
              />
            </div>
          </div>
        </div>
        <FilterLogsModal
          open={open}
          onClose={() => setOpen(false)}
          onApply={handleApplyFilter}
          setAuditData={setAuditData}
          auditData={auditData}
          getAllAuditData={getAllAuditData}
        />
        <ExportLogsModal
          open={exportOpen}
          onClose={() => setExportOpen(false)}
        />
      </div>
    </div>
  );
};

export default AuditLogs;
