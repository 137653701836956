import React, { useState } from "react";
import { Modal, Box, Typography, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ExportAuditLogs } from "../../WorkSpace/Components/ApiServices/apiHandler";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#F4FAF5",
    borderColor: state.isFocused ? "#C2E6D1" : provided.borderColor,
    boxShadow: state.isFocused ? "0 0 0 0" : provided.boxShadow,
    "&:hover": {
      borderColor: state.isFocused ? "#C2E6D1" : provided["&:hover"].borderColor,
    },
    minHeight: "50px",
    height: "50px",
    borderRadius: "10px",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#F4FAF5" : state.isFocused ? "#F4FAF5" : "white",
    color: "black",
    "&:hover": {
      backgroundColor: "#E0E0E0",
    },
  }),
};

const ExportLogsModal = ({ open, onClose }) => {
  const animatedComponents = makeAnimated();
  const [exportData, setExportData] = useState({ month: "", year: "" });

  const handleExport = (e) => {
    setExportData({ ...exportData, [e.target.name]: e.target.value });
  };

  const handleClose =()=>{
    onClose();
    setExportData({ month: "", year: "" });
  }
  

  const ExportLogs = async () => {
      try {
        const res = await ExportAuditLogs(exportData.month,exportData.year);
        const blob = new Blob([res.data], { type: res.headers["content-type"] });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Audit_Logs_${exportData.month}_${exportData.year}.xlsx`); // File name
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
        handleClose();
      } catch (error) {
        console.log(error.message, error);
      }
    };
    

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 700,
        bgcolor: "background.paper",
        boxShadow: 24,
        borderRadius: 5,
      }}>

        <div className="p-2">
          <Box display="flex" justifyContent="space-between " alignItems="center">
            <Typography variant="h6" className="px-2 py-2 ml-1 font-sans font-size-20 custom-font-500">Export Logs</Typography>
            <IconButton onClick={handleClose} sx={{ color: "#205A46" }} className="px-2 py-2 mr-1 ">
              <CloseIcon />
            </IconButton>
          </Box>
        </div>
        <hr className="mt-0 mb-0" />
        <div className="px-4 py-4">
          <Typography variant="body2" mb={3} className="font-sans font-size-14 text-dark custom-font-500">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dignissim, magna et porttitor.
          </Typography>
          <Box display="flex" gap={2}>
            <Box width="100%">
              <p className="custom-font-500 font-size-14 mt-0 mb-2" style={{ color: "#33584E" }}>Select Month</p>
              <Select
                isMulti={false}
                placeholder="Select Month"
                name="month"
                value={exportData.month ? { value: exportData.month, label: exportData.month } : null}
                onChange={(selectedOption) => handleExport({ target: { name: "month", value: selectedOption?.value } })}
                options={[
                  { value: "January", label: "January" },
                  { value: "February", label: "February" },
                  { value: "March", label: "March" },
                  { value: "April", label: "April" },
                  { value: "May", label: "May" },
                  { value: "June", label: "June" },
                  { value: "July", label: "July" },
                  { value: "August", label: "August" },
                  { value: "September", label: "September" },
                  { value: "October", label: "October" },
                  { value: "November", label: "November" },
                  { value: "December", label: "December" },
                ]}
                components={animatedComponents}
                closeMenuOnSelect={true}
                styles={customStyles}
              />
            </Box>
            <Box width="100%">
              <p className="custom-font-500 font-size-14 mt-0 mb-2" style={{ color: "#33584E" }}>Select Year</p>
              <Select
                isMulti={false}
                placeholder="Select Year"
                name="year"
                value={exportData.year ? { value: exportData.year, label: exportData.year } : null}
                onChange={(selectedOption) => handleExport({ target: { name: "year", value: selectedOption?.value } })}
                options={[
                  { value: "2025", label: "2025" },
                  { value: "2024", label: "2024" },
                  { value: "2023", label: "2023" },
                  { value: "2022", label: "2022" },
                  { value: "2021", label: "2021" },
                  { value: "2020", label: "2020" },
                  { value: "2019", label: "2019" },
                  { value: "2018", label: "2018" },
                ]}
                components={animatedComponents}
                closeMenuOnSelect={true}
                styles={customStyles}
              />
            </Box>
          </Box>
        </div>
        <hr className="mt-0 mb-0" />
        <div className="px-4 py-3">
            <Box display="flex" justifyContent="space-between" >
              <Button onClick={handleClose} sx={{ width: "120px", height: "45px", border: "1px solid #A0DABC" }} className="px-4 font-sans radius-10px  custom-font-500 font-size-16 text-capitalize custom-common-theme-text-color">Cancel</Button>
              <Button className="text-white text-capitalize  font-sans custom-common-theme-bg-color radius-10px  custom-font-500 font-size-16" sx={{ width: "120px", height: "45px", }} onClick={()=>ExportLogs()}>
                Export
              </Button>
            </Box>
          </div>

      </Box>
    </Modal>
  );
};

export default ExportLogsModal;
