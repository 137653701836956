import React, { useState, useEffect } from "react";
import { useTable } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import axios from "axios";
import { motion } from "framer-motion";

import { useHistory } from "react-router-dom";

import { socket } from "../../services/socket/socket";
import * as actionTypes from "../../redux/actionTypes";

import arrowLeftGreen from "../../assets/arrowLeftGreen.svg";
import { Submit } from "../../Components/Button";
import { groupingColumn } from "./groupingColumn";
import { Navbar } from "../../Components/Navbar";
import { groupings } from "../../Components/BalanceSheet/mockGrouping";
import TABLE_MOCK from "../../Components/Table/TABLE_MOCK.json";
import "./preview.css";
import { SelectComponent } from "../../Components/Table/BasicTable";
import { fetchConventionalMode, updateConventionalMode } from "../../redux/actions/TemplateTabs/actionCM";
import { fetchTTGroupData } from "../../redux/actions/TemplateTabs/actionGrouping";

const board = {
  padding: "2rem 4rem",
  minWidth: "1045px",
  backgroundColor: "#E5E5E5",
  minHeight: "calc(100vh - 80px)",
};

const groupingScreen = {
  // backgroundColor:'white',
  width: "100%",
  height: "80vh",
  paddingRight: "15px",
  // overflowY:'scroll',
  borderRadius: "10px",
};

const groupingNav = {
  backgroundColor: "white",
  width: "100%",
  height: "80px",
  borderTopRightRadius: "10px",
  display: "flex",
  justifyContent: "space-between",
  padding: "0 2rem",
  alignItems: "center",
  position: "sticky",
  top: "0",
};
const groupingTable = {
  backgroundColor: "white",
  width: "100%",
  minHeight: "65vh",
  maxHeight: "65vh",
  overflowY: "scroll",
};

const backNav = {
  fontSize: "1.2rem",
  fontWeight: "600",
  color: "#03565A",
  cursor: "pointer",
};

const PreviewPage = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const fSData = useSelector(
    (initialState) => initialState.reducer.currentClassification
  );
  const [fs, setFs] = useState([]);
  const [edit, setEdit] = useState(false);
  const [random, setRandom] = useState("");
  const index = fSData.length;
  const auth = localStorage.getItem("auth_token");
  const project_id = localStorage.getItem("project_id");
  const tb_id = localStorage.getItem("tb_id");

  const TB_ID = useSelector((initialState) => initialState.reducer.tb_id);
  const Name = useSelector(
    (initialState) => initialState.reducer.currentClassificationName
  );
  const py = fSData?.find((data)=>data.hasOwnProperty('header'))?.py ?? 0;
  const cy = fSData?.find((data)=>data.hasOwnProperty('header'))?.cy ?? 0;
  const {checkYearly} = useSelector((state) => state.selectedDragReducer);

  const TTGrouping = useSelector((state) => state.TTGroupingReducer);
  const groupDataRes = TTGrouping.response ?? [];

  const conventionModeData = useSelector(
    (state) => state.ConventionModeReducer
  );


  useEffect(() => {
    fetchFsData();
    getConventionalMode();
    if (!TTGrouping.isSuccess || TTGrouping.set_payload !== JSON.stringify({project_id, tb_id})) {
    dispatch(fetchTTGroupData(project_id, tb_id));
    } 
  }, []);

  useEffect(() => {
    setFs(fSData.filter(data => !data.hasOwnProperty('header')));
  }, [fSData]);

  let headers = {
    "x-auth-token": auth,
  };

  const getConventionalMode = async () => {
    if (
      !conventionModeData.isSuccess ||
      conventionModeData.set_payload !== JSON.stringify({ project_id, tb_id })
    ) {
      dispatch(fetchConventionalMode(project_id, tb_id));
    }
  };

  function HandleSave() {
    fetchFsData();
    setEdit(!edit);
  }

  const fetchFsData = () => {
    axios
      .get(`/api/v1/fs-classification/get-fs/${project_id}/${TB_ID}`, {
        headers,
      })
      .then((response) => {
        const data = response.data.classification_list[Name];
        dispatch({ type: actionTypes.SET_CLASSIFICATION, payload: data });
      })
      .catch((error) => {
        console.log(error.response, "error");
      });
  };


  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns: groupingColumn,
      // data: TABLE_MOCK
      data: fs,
    });

    const handleMap = async (type, row, change, type2) => {
        if(!change)return;
        switch(type){
          case "fs_grp":{
            const mapData = {
              fs_grp: change,
              note_grp: "",
              sub_grp: "",
              note_no: "" 
            }
            let updatedData =  {
              ...row
            }
            if(checkYearly){
              updatedData =  {
                ...updatedData,
                map_data: {
                  ...updatedData.map_data,
                  [type2+'_map']:{...mapData}
                }
              }
            }else{
              updatedData = {...updatedData, map_data: {
                ...updatedData.map_data,
                cy_map: {...mapData},
                py_map: {...mapData}
              }}
            }

            //? written for update data in table locally
            const element = fs.find(ele => ele.lid === row.lid);
            if (element) {
              element.map_data = updatedData.map_data;
            }
            setFs([...fs]);

            dispatch(updateConventionalMode(conventionModeData.set_payload, updatedData))
            return true
          }
          case "note_grp":{
            let parseChange = JSON.parse(change);
            const mapData = {
              fs_grp: row?.map_data?.[type2 + '_map']?.fs_grp || row.fs_grp,
              note_grp: parseChange.notes_grp,
              note_no: parseChange.notes_no,
              sub_grp: "",
            }
            let updatedData =  {
              ...row
            }
            if(checkYearly){
              updatedData =  {
                ...updatedData,
                map_data: {
                  ...updatedData.map_data,
                  [type2+'_map']:{...mapData}
                }
              }
            }else{
              updatedData = {...updatedData, map_data: {
                ...updatedData.map_data,
                cy_map: {...mapData},
                py_map: {...mapData}
              }}
            }
            //? written for update data in table locally
            const element = fs.find(ele => ele.lid === row.lid);
            if (element) {
              element.map_data = updatedData.map_data;
            }
            setFs([...fs]);

            dispatch(updateConventionalMode(conventionModeData.set_payload, updatedData))
            return true
          }
          case "sub_grp":{
            const mapData = {
              fs_grp: row?.map_data?.[type2 + '_map']?.fs_grp || row.fs_grp,
              note_grp: row?.map_data?.[type2 + '_map']?.note_grp || row.note_grp,
              note_no: row?.map_data?.[type2 + '_map']?.note_no || row.note_no,
              sub_grp: change
            }
            let updatedData =  {
              ...row,
            }
            if(checkYearly){
              updatedData =  {
                ...updatedData,
                map_data: {
                  ...updatedData.map_data,
                  [type2+'_map']:{...mapData}
                }
              }
            }else{
              updatedData = {...updatedData, map_data: {
                ...updatedData.map_data,
                cy_map: {...mapData},
                py_map: {...mapData}
              }}
            }
            //? written for update data in table locally
            const element = fs.find(ele => ele.lid === row.lid);
            if (element) {
              element.map_data = updatedData.map_data;
            }
            setFs([...fs]);
            
            dispatch(updateConventionalMode(conventionModeData.set_payload, updatedData))
            return true
          }
          default:
            return false
        }
      }

    const handleGroupData = (type, row, mapType) => {
    let {fs_grp, note_grp} = row.original;
    if(row.original.hasOwnProperty('map_data') && row.original.map_data.hasOwnProperty(mapType)){
        fs_grp = row.original.map_data[mapType].fs_grp;
        note_grp = row.original.map_data[mapType].note_grp;
    }
    
    switch(type){
        case "fs_grp_cy":
        if(TTGrouping.isSuccess){
            return groupDataRes?.fs_grp?.map(fsGrp=> fsGrp.fs_grp)
        }else{
            return []
        }
        case "fs_grp_py":
        if(TTGrouping.isSuccess){
            return groupDataRes?.fs_grp?.map(fsGrp=> fsGrp.fs_grp)
        }else{
            return []
        }
        case "note_grp_cy":
        if(TTGrouping.isSuccess && fs_grp){
            return groupDataRes?.fs_grp?.find(fsGrp => fsGrp.fs_grp === fs_grp)
            .notes_grp.map(notes => {return {notes_grp: notes.notes_grp, notes_no: notes.notes_no}})
        }else{
            return []
        }
        case "note_grp_py":
        if(TTGrouping.isSuccess && fs_grp){
            return groupDataRes?.fs_grp?.find(fsGrp => fsGrp.fs_grp === fs_grp)
            .notes_grp.map(notes => {return {notes_grp: notes.notes_grp, notes_no: notes.notes_no}})
        }else{
            return []
        }
        case "sub_grp_cy":
        if(TTGrouping.isSuccess && fs_grp && note_grp){
            return groupDataRes?.fs_grp?.find(fsGrp => fsGrp.fs_grp === fs_grp)
            ?.notes_grp.find(note => note.notes_grp === note_grp)?.sub_grp.map(subGrp => subGrp.sub_grp)
        }else{
            return []
        }
        case "sub_grp_py":
        if(TTGrouping.isSuccess && fs_grp && note_grp){
            return groupDataRes?.fs_grp?.find(fsGrp => fsGrp.fs_grp === fs_grp)
            ?.notes_grp.find(note => note.notes_grp === note_grp)?.sub_grp.map(subGrp => subGrp.sub_grp)
        }else{
            return []
        }
        default:
        return []
    }
    }
    

  return (
    <>
      <Navbar text="Classification Preview" />
      <motion.div style={board}>
        <div style={groupingScreen}>
          <div style={groupingNav}>
            <div
              style={backNav}
              onClick={() => {
                history.push("/preview");
              }}
            >
              <img src={arrowLeftGreen} style={{ marginRight: "10px" }} />
              {Name}
            </div>
            <div
              style={{ width: "12rem" }}
              onClick={() => {
                HandleSave();
              }}
            >
              {fSData.length === 0 ? (
                <Submit
                  disable
                  value={edit ? "Save Changes" : "Edit Changes"}
                  sm
                />
              ) : (
                <Submit value={edit ? "Save Changes" : "Edit Changes"} sm />
              )}
            </div>
          </div>
          <div
            style={groupingTable}
            id="groupingScreen"
            className="classificationTable"
          >
            {fSData.length === 0 ? (
              // "no"
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  minHeight: "60vh",
                  alignItems: "center",
                }}
              >
                <h1
                  style={{
                    fontWeight: "500",
                    fontSize: "20px",
                    color: "lightgray",
                  }}
                >
                  No Ledgers Mapped to current Classification: {Name}
                </h1>
              </div>
            ) : (
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th style={{maxWidth:"200px", minWidth:"200px"}} className="font-sans" {...column.getHeaderProps()}>
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()} className="preview-page">
                  {rows.map((row, ind) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell, i) => {
                          if (cell.column.Header == "ADJUSTED AMT") {
                            if (cell.row.original.adjusted_amount === 0) {
                              return (
                                <td
                                  style={{
                                    textAlign: "left",
                                    padding: "10px",
                                  }}
                                >
                                  -
                                </td>
                              );
                            }
                            console.log(
                              cell.row.original.isAdjusted,
                              "cellcellcellcellcellcell"
                            );

                            return (
                              <td
                                style={{ textAlign: "right", padding: "10px" }}
                              >
                                {cell?.row?.original?.adjusted_amount?.toLocaleString(
                                  "en-IN"
                                )}
                              </td>
                            );
                          }
                          if (cell.column.Header == "CURRENT YEAR") {
                            return (
                              <td
                                {...cell.getCellProps()}
                                style={{
                                  textAlign: "right",
                                  paddingRight: "10px",
                                }}
                              >
                                {cell?.row?.original?.cy?.toLocaleString(
                                  "en-IN"
                                )}
                              </td>
                            );
                          }
                          if (cell.column.Header == "PREVIOUS YEAR") {
                            return (
                              <td
                                {...cell.getCellProps()}
                                style={{
                                  textAlign: "right",
                                  paddingRight: "10px",
                                }}
                              >
                                {cell?.row?.original?.py?.toLocaleString(
                                  "en-IN"
                                )}
                              </td>
                            );
                          }

                          if (cell.column.Header == "FINAL AMT") {
                            if (cell.row.original.isAdjusted) {
                              return (
                                <td
                                  style={{
                                    textAlign: "right",
                                    padding: "10px",
                                  }}
                                >
                                  {(
                                    cell?.row?.original?.cy -
                                    cell?.row?.original?.adjusted_amount
                                  ).toLocaleString("en-IN")}
                                </td>
                              );
                            }
                            console.log(
                              cell.row.original.isAdjusted,
                              "cellcellcellcellcellcell"
                            );

                            return (
                              <td
                                style={{ textAlign: "right", padding: "10px" }}
                              >
                                {cell.row.original?.cy.toLocaleString("en-IN")}
                              </td>
                            );
                          }
                          if (cell.column.Header == "FS GROUPING (CY)") {
                            return (
                              <td
                                {...cell.getCellProps({
                                  style: {
                                    minWidth: cell.column.minWidth,
                                    width: cell.column.width,
                                  },
                                })}
                                className="dropMapping"
                              >
                                <div className="visible">
                                <SelectComponent 
                                    rowData={row} 
                                    type={"fs_grp"}
                                    options={handleGroupData("fs_grp_cy", row, "cy_map")}
                                    handleChange={handleMap}
                                    checkYearly={checkYearly}
                                    type2={"cy"}
                                />
                                </div>
                              </td>
                            );
                          }
                          if (cell.column.Header == "NOTES GROUPING (CY)") {
                            return (
                              <td
                                {...cell.getCellProps({
                                  style: {
                                    minWidth: cell.column.minWidth,
                                    width: cell.column.width,
                                  },
                                })}
                                className="dropMapping"
                              >
                                <div className="visible">
                                <SelectComponent 
                                    rowData={row} 
                                    type={"note_grp"}
                                    options={handleGroupData("note_grp_cy", row, "cy_map")}
                                    handleChange={handleMap}
                                    checkYearly={checkYearly}
                                    type2={"cy"}
                                />
                                </div>
                              </td>
                            );
                          }
                          if (cell.column.Header == "SUB GROUPING (CY)") {
                            return (
                              <td
                                {...cell.getCellProps({
                                  style: {
                                    minWidth: cell.column.minWidth,
                                    width: cell.column.width,
                                  },
                                })}
                                className="dropMapping"
                              >
                              <div className="visible">
                                <SelectComponent 
                                    rowData={row} 
                                    type={"sub_grp"}
                                    options={handleGroupData("sub_grp_cy", row, "cy_map")}
                                    handleChange={handleMap}
                                    checkYearly={checkYearly}
                                    type2={"cy"}
                                />
                                </div>
                              </td>
                            );
                          }
                          if (cell.column.Header == "FS GROUPING (PY)") {
                            return (
                              <td
                                {...cell.getCellProps({
                                  style: {
                                    minWidth: cell.column.minWidth,
                                    width: cell.column.width,
                                  },
                                })}
                                className="dropMapping"
                              >
                                <div className="visible">
                                <SelectComponent 
                                    rowData={row} 
                                    type={"fs_grp"}
                                    options={handleGroupData("fs_grp_py", row, "py_map")}
                                    handleChange={handleMap}
                                    checkYearly={checkYearly}
                                    type2={"py"}
                                />
                                </div>
                              </td>
                            );
                          }
                          if (cell.column.Header == "NOTES GROUPING (PY)") {
                            return (
                              <td
                                {...cell.getCellProps({
                                  style: {
                                    minWidth: cell.column.minWidth,
                                    width: cell.column.width,
                                  },
                                })}
                                className="dropMapping"
                              >
                                <div className="visible">
                                <SelectComponent 
                                    rowData={row} 
                                    type={"note_grp"}
                                    options={handleGroupData("note_grp_py", row, "py_map")}
                                    handleChange={handleMap}
                                    checkYearly={checkYearly}
                                    type2={"py"}
                                />
                                </div>
                              </td>
                            );
                          }
                          if (cell.column.Header == "SUB GROUPING (PY)") {
                            return (
                              <td
                                {...cell.getCellProps({
                                  style: {
                                    minWidth: cell.column.minWidth,
                                    width: cell.column.width,
                                  },
                                })}
                                className="dropMapping"
                              >
                              <div className="visible">
                                <SelectComponent 
                                    rowData={row} 
                                    type={"sub_grp"}
                                    options={handleGroupData("sub_grp_py", row, "py_map")}
                                    handleChange={handleMap}
                                    checkYearly={checkYearly}
                                    type2={"py"}
                                />
                                </div>
                              </td>
                            );
                          }

                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
          <div
            style={{
              height: "2.5rem",
              backgroundColor: "beige",
              padding: "5px",
              paddingLeft: "2rem",
              display: "flex",
            }}
          >
            <div>
              <p style={{ marginTop: "5px", fontSize: "15px" }}>
                {/* Total Assets */}
                <span
                  style={{ fontSize: "13px", marginLeft: "5px", color: "grey" }}
                >
                  ({index} Items)
                </span>
              </p>
            </div>
            <div>
              <p
                style={{
                  marginTop: "5px",
                  fontSize: "15px",
                  marginLeft: "150px",
                  color: "grey",
                }}
              >
                {cy.toLocaleString("en-IN")}
              </p>
            </div>
            <div>
              <p
                style={{
                  marginTop: "5px",
                  fontSize: "15px",
                  marginLeft: "250px",
                  color: "grey",
                }}
              >
                {py.toLocaleString("en-IN")}
              </p>
            </div>
            <div style={{ marginLeft: "auto", marginRight: "1rem" }}>
              <p style={{ marginTop: "5px", fontSize: "15px", color: "grey" }}>
                {/* *all amount in INR Lakhs */}
              </p>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default PreviewPage;