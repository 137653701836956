
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import swal from 'sweetalert';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AddNewReferenceGN from './Components/AddNewReferenceGN';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from "react-toastify";
import LoaderTwo from '../Common/Loader/LoaderTwo';
import { socket } from '../../services/socket/socket';
import { useDispatch } from 'react-redux';
import { fetchTTBSData } from '../../redux/actions/TemplateTabs/actionBS';
import GeneralNoteEditor from './Components/GeneralNoteEditor';
import { alphaBitArr } from '../../helper/MathematicsCalculation';
import { addNewNote, addNewRef, addNewSubNote, deleteSubNote, getGeneralNotes, getSoftDeletedGN, softDeleteGNote, softRestoreDeleteGNote } from '../../services/api/apiHandler';
import '../Notes/notes.css'
import DeletedSoftNotesModal from './Components/DeletedSoftNotesModal';
import { fetchTTPNLData } from '../../redux/actions/TemplateTabs/actionPNL';

// TODO:- In this page General Editor and GeneralEditorNote2 file not used

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        minHeight: '50vh',


    },
    tabs: {
        maxHeight: '100%',
        borderRight: `1px solid ${theme.palette.divider}`,
        paddingTop: '3rem',
        paddingBottom: '3rem',
        background: 'linear-gradient(45deg, transparent, #E5EEEE)',
        color: 'black',
        fontSize: '2rem',
        width: '260px',
    },
    tab: {

        fontSize: '1rem',
        padding: '12px 12px',
        overflow: 'hidden',
        position: 'relative',
        fontSize: '1rem',
        // maxWidth: '300px',
        // width: '220px',
        boxSizing: 'border-box',
        minHeight: '55px',
        textAlign: 'center',
        flexShrink: 0,
        fontWeight: "500",
        lineHeight: '1.75',
        letterSpacing: '0.02857em',
        textTransform: 'none',
    },
    TabPanel: {
        width: '100%'
    },
}));

const input = {
    boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
    border: "none", height: "2.2rem", padding: "0.3rem"
}
const shadow = {
    boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
}

const heading = {
    width: "100%",
    height: '40px',
    backgroundColor: '#E5EEEE',
    position: 'sticky',
    top: '80px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
}

export default function GeneralNote() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const project_id = localStorage.getItem('project_id');
    const tb_id = localStorage.getItem('tb_id')
    const [value, setValue] = useState(0);
    const [totalNotes, setTotalNotes] = useState([]);
    const [createNodeLoader, setCreateNodeLoader] = useState(false);
    const [isSubNoteLoading, setIsSubNoteLoading] = useState(false);
    const [isRefModal, setIsRefModal] = useState(false);
    const [refDetail, setRefDetail] = useState({
        index: null,
        subIndex: null,
        subDataIndex: null,
        noteNo: null,
        actionType: "",
        refName: ""
    });
    const [isOpen, setIsOpen] = useState(false);
    const [softDeletedNotes, setSoftDeletedNotes] = useState([]);

    useEffect(() => {
        GetGeneralNotes()
        GetSoftDeletedGN(true)
    }, []);

    const GetGeneralNotes = () => {
        getGeneralNotes(project_id).then(response => {
            setTotalNotes(response.data?.data?.data);
        }).catch(error => {
            swal("Error", error.response.data?.error || "Failed to get general notes.", "error");
        })
    }

    const AddNewNote = () => {
        let number = totalNotes.length + 1
        let data = {
            name: `Note ${number}`
        }
        const softDeletedNames = softDeletedNotes.map(item => item.element.name);
        while (softDeletedNames.includes(data.name)) {
            number++;
            data.name = `Note ${number}`;
        }
        setCreateNodeLoader(true);
        addNewNote(project_id, data).then(response => {
            dispatch({ type: "DISCLOSURE_DATA", payload: response?.data?.index_status?.disclosureDataNbfc });
            // socket.emit("request-conventional-mode", { project_id, tb_id })
            // socket.emit("request-mapped-grouping", { project_id, tb_id });
            // socket.emit("request-balance-sheet", { project_id, tb_id });
            // socket.emit("request-pnl-statement", { project_id, tb_id });
            // socket.emit("request-fetch-console-notes", { pid: project_id });
            setTotalNotes(response.data?.data?.data);
            dispatch(fetchTTBSData(project_id, tb_id, "financialstatement"));
            dispatch(fetchTTPNLData(project_id, tb_id));
        }).catch(error => {
            swal("Error", error.response.data?.error || "New Node Added Failed", "error");
        }).finally(() => {
            setCreateNodeLoader(false);
        })
    }

    const AddNewSubNote = (index, subIndex) => {
        setIsSubNoteLoading(true);
        addNewSubNote(project_id, {
            index,
            subIndex
        }).then(response => {
            setTotalNotes(response.data?.updateGeneralNote?.data)
            setValue(1);
        }).catch(error => {
            swal("Error", error.response.data?.error || "New Sub Note Added Failed", "error");
        }).finally(() => {
            setIsSubNoteLoading(false);
        })
    }

    const DeleteSubNote = (index, subIndex, subDataIndex) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(() => {
            deleteSubNote(project_id, {
                index,
                subIndex,
                subDataIndex
            }).then(response => {
                setTotalNotes(response.data?.updateGeneralNote?.data)
                setValue(1);
                toast.success("Note Deleted Successfully.")
            }).catch(error => {
                swal("Error", error.response.data?.error || "New Sub Note Deleted Failed", "error");
            })
        });
    }

    const AddNawRef = () => {
        addNewRef({
            project_id,
            refName: refDetail.refName,
            index: refDetail.index,
            subIndex: refDetail.subIndex,
            subDataIndex: refDetail.subDataIndex,
            actionType: refDetail.actionType
        }).then(response => {
            setTotalNotes(response.data?.updateGeneralNote?.data)
            setValue(refDetail.noteNo);
        }).catch(error => {
            swal("Error", error.response.data?.error || "New Reference Added Failed", "error");
        }).finally(() => {
            setRefDetail(prev => ({ ...prev, refName: "", index: null, subIndex: null, subDataIndex: null, actionType: "", noteNo: null }));
            setIsRefModal(false);
        })
    }

    const HandleEditorChange = (data, index, isInput, subIndex, dataIndex) => {
        const copy = [...totalNotes]

        if (isInput === "input") {
            copy[index].subData[subIndex].data[dataIndex].label = data
        } else if (isInput === "subData") {
            copy[index][isInput][subIndex].message = data
        } else if (isInput === "data") {
            copy[index].subData[subIndex][isInput][dataIndex].message = data
        } else if (isInput === "label") {
            copy[index][isInput] = data
        } else {
            copy[index].message = data
        }

        setTotalNotes(copy);

        socket.emit("save-general-note", {
            project_id,
            contents: copy,
            contentType: "general_note",
        });
    }

    const SoftDeleteNote = (index) => {
        swal({
            title: "Are you sure?",
            text: "This note will be moved to the archive. You can recover it later if needed.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(() => {
            softDeleteGNote({
                project_id,
                index,
            }).then(response => {
                GetGeneralNotes();
                dispatch({ type: "DISCLOSURE_DATA", payload: response?.data?.index_status?.disclosureDataNbfc });
                dispatch(fetchTTBSData(project_id, tb_id, "financialstatement"));
                dispatch(fetchTTPNLData(project_id, tb_id));
                toast.success("Note Deleted Successfully.")
            }).catch(error => {
                swal("Error", error.response.data?.error || "New Sub Note Deleted Failed", "error");
            })
        });
    }

    const GetSoftDeletedGN = (fromAction = false) => {
        getSoftDeletedGN(project_id).then(response => {
            setSoftDeletedNotes(response.data?.deletedData);
            if (!fromAction) {
                setIsOpen(true);
            }
        }).catch(error => {
            swal("Error", error.response.data?.error || "Failed to get soft deleted general notes.", "error");
        })
    }

    const HandleRestore = (index) => {
        softRestoreDeleteGNote({
            project_id,
            index,
        }).then(response => {
            GetSoftDeletedGN(true)
            GetGeneralNotes();
            dispatch({ type: "DISCLOSURE_DATA", payload: response?.data?.index_status?.disclosureDataNbfc });
            dispatch(fetchTTBSData(project_id, tb_id, "financialstatement"));
            dispatch(fetchTTPNLData(project_id, tb_id));
            toast.success("Note Restored Successfully.")
        }).catch(error => {
            swal("Error", error.response.data?.error || "Note Restored Failed", "error");
        })
    }

    return (
        <>
            <div style={{ backgroundColor: "#e5eeee" }} className='d-flex justify-content-end'>
                <div className='rounded deleteBtn mr-2 my-1' style={{ backgroundColor: "white", width: "fit-content", cursor: "pointer" }}
                    onClick={() => { GetSoftDeletedGN() }}
                >
                    <div className='px-4 py-2 font-sans' style={{ color: "#03565a", fontWeight: "600" }}>Deleted Tabs</div>
                </div>
            </div>
            <div className={classes.root}>
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={(event, newValue) => setValue(newValue)}
                    aria-label="Vertical tabs example"
                    className={`font-sans ${classes.tabs}`}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: '#03565A',
                            width: '4px',
                        },
                    }}
                >
                    {totalNotes && totalNotes.length > 0 && totalNotes.map((note, ix) => {
                        return (
                            <Tab key={ix} className={`${classes.tab} font-sans`} style={{ color: "black", fontWeight: "600" }} label={note?.name} {...a11yProps(ix)} />
                        )
                    })}
                    <div style={{ height: '50px', backgroundColor: '', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {!createNodeLoader ? (
                            <p className='font-sans' style={{ color: 'var(--clr-accent)', cursor: 'pointer', fontWeight: '600', fontSize: '16px' }} onClick={() => { AddNewNote() }}>
                                <span className='font-sans'>+ </span>New Notes
                            </p>
                        ) : (
                            <div>
                                <LoaderTwo />
                            </div>
                        )}
                    </div>
                </Tabs>

                {totalNotes && totalNotes.length > 0 && totalNotes.map((note, ix) => {
                    return (
                        <TabPanel key={ix} className={classes.TabPanel} value={value} index={ix}>
                            <div style={{ ...heading, zIndex: 1 }}>
                                <div className='rounded deleteBtn mr-2 my-1 font-sans' style={{ backgroundColor: "white", width: "fit-content", cursor: "pointer" }}
                                    onClick={() => { SoftDeleteNote(ix) }}
                                >
                                    <div className='px-4 py-1 font-sans' style={{ color: "#03565a", fontWeight: "600" }}>Delete Tab</div>
                                </div>
                            </div>
                            <div className='mb-2'>
                                <div className="d-flex justify-content-between align-items-center mt-3">
                                    {note?.isLabelInput ?
                                        <input
                                            style={input}
                                            className='ml-3 rounded w-50'
                                            value={note?.label}
                                            onChange={(e) => HandleEditorChange(e.target.value, ix, "label")}
                                        />
                                        : <p className="mt-0 mb-0 pl-3 font-sans font-size-18"> {note?.label}</p>}
                                    {note?.refData &&
                                        <div className='mr-2'>
                                            <small className="pr-3 font-sans">{note?.refData?.map(ele => ele.reference).join(", ")}</small>
                                            <Tooltip title="Add New Reference" placement='top-end'>
                                                <IconButton onClick={() => {
                                                    setRefDetail(prev => ({ ...prev, index: ix, noteNo: value, actionType: "note" }));
                                                    setIsRefModal(true);
                                                }}>
                                                    <AddIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    }
                                </div>
                                <div className="px-3 mt-1">
                                    <GeneralNoteEditor
                                        data={note?.message}
                                        onChange={
                                            (data) => HandleEditorChange(data, ix, "")
                                        }
                                    />
                                </div>
                                {
                                    note?.subData && note?.subData.length > 0 && note?.subData.map((sub, sx) => (
                                        <div key={sx}>
                                            <div className="d-flex justify-content-between align-items-center mt-3">
                                                {sub?.label && <p className="mt-0 mb-0 pl-3 font-sans font-size-18"> {sub?.label}</p>}
                                                {sub?.refData &&
                                                    <div className='mr-2'>
                                                        <small className="font-sans">{sub?.refData?.map(ele => ele.reference).join(", ")}</small>
                                                        <Tooltip title="Add New Reference" placement='top-end'>
                                                            <IconButton onClick={() => {
                                                                setRefDetail(prev => ({ ...prev, index: ix, subIndex: sx, noteNo: value, actionType: "subNote" }));
                                                                setIsRefModal(true);
                                                            }}>
                                                                <AddIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                }
                                            </div>
                                            {
                                                sub?.isMessage && <div className="px-3 mt-1">
                                                    <GeneralNoteEditor
                                                        data={sub?.message}
                                                        onChange={
                                                            (data) => HandleEditorChange(data, ix, "subData", sx)
                                                        }
                                                    />
                                                </div>
                                            }
                                            {
                                                sub?.data && sub?.data.length > 0 && sub?.data.map((subData, sdx) => (
                                                    <div key={sdx}>
                                                        <div className='mx-5 my-2 p-1 pb-2' style={shadow}>
                                                            <div className='d-flex justify-content-between align-items-center mx-3'>
                                                                <p>{alphaBitArr[sdx] + "."}</p>
                                                                <div className='d-flex justify-content-between ml-2 mt-2 w-100' style={{ gap: "1rem" }}>
                                                                    <input
                                                                        className='w-50'
                                                                        style={input}
                                                                        value={subData?.label}
                                                                        onChange={(e) => HandleEditorChange(e.target.value, ix, "input", sx, sdx)}
                                                                    />
                                                                    <div>
                                                                        <small className="pr-3 font-sans">{subData?.refData?.map(ele => ele.reference).join(", ")}</small>
                                                                        <Tooltip title="Add New Reference" placement='top-end'>
                                                                            <IconButton onClick={() => {
                                                                                setRefDetail(prev => ({ ...prev, index: ix, subIndex: sx, subDataIndex: sdx, noteNo: value, actionType: "subDataNote" }));
                                                                                setIsRefModal(true);
                                                                            }}>
                                                                                <AddIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip title="Delete Note" placement='top-end'>
                                                                            <IconButton onClick={() => DeleteSubNote(ix, sx, sdx)}>
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <GeneralNoteEditor
                                                                data={subData?.message}
                                                                onChange={
                                                                    (data) => HandleEditorChange(data, ix, "data", sx, sdx)
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            {sub.data ?
                                                <button
                                                    style={{ width: "12rem" }}
                                                    className='my-2 deleteBtn bg-white border-0 ml-5 custom-common-theme-text-color custom-font-600 px-3 py-2 rounded'
                                                    onClick={() => AddNewSubNote(ix, sx)}
                                                >
                                                    {isSubNoteLoading ? <div className='d-flex justify-content-center py-1' > <LoaderTwo /> </div> : "+ Create New Note"}
                                                </button> : ""
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        </TabPanel>
                    )
                })}
            </div>
            <AddNewReferenceGN
                isRefModal={isRefModal}
                setIsRefModal={setIsRefModal}
                setRefDetail={setRefDetail}
                refDetail={refDetail}
                AddNawRef={AddNawRef}
            />

            <DeletedSoftNotesModal
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                data={softDeletedNotes}
                HandleRestore={HandleRestore}
            />
        </>
    );
}