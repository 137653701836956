import React from 'react';
import moment from "moment";
import { useSelector } from 'react-redux';
import { DisclosureNBFCTableBody, DisclosureNBFCTableHead } from './DisclosureHelper';
import { formatIndianCurrency } from '../../../helper/ConvertValue';



export function NBFC_35p1({ data, subIndex, dis_index_id, HandleChange, calculateTotal }) {
    const reduxProjectData = useSelector((initialState) => initialState?.DisclosureReducer?.projectData || {});

    return (
        <>
            <thead>
                {[3, 4, 5, 7, 8, 12, 13, 14, 15, 16, 34].includes(dis_index_id) && (
                    <>
                        <tr>
                            <th className='text-left w-75 pl-3 border-top-0 border-bottom-0'style={{minWidth:"110px"}}  >
                                Particulars
                            </th>
                            <th className='w-auto border-top-0 border-bottom-0' style={{minWidth:"110px"}}>
                                As at{""} {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}
                            </th>
                            <th className='w-auto border-top-0 border-bottom-0' style={{minWidth:"110px"}}>
                                As at {""} {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}
                            </th>
                            <DisclosureNBFCTableHead isEmpty={false} />
                        </tr>

                    </>
                )}
                {dis_index_id === 9 && (
                    <>
                        <tr>
                            <th className='text-left w-75 pl-3 border-top-0 border-bottom-0' >Particulars </th>
                            <th className='w-auto border-top-0 border-bottom-0' style={{minWidth:"110px"}}> Weighted Average Exercise Price </th>
                            <DisclosureNBFCTableHead isEmpty={false} />
                        </tr>
                    </>
                )}
                {(dis_index_id === 10 || dis_index_id === 11) && (
                    <>
                        <tr>
                            <th className='w-auto border-top-0 border-bottom-0'>
                                {dis_index_id === 10 ?
                                    `As at ${moment(reduxProjectData.current_year).format("DD MMMM YYYY")}` :
                                    `${moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}`
                                }
                            </th>

                            <th className='w-auto border-top-0 border-bottom-0'></th>
                            <th className='w-auto border-top-0 border-bottom-0'></th>
                            <th className='w-auto border-top-0 border-bottom-0'></th>
                            <th className='w-auto border-top-0 border-bottom-0'></th>
                            <th className='w-auto border-top-0 border-bottom-0'></th>
                            <th className='w-auto border-top-0 border-bottom-0'></th>
                            <th className='w-auto border-top-0 border-bottom-0'></th>
                            <th className='w-auto border-top-0 border-bottom-0'></th>
                            <th className='w-auto border-top-0 border-bottom-0'></th>
                            <th className='w-auto border-top-0 border-bottom-0'></th>
                            <th className='w-auto border-top-0 border-bottom-0'></th>
                            <DisclosureNBFCTableHead isEmpty={false} />
                        </tr>
                        <tr>
                            <th className='text-left w-25 pl-3 border-top-0 border-bottom-0' > Particulars </th>
                            <th className='w-auto border-top-0 border-bottom-0'>1 to 7 days</th>
                            <th className='w-auto border-top-0 border-bottom-0'>1 to 14 days</th>
                            <th className='w-auto border-top-0 border-bottom-0'>1 to 30/31 days</th>
                            <th className='w-auto border-top-0 border-bottom-0'>Over 1 month & upto 2 months</th>
                            <th className='w-auto border-top-0 border-bottom-0'>Over 2 months & upto 3 months</th>
                            <th className='w-auto border-top-0 border-bottom-0'>Over 3 months & upto 6 months</th>
                            <th className='w-auto border-top-0 border-bottom-0'>Over 6 months & upto 1 year</th>
                            <th className='w-auto border-top-0 border-bottom-0'>Over 1 year & upto 3 years </th>
                            <th className='w-auto border-top-0 border-bottom-0'>Over 3 years & upto 5 years</th>
                            <th className='w-auto border-top-0 border-bottom-0'>Over 5 years</th>
                            <th className='w-auto border-top-0 border-bottom-0'>Total</th>
                            <DisclosureNBFCTableHead isEmpty={true} />
                        </tr>
                    </>
                )}
                {[21, 23, 33].includes(dis_index_id) && (
                    <>
                        <tr>
                            <th className='text-left w-75 pl-3 border-top-0 border-bottom-0' >
                                Particulars
                            </th>
                            <th className='w-auto border-top-0 border-bottom-0' style={{minWidth:"110px"}}>
                                For the year ended  {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}
                            </th>
                            <th className='w-auto border-top-0 border-bottom-0' style={{minWidth:"110px"}}>
                                For the year ended {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}
                            </th>
                            <DisclosureNBFCTableHead isEmpty={false} />

                        </tr>
                    </>
                )}
                {(dis_index_id === 24 || dis_index_id === 25) && (
                    <>
                        <tr>
                            <th className='w-auto border-top-0 border-bottom-0'></th>
                            <th className='w-auto border-top-0 border-bottom-0'>
                                {dis_index_id === 24 ?
                                    `For year ended ${moment(reduxProjectData.current_year).format("DD MMMM YYYY")}` :
                                    ` For year ended ${moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}`
                                }
                            </th>

                            <th className='w-auto border-top-0 border-bottom-0'></th>
                            <th className='w-auto border-top-0 border-bottom-0'></th>
                            <th className='w-auto border-top-0 border-bottom-0'></th>
                            <th className='w-auto border-top-0 border-bottom-0'></th>
                            <th className='w-auto border-top-0 border-bottom-0'></th>
                            <DisclosureNBFCTableHead isEmpty={false} />
                        </tr>
                        <tr>
                            <th className='w-auto border-top-0 border-bottom-0'>Sr. No.</th>
                            <th className='text-left pl-3 border-top-0 border-bottom-0' style={{ width: "40%" }} > Grounds of complaints, (i.e. complaints relating to) </th>
                            <th className='w-auto border-top-0 border-bottom-0'>Number of complaints pending at the beginning of the year </th>
                            <th className='w-auto border-top-0 border-bottom-0'>Number of complaints received during the year</th>
                            <th className='w-auto border-top-0 border-bottom-0'>% increase/ decrease in the number of complaints received over the previous year</th>
                            <th className='w-auto border-top-0 border-bottom-0'>Number of complaints pending at the end of the year</th>
                            <th className='w-auto border-top-0 border-bottom-0'>Of 5, number of complaints pending beyond 30 days</th>
                            <DisclosureNBFCTableHead isEmpty={true} />
                        </tr>
                    </>
                )}
                {dis_index_id === 26 && (
                    <>
                        <tr>
                            <th className='text-left w-50 pl- border-top-0 border-bottom-03' >Regulator </th>
                            <th className='w-auto border-top-0 border-bottom-0 text-left pl-3'> Registration No. </th>
                            <DisclosureNBFCTableHead isEmpty={false}/>
                        </tr>
                    </>
                )}
                {dis_index_id === 27 && (
                    <>
                        <tr>
                            <th className='text-left pl-3 border-top-0 border-bottom-0' >Sr.No.</th>
                            <th className='w-auto border-top-0 border-bottom-0 text-left pl-3'>Particulars</th>
                            <th className='w-auto border-top-0 border-bottom-0 text-left pl-3'>Name of the Instrument</th>
                            <th className='w-auto border-top-0 border-bottom-0 text-left pl-3'>For the year ended {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
                            <th className='w-auto border-top-0 border-bottom-0 text-left pl-3'>For the year ended {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
                            <DisclosureNBFCTableHead isEmpty={false}/>

                        </tr>
                    </>
                )}
                {[35, 36, 37, 39, 40, 41].includes(dis_index_id) && (
                    <>
                        <tr>
                            <th className='w-auto border-top-0 border-bottom-0 text-left' rowSpan={2}>Sr.No.</th>
                            <th className='w-50 pl-3 text-left border-top-0 border-bottom-0' rowSpan={2}>Significant counterparty*</th>
                            <th className='w-auto border-top-0 border-bottom-0' colSpan={2} >
                                {[39, 40, 41].includes(dis_index_id) ?
                                    `As at ${moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}` :
                                    `As at ${moment(reduxProjectData.current_year).format("DD MMMM YYYY")}`
                                }

                            </th>
                            <DisclosureNBFCTableHead isEmpty={false}/>
                        </tr>
                        <tr>
                            <th className='w-auto border-top-0 border-bottom-0'>Amount</th>
                            <th className='w-auto border-top-0 border-bottom-0'>% of total liabilities</th>
                            <DisclosureNBFCTableHead isEmpty={true} />
                        </tr>
                    </>
                )}
                {(dis_index_id === 38 || dis_index_id === 42) && (
                    <>
                        <tr>
                            <th className='w-auto border-top-0 border-bottom-0 text-left' rowSpan={2}>Sr.No.</th>
                            <th className='w-75 pl-3 text-left border-top-0 border-bottom-0' rowSpan={2}>Particulars</th>
                            <th className='w-auto border-top-0 border-bottom-0'>
                                {dis_index_id === 38 ?
                                    "As at 31 March 2023" :
                                    `As at ${moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}`
                                }

                            </th>
                            <DisclosureNBFCTableHead isEmpty={false} />
                        </tr>
                        <tr>
                            <th className='w-auto border-top-0 border-bottom-0'>%</th>
                            <DisclosureNBFCTableHead isEmpty={true} />
                        </tr>
                    </>
                )}
            </thead>

            <tbody>
                {data &&
                    data.length > 0 &&
                    data.map((subDis, subDisIndex) => (
                        <>
                            {[3, 4, 5, 7, 8, 12, 13, 14, 15, 16, 21, 23, 33, 34].includes(dis_index_id) && (
                                <>
                                    <tr >
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className={`p-0 m-0 w-100 border-0 border-bottom-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                    }`}
                                                type={"text"}
                                                value={subDis?.header}
                                                name="header"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input
                                                style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 border-bottom-0 pr-3 text-right"
                                                type={"text"}
                                                readOnly={!(typeof subDis?.cy === "number" || typeof subDis?.cy === "string")}
                                                value={formatIndianCurrency(subDis?.cy)}
                                                name="cy"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input
                                                style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={typeof subDis?.py === "number" ? "number" : "text"}
                                                readOnly={!(typeof subDis?.cy === "number" || typeof subDis?.cy === "string")}
                                                value={formatIndianCurrency(subDis?.py)}
                                                name="py"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData} 
                                            isReference={subDis?.isReference}  keyValue={13} index={subIndex}  sub_index={subDisIndex} />
                                    </tr>
                                    {[15, 16].includes(dis_index_id) &&
                                        data.length - 1 === subDisIndex && (
                                            <tr>
                                                <td className='text-left pl-3 font-weight-bold text-dark custom-bg-gray-200'>
                                                    {dis_index_id === 15 && "Total Exposure to Real Estate"}
                                                    {dis_index_id === 16 && "Total exposure to Capital Market"}
                                                </td>
                                                <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateTotal(data, "cy"))} </td>
                                                <td className='text-right pr-3 font-weight-bold text-dark custom-bg-gray-200'> {formatIndianCurrency(calculateTotal(data, "py"))} </td>
                                                <DisclosureNBFCTableBody isEmpty={true} refData={[]} isReference={""} />
                                            </tr>
                                        )
                                    }
                                </>
                            )}
                            {dis_index_id === 9 && (
                                <>
                                    <tr>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                    }`}
                                                type={"text"}
                                                value={subDis?.header}
                                                name="header"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input
                                                style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={typeof subDis?.weighted_avrg_exercise_price === "number" ? "number" : "text"}
                                                readOnly={!(typeof subDis?.weighted_avrg_exercise_price === "number" || typeof subDis?.weighted_avrg_exercise_price === "string")}
                                                value={formatIndianCurrency(subDis?.weighted_avrg_exercise_price)}
                                                name="weighted_avrg_exercise_price"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData} 
                                            isReference={subDis?.isReference}  keyValue={13} index={subIndex}  sub_index={subDisIndex} />
                                    </tr>
                                </>
                            )}
                            {(dis_index_id === 10 || dis_index_id === 11) && (
                                <>
                                    <tr>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                    }`}
                                                type={"text"}
                                                value={subDis?.header}
                                                name="header"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input
                                                style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={typeof subDis["1_to_7days"] === "number" ? "number" : "text"}
                                                readOnly={!(typeof subDis["1_to_7days"] === "number" || typeof subDis["1_to_7days"] === "string")}
                                                value={formatIndianCurrency(subDis["1_to_7days"])}
                                                name="1_to_7days"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input
                                                style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={typeof subDis["8_to_14days"] === "number" ? "number" : "text"}
                                                readOnly={!(typeof subDis["8_to_14days"] === "number" || typeof subDis["8_to_14days"] === "string")}
                                                value={formatIndianCurrency(subDis["8_to_14days"])}
                                                name="8_to_14days"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input
                                                style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={typeof subDis["15_to_30days"] === "number" ? "number" : "text"}
                                                readOnly={!(typeof subDis["15_to_30days"] === "number" || typeof subDis["15_to_30days"] === "string")}
                                                value={formatIndianCurrency(subDis["15_to_30days"])}
                                                name="15_to_30days"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input
                                                style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={typeof subDis["over_1month"] === "number" ? "number" : "text"}
                                                readOnly={!(typeof subDis["over_1month"] === "number" || typeof subDis["over_1month"] === "string")}
                                                value={formatIndianCurrency(subDis["over_1month"])}
                                                name="over_1month"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input
                                                style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={typeof subDis["over_2months"] === "number" ? "number" : "text"}
                                                readOnly={!(typeof subDis["over_2months"] === "number" || typeof subDis["over_2months"] === "string")}
                                                value={formatIndianCurrency(subDis["over_2months"])}
                                                name="over_2months"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input
                                                style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={typeof subDis["over_3months"] === "number" ? "number" : "text"}
                                                readOnly={!(typeof subDis["over_3months"] === "number" || typeof subDis["over_3months"] === "string")}
                                                value={formatIndianCurrency(subDis["over_3months"])}
                                                name="over_3months"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input
                                                style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={typeof subDis["over_6months"] === "number" ? "number" : "text"}
                                                readOnly={!(typeof subDis["over_6months"] === "number" || typeof subDis["over_6months"] === "string")}
                                                value={formatIndianCurrency(subDis["over_6months"])}
                                                name="over_6months"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input
                                                style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={typeof subDis["over_1year"] === "number" ? "number" : "text"}
                                                readOnly={!(typeof subDis["over_1year"] === "number" || typeof subDis["over_1year"] === "string")}
                                                value={formatIndianCurrency(subDis["over_1year"])}
                                                name="over_1year"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input
                                                style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={typeof subDis["over_3years"] === "number" ? "number" : "text"}
                                                readOnly={!(typeof subDis["over_3years"] === "number" || typeof subDis["over_3years"] === "string")}
                                                value={formatIndianCurrency(subDis["over_3years"])}
                                                name="over_3years"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input
                                                style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={typeof subDis["over_5years"] === "number" ? "number" : "text"}
                                                readOnly={!(typeof subDis["over_5years"] === "number" || typeof subDis["over_5years"] === "string")}
                                                value={formatIndianCurrency(subDis["over_5years"])}
                                                name="over_5years"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0 text-right pr-3'>
                                            {formatIndianCurrency(calculateTotal(data, null, { type: "type_3", index: subDisIndex }))}
                                        </td>
                                        <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData}
                                             isReference={subDis?.isReference}  keyValue={13} index={subIndex}  sub_index={subDisIndex}/>
                                    </tr>
                                </>
                            )}
                            {(dis_index_id === 24 || dis_index_id === 25) && (
                                <>
                                    <tr>
                                        <td className='border-top-0 border-bottom-0'>{subDis.sr_no}</td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                    }`}
                                                type={"text"}
                                                value={subDis?.header}
                                                name="header"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input
                                                style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={typeof subDis["pending_at_the_beginning_of_the_year"] === "number" ? "number" : "text"}
                                                readOnly={!(typeof subDis["pending_at_the_beginning_of_the_year"] === "number" || typeof subDis["pending_at_the_beginning_of_the_year"] === "string")}
                                                value={formatIndianCurrency(subDis["pending_at_the_beginning_of_the_year"])}
                                                name="pending_at_the_beginning_of_the_year"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input
                                                style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={typeof subDis["received_during_the_year"] === "number" ? "number" : "text"}
                                                readOnly={!(typeof subDis["received_during_the_year"] === "number" || typeof subDis["received_during_the_year"] === "string")}
                                                value={formatIndianCurrency(subDis["received_during_the_year"])}
                                                name="received_during_the_year"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input
                                                style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={typeof subDis["received_over_the_previous_year"] === "number" ? "number" : "text"}
                                                readOnly={!(typeof subDis["received_over_the_previous_year"] === "number" || typeof subDis["received_over_the_previous_year"] === "string")}
                                                value={formatIndianCurrency(subDis["received_over_the_previous_year"])}
                                                name="received_over_the_previous_year"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input
                                                style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={typeof subDis["pending_at_the_end_of_the_year"] === "number" ? "number" : "text"}
                                                readOnly={!(typeof subDis["pending_at_the_end_of_the_year"] === "number" || typeof subDis["pending_at_the_end_of_the_year"] === "string")}
                                                value={formatIndianCurrency(subDis["pending_at_the_end_of_the_year"])}
                                                name="pending_at_the_end_of_the_year"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input
                                                style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={typeof subDis["pending_beyond_30_days"] === "number" ? "number" : "text"}
                                                readOnly={!(typeof subDis["pending_beyond_30_days"] === "number" || typeof subDis["pending_beyond_30_days"] === "string")}
                                                value={formatIndianCurrency(subDis["pending_beyond_30_days"])}
                                                name="pending_beyond_30_days"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData}
                                             isReference={subDis?.isReference}  keyValue={13} index={subIndex}  sub_index={subDisIndex}/>
                                    </tr>
                                </>
                            )}
                            {dis_index_id === 26 && (
                                <>
                                    <tr>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                    }`}
                                                type={"text"}
                                                value={subDis?.header}
                                                name="header"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                    }`}
                                                type={"text"}
                                                value={subDis?.header_2}
                                                name="header_2"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData} 
                                               isReference={subDis?.isReference}  keyValue={13} index={subIndex}  sub_index={subDisIndex}/>
                                    </tr>
                                </>
                            )}
                            {dis_index_id === 27 && (
                                <>
                                    <tr>
                                        <td className='border-top-0 border-bottom-0'>{subDis.sr_no}</td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                    }`}
                                                type={"text"}
                                                value={subDis?.header}
                                                name="header"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                    }`}
                                                type={"text"}
                                                value={subDis?.header_2}
                                                name="header_2"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                    }`}
                                                type={"text"}
                                                value={subDis?.cy_header}
                                                name="cy_header"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                    }`}
                                                type={"text"}
                                                value={subDis?.py_header}
                                                name="py_header"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData} 
                                          isReference={subDis?.isReference}  keyValue={13} index={subIndex}  sub_index={subDisIndex}/>
                                    </tr>
                                </>
                            )}

                            {[35, 36, 37, 39, 40, 41].includes(dis_index_id) && (
                                <>
                                    <tr>
                                        <td className='border-top-0 border-bottom-0'>{subDis.sr_no}</td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                    }`}
                                                type={"text"}
                                                value={subDis?.header}
                                                name="header"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input
                                                style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={typeof subDis["amount"] === "number" ? "number" : "text"}
                                                readOnly={!(typeof subDis["amount"] === "number" || typeof subDis["amount"] === "string")}
                                                value={subDis["amount"]}
                                                name="amount"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input
                                                style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={typeof subDis["total_liabilities"] === "number" ? "number" : "text"}
                                                readOnly={!(typeof subDis["total_liabilities"] === "number" || typeof subDis["total_liabilities"] === "string")}
                                                value={subDis["total_liabilities"]}
                                                name="total_liabilities"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData} 
                                              isReference={subDis?.isReference}  keyValue={13} index={subIndex}  sub_index={subDisIndex}/>
                                    </tr>
                                </>
                            )}

                            {(dis_index_id === 38 || dis_index_id === 42) && (
                                <>
                                    <tr>
                                        <td className='border-top-0 border-bottom-0'>{subDis.sr_no}</td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input style={{ backgroundColor: "inherit" }}
                                                className={`p-0 m-0 w-100 border-0 pl-3 text-left ${subDis?.isHeading && "font-weight-bold"
                                                    }`}
                                                type={"text"}
                                                value={subDis?.header}
                                                name="header"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <td className='border-top-0 border-bottom-0'>
                                            <input
                                                style={{ backgroundColor: "inherit" }}
                                                className="p-0 m-0 w-100 border-0 pr-3 text-right"
                                                type={typeof subDis["percentage"] === "number" ? "number" : "text"}
                                                readOnly={!(typeof subDis["percentage"] === "number" || typeof subDis["percentage"] === "string")}
                                                value={subDis["percentage"]}
                                                name="percentage"
                                                onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                                            />
                                        </td>
                                        <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData} 
                                             isReference={subDis?.isReference} keyValue={13} index={subIndex}  sub_index={subDisIndex}/>
                                    </tr>
                                </>
                            )}

                        </>
                    ))}
            </tbody>
        </>
    );
}