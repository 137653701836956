import React from "react";
import "./nbfcDisclosureNotes.css";
import { useSelector } from 'react-redux';
import moment from "moment";
import CommonToolTip from "../../Common/CommonToolTip";
import ErrorIcon from "@mui/icons-material/Error";
import { formatIndianCurrency } from '../../../helper/ConvertValue'
import { DisclosureNBFCTableBody, DisclosureNBFCTableHead } from './DisclosureHelper';


export function NBFC_26_30({data, subIndex, dis_index_id, HandleChange, from_financial_statement}) {
  const reduxProjectData = useSelector((initialState) => initialState.DisclosureReducer.projectData);

  const calculateTotal = (key) => {
    const copy = [...data];
    return copy.reduce((total, item) => {
      const value = item[key];
      if (typeof value === 'number') {
        return total + value;
      }
      return total;
    }, 0);
  };

  const checkCondition = (key) =>{
    return calculateTotal(key) === ((key === 'cy' || key === "gratuity_cy") ? from_financial_statement?.cy_amt : from_financial_statement?.py_amt) ? false : true;
  }


  return (
    <>
      <thead>
        {dis_index_id === 1 && (
          <tr>
            <th className='text-left w-75 pl-3 border-top-0 border-bottom-0' >
              Particulars
            </th>
            <th className='w-auto border-top-0 border-bottom-0' style={{minWidth:"110px"}}  > Year ended {" "} {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
            <th className='w-auto border-top-0 border-bottom-0'style={{minWidth:"110px"}} > Year ended {" "} {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
            <DisclosureNBFCTableHead isEmpty={false} />
          </tr>
        )}

        {  [2,3,4,5,6,7,8].includes(dis_index_id) && (
          <>
            <tr >
              <th rowSpan={2} className='text-left w-75 pl-3 border-top-0 border-bottom-0 '>
                Particulars
              </th>
              <th className='w-auto border-top-0 border-bottom-0' colSpan={2}>
                GRATUITY
              </th>
              <th className='w-auto border-top-0 border-bottom-0' colSpan={2}>
                COMPENSATED ABSENCE
              </th>
              <DisclosureNBFCTableHead isEmpty={false} />
            </tr>

            
            <tr>
              <th className='w-auto border-top-0 border-bottom-0'> {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
              <th className='w-auto border-top-0 border-bottom-0'>{moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
              <th className='w-auto border-top-0 border-bottom-0'> {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
              <th className='w-auto border-top-0 border-bottom-0'>{moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
              <DisclosureNBFCTableHead isEmpty={true} />
            </tr>
          </>
        )}

        {dis_index_id === 9 && (
          <>
            <tr>
              <th rowSpan={2} className='text-left w-75 pl-3 border-top-0 border-bottom-0'>
                Particulars
              </th>
              <th className='w-auto border-top-0 border-bottom-0' colSpan={2}>
                DR: Discount Rate
              </th>
              <th className='w-auto border-top-0 border-bottom-0' colSpan={2}>
                ER: Salary Escalation Rate
              </th>
              <DisclosureNBFCTableHead isEmpty={false} />
            </tr>
            <tr>
              <th className='w-auto border-top-0 border-bottom-0'>PVO DR +1%</th>
              <th className='w-auto border-top-0 border-bottom-0'>PVO DR -1%</th>
              <th className='w-auto border-top-0 border-bottom-0'>PVO ER +1%</th>
              <th className='w-auto border-top-0 border-bottom-0'>PVO ER -1%</th>
              <DisclosureNBFCTableHead isEmpty={true} />
            </tr>
          </>
        )}
        {dis_index_id === 10 && (
          <>
            <tr>
              <th className='text-left w-75 pl-3 border-top-0 border-bottom-0'>
                Particulars
              </th>
              <th className='w-auto border-top-0 border-bottom-0'  style={{minWidth:"110px"}} >{moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
              <th className='w-auto border-top-0 border-bottom-0'>% Allocation</th>
              <th className='w-auto border-top-0 border-bottom-0'  style={{minWidth:"110px"}} >{moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
              <th className='w-auto border-top-0 border-bottom-0'  style={{minWidth:"110px"}} >% Allocation</th>
              <DisclosureNBFCTableHead isEmpty={false} />

            </tr>
          </>
        )}
        {dis_index_id === 11 && (
          <>
            <tr>
              <th className='text-left w-75 pl-3 border-top-0 border-bottom-0'>
                Particulars
              </th>
              <th className='w-auto border-top-0 border-bottom-0'  >{moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
              <th className='w-auto border-top-0 border-bottom-0'>{moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
              <DisclosureNBFCTableHead  isEmpty={false} /> 

            </tr>
            

          </>
        )}
        {dis_index_id === 12 && (
          <>
            <tr>
              <th className='text-left w-75 pl-3 border-top-0 border-bottom-0'>
                Particulars
              </th>
              <th className='w-auto border-top-0 border-bottom-0' style={{minWidth:"110px"}}>Pay-outs {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
              <th className='w-auto border-top-0 border-bottom-0' style={{minWidth:"110px"}}>Pay-outs {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
              <DisclosureNBFCTableHead isEmpty={false} />
            </tr>
          </>
        )}
      </thead>

      <tbody>
        {data &&
          data.length > 0 &&
          data.map((subDis, subDisIndex) => (
            <>
              {dis_index_id === 1 && (
                <>
                  <tr>
                    <td className="border-top-0 border-bottom-0">
                      <input style={{backgroundColor:"inherit",fontSize:"16px"}}
                        className={`p-0 m-0 w-100 border-0 pl-3 text-left ${
                          subDis?.isHeading && "font-weight-bold"
                        }`}
                        type={"text"}
                        value={subDis?.header}
                        name="header"
                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                      />
                    </td>

                    <td className="border-top-0 border-bottom-0">
                      <input style={{backgroundColor:"inherit",fontSize:"16px"}}
                        className="p-0 m-0 w-100 border-0 pr-3 text-right"
                        readOnly={typeof subDis?.cy !== "number"}
                        type={"text"}
                        value={formatIndianCurrency(subDis?.cy)}
                        name="cy"
                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                      />
                    </td>
                    <td className="border-top-0 border-bottom-0">
                      <input style={{backgroundColor:"inherit",fontSize:"16px"}}
                        className="p-0 m-0 w-100 border-0 pr-3 text-right"
                        type={"text"}
                        readOnly={typeof subDis?.py !== "text"}
                        value={subDis?.py}
                        name="py"
                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                      />
                    </td>
                    <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData} isReference={subDis?.isReference}  keyValue={2} index={subIndex}  sub_index={subDisIndex}/>
                  </tr>
                  {
                    data.length - 1 === subDisIndex &&  (
                      <tr>
                        <td className="custom-bg-gray-200"> </td>
                        <td className='pr-3 text-right font-weight-bold text-dark custom-bg-gray-200'>
                          {formatIndianCurrency(calculateTotal('cy'))}
                          {checkCondition('cy') &&
                            <CommonToolTip
                              variant="primary"
                              title={"The value is not matching with financial statement value." }
                              placement="top"
                            >
                                <ErrorIcon
                                    style={{
                                        color: "darkorange",
                                        cursor: "pointer",
                                    }}
                                    className="financialInstrument-hoverIcon"
                                />
                            </CommonToolTip> 
                          }
                        </td>
                        <td className='pr-3 text-right font-weight-bold text-dark custom-bg-gray-200'>
                          {formatIndianCurrency(calculateTotal('py'))}
                            {checkCondition('py') && 
                              <CommonToolTip
                                variant="primary"
                                title={"The value is not matching with financial statement value." }
                                placement="top"
                              >
                                  <ErrorIcon
                                    style={{
                                        color: "darkorange",
                                        cursor: "pointer",
                                    }}
                                    className="financialInstrument-hoverIcon"
                                />
                              </CommonToolTip> 
                            }
                        </td>
                    <DisclosureNBFCTableBody isEmpty={true} refData={[]} isReference={""}/>
                      </tr>
                    )
                  }
                </>
              )}
              { [2,3,4,5,6,7,8].includes(dis_index_id) && (
                <>
                  <tr >
                    <td className="border-top-0 border-bottom-0">
                      <input style={{backgroundColor:"inherit"}}
                        className={`p-0 m-0 w-100 border-0 pl-3 text-left ${
                          subDis?.isHeading && "font-weight-bold"
                        }`}
                        type={"text"}
                        value={subDis?.header}
                        name="header"
                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                      />
                    </td>

                    <td  className="border-top-0 border-bottom-0" >
                      <input style={{backgroundColor:"inherit"}}
                        className="p-0 m-0 w-100 border-0 pr-3 text-right"
                        readOnly={typeof subDis?.gratuity_cy !== "number"}
                        type={"text"}
                        value={formatIndianCurrency(subDis?.gratuity_cy)}
                        name="gratuity_cy"
                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                      />
                    </td>
                    <td  className="border-top-0 border-bottom-0">
                      <input style={{backgroundColor:"inherit"}}
                        className="p-0 m-0 w-100 border-0 pr-3 text-right"
                        type={"text"}
                        readOnly={typeof subDis?.gratuity_py !== "number"}
                        value={formatIndianCurrency(subDis?.gratuity_py)}
                        name="gratuity_py"
                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                      />
                    </td>

                    <td className="border-top-0 border-bottom-0">
                      <input style={{backgroundColor:"inherit"}}
                        className="p-0 m-0 w-100 border-0 pr-3 text-right"
                        readOnly={typeof subDis?.compensate_cy !== "number"}
                        type={"text"}
                        value={formatIndianCurrency(subDis?.compensate_cy)}
                        name="compensate_cy"
                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                      />
                    </td>
                    <td  className="border-top-0 border-bottom-0" >
                      <input style={{backgroundColor:"inherit"}}
                        className="p-0 m-0 w-100 border-0 pr-3 text-right"
                        type={"text"}
                        readOnly={typeof subDis?.compensate_py !== "number"}
                        value={formatIndianCurrency(subDis?.compensate_py)}
                        name="compensate_py"
                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                      />
                    </td>
                    
                    <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData} isReference={subDis?.isReference} keyValue={2} index={subIndex}  sub_index={subDisIndex}/>

                  </tr>
                  {dis_index_id !== 5 && 
                    data.length - ( dis_index_id === 6 ? 2 : 1) === subDisIndex &&  (
                      <tr>
                        <td className='pl-3 text-left font-weight-bold text-dark custom-bg-gray-200'>     {
                                    dis_index_id === 2 ? "" :
                                    dis_index_id === 3 ? "Present Value of obligation at the end of the year" :
                                    dis_index_id === 4 ? "Fair Value of Plan Assets at the end of the year" : 
                                    dis_index_id === 6 ? "Net cost recognised in the current year" : 
                                    dis_index_id === 7 ? "Total measurement cost / (credit) for the year recognised in OCI" :
                                    dis_index_id === 8 ? "Net asset / (liability) recognised at the end of the year" : ""
                                  } 
                        </td>
                        <td className='pr-3 text-right font-weight-bold text-dark custom-bg-gray-200'>
                          {formatIndianCurrency(calculateTotal('gratuity_cy'))} 
                          {
                            dis_index_id === 7 &&
                            ( checkCondition('gratuity_cy') && 
                            <CommonToolTip
                              variant="primary"
                              title={"The value is not matching with financial statement value." }
                              placement="top"
                            >
                                <ErrorIcon
                                    style={{
                                        color: "darkorange",
                                        cursor: "pointer",
                                    }}
                                    className="financialInstrument-hoverIcon"
                                />
                            </CommonToolTip> 
                            )
                          }
                        </td>
                        <td className='pr-3 text-right font-weight-bold text-dark custom-bg-gray-200'>
                          {formatIndianCurrency(calculateTotal('gratuity_py'))}
                          {
                            dis_index_id === 7 && (
                              checkCondition('gratuity_py') &&
                              <CommonToolTip
                              variant="primary"
                              title={"The value is not matching with financial statement value." }
                              placement="top"
                            >
                                <ErrorIcon
                                    style={{
                                        color: "darkorange",
                                        cursor: "pointer",
                                    }}
                                    className="financialInstrument-hoverIcon"
                                />
                            </CommonToolTip> 
                            )
                          }
                        </td>
                        <td className='pr-3 text-right font-weight-bold text-dark custom-bg-gray-200'>{formatIndianCurrency(calculateTotal('compensate_cy'))} </td>
                        <td className='pr-3 text-right font-weight-bold text-dark custom-bg-gray-200'>{formatIndianCurrency(calculateTotal('compensate_py'))} </td>
                        <DisclosureNBFCTableBody isEmpty={true} refData={[]} isReference={""}/>
                      </tr>
                    )
                  }
                </>
              )}
              {dis_index_id === 9 && (
                <>
                  <tr >
                    <td className="border-top-0 border-bottom-0">
                      <input style={{backgroundColor:"inherit"}}
                        className={`p-0 m-0 w-100 border-0 pl-3 text-left  ${
                          subDis?.isHeading && "font-weight-bold"
                        }`}
                        type={"text"}
                        value={subDis?.header}
                        name="header"
                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                      />
                    </td>
                    <td  className="border-top-0 border-bottom-0">
                      <input style={{backgroundColor:"inherit"}}
                        className="p-0 m-0 w-100 border-0 pr-3 text-right"
                        type={"text"}
                        readOnly={typeof subDis?.dr_inc !== "number"}
                        value={formatIndianCurrency(subDis?.dr_inc)}
                        name="dr_inc"
                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                      />
                    </td>
                    <td  className="border-top-0 border-bottom-0">
                      <input style={{backgroundColor:"inherit"}}
                        className="p-0 m-0 w-100 border-0 pr-3 text-right"
                        readOnly={typeof subDis?.dr_dec !== "number"}
                        type={"text"}
                        value={formatIndianCurrency(subDis?.dr_dec)}
                        name="dr_dec"
                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                      />
                    </td>
                    <td  className="border-top-0 border-bottom-0">
                      <input style={{backgroundColor:"inherit"}}
                        className="p-0 m-0 w-100 border-0 pr-3 text-right"
                        readOnly={typeof subDis?.er_inc !== "number"}
                        type={"text"}
                        value={formatIndianCurrency(subDis?.er_inc)}
                        name="er_inc"
                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                      />
                    </td>
                    <td  className="border-top-0 border-bottom-0">
                      <input style={{backgroundColor:"inherit"}}
                        className="p-0 m-0 w-100 border-0 pr-3 text-right"
                        type={"text"}
                        readOnly={typeof subDis?.er_dec !== "number"}
                        value={formatIndianCurrency(subDis?.er_dec)}
                        name="er_dec"
                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                      />
                    </td>
                    <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData} isReference={subDis?.isReference}  keyValue={2} index={subIndex}  sub_index={subDisIndex}/>
                  </tr>
                </>
              )}
              {dis_index_id === 10 && (
                <>
                  <tr >
                    <td className="border-top-0 border-bottom-0" >
                      <input style={{backgroundColor:"inherit"}}
                        className={`p-0 m-0 w-100 border-0 pl-3 text-left ${
                          subDis?.isHeading && "font-weight-bold"
                        }`}
                        type={"text"}
                        value={subDis?.header}
                        name="header"
                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                      />
                    </td>
                    <td className="border-top-0 border-bottom-0">
                      <input style={{backgroundColor:"inherit"}}
                        className="p-0 m-0 w-100 border-0 pr-3 text-right"
                        type={"text"}
                        readOnly={typeof subDis?.cy !== "number"}
                        value={formatIndianCurrency(subDis?.cy)}
                        name="cy"
                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                      />
                    </td>
                    <td className="border-top-0 border-bottom-0">
                      <input style={{backgroundColor:"inherit"}}
                        className="p-0 m-0 w-100 border-0 pr-3 text-right"
                        readOnly={typeof subDis?.cy_allocation !== "number"}
                        type={"text"}
                        value={formatIndianCurrency(subDis?.cy_allocation)}
                        name="cy_allocation"
                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                      />
                    </td>
                    <td className="border-top-0 border-bottom-0">
                      <input style={{backgroundColor:"inherit"}}
                        className="p-0 m-0 w-100 border-0 pr-3 text-right"
                        readOnly={typeof subDis?.py !== "number"}
                        type={"text"}
                        value={formatIndianCurrency(subDis?.py)}
                        name="py"
                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                      />
                    </td>
                    <td className="border-top-0 border-bottom-0">
                      <input style={{backgroundColor:"inherit"}}
                        className="p-0 m-0 w-100 border-0 pr-3 text-right"
                        type={"text"}
                        readOnly={typeof subDis?.py_allocation !== "number"}
                        value={formatIndianCurrency(subDis?.py_allocation)}
                        name="py_allocation"
                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                      />
                    </td>
                    <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData} isReference={subDis?.isReference}  keyValue={2} index={subIndex}  sub_index={subDisIndex}/>
                  </tr>
                  
                  
                </>
              )}
              {[11,12].includes(dis_index_id) && (
                <>
                  <tr>
                    <td  className="border-top-0 border-bottom-0">
                      <input style={{backgroundColor:"inherit"}}
                        className={`p-0 m-0 w-100 border-0 pl-3 text-left ${
                          subDis?.isHeading && "font-weight-bold"
                        }`}
                        type={"text"}
                        value={subDis?.header}
                        name="header"
                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                      />
                    </td>
                    <td  className="border-top-0 border-bottom-0">
                      <input style={{backgroundColor:"inherit"}}
                        className="p-0 m-0 w-100 border-0 pr-3 text-right"
                        type={"text"}
                        readOnly={typeof subDis?.cy !== "number"}
                        value={formatIndianCurrency(subDis?.cy)}
                        name="cy"
                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                      />
                    </td>
                    
                    <td  className="border-top-0 border-bottom-0">
                      <input style={{backgroundColor:"inherit"}}
                        className="p-0 m-0 w-100 border-0 pr-3 text-right"
                        readOnly={typeof subDis?.py !== "number"}
                        type={"text"}
                        value={formatIndianCurrency(subDis?.py)}
                        name="py"
                        onChange={(event) => HandleChange(event, subIndex, subDisIndex)}
                      />
                    </td>
                    <DisclosureNBFCTableBody isEmpty={false} refData={subDis?.refData} isReference={subDis?.isReference}  keyValue={2} index={subIndex}  sub_index={subDisIndex}/>
                  </tr>
                </>
              )}
            </>
          ))}
      </tbody>
    </>
  );
}
