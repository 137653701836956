import React, { useEffect, useState } from "react";
import './notesData.css';
import { socket } from "../../services/socket/socket";
import swal from 'sweetalert';
import Loader from "../../helper/loader";
// import Note1 from "./Note1";
// import { motion } from "framer-motion";
import { Box, Tooltip, Typography } from "@mui/material";
import PropTypes from 'prop-types';
// import { fetchTTNotesData } from "../../redux/actions/TemplateTabs/actionNotes";
import { convertStrToNumber, formatIndianCurrency } from "../../helper/ConvertValue";
import ErrorIcon from "@mui/icons-material/Error";
import moment from "moment";
import CkEditor from '../../Components/Common/CkEditor';
import CommonToolTip from "../Common/CommonToolTip";
import NoteRemark from "./components/NoteRemark";
import { getNotesData, notesReferenceMapping, updateDisclosure } from "../../services/api/apiHandler";
import { RenderChecklistReference } from "../Disclosures/NBFC/DisclosureHelper";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getChecklistData } from "../../services/api/StandAlone/disclosureChecklist";

const inputDiv = {
  height: "100%",
  width: "100%",
};
const input = {
  height: "100%",
  width: "100%",
  maxWidth: "200px",
  border: "none",
  textAlign: "end",
  background: "transparent",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const NotesData = (props) => {
  const { notes_no, fs_grp, render } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [tabIndex, setTabIndex] = useState(0);
  const [updateData, setUpdateData] = useState(null);
  const [notesData, setNotesData] = useState({
    response: [],
    project: {},
    isSuccess: false,
    isLoading: false,
    error_message: null
  });

  const TB_ID = localStorage.getItem("tb_id");
  const project_id = localStorage.getItem("project_id");

  const reduxChecklistData = useSelector((initialState) => initialState.DisclosureChecklistReducer.disclosureChecklistData);

  useEffect(() => {
    if (reduxChecklistData.length <= 0) {
      getChecklistData(project_id, TB_ID).then(response => {
        dispatch({ type: "DISCLOSURE_CHECKLIST_DATA", payload: response?.data?.data });
      }).catch(error => {
        swal("Error", error, "error");
      });
    }
  }, [reduxChecklistData])


  useEffect(() => {
    (async () => {
      try {
        const params_data = { fs_grp, notes_no };
        setNotesData((res) => {
          return {
            ...res,
            loading: true
          }
        });
        const response = await getNotesData(project_id, TB_ID, params_data);
        setNotesData((res) => {
          return {
            ...res,
            loading: false,
            isSuccess: true,
            response: response.data.result,
            project: response.data.project
          }
        });
      } catch (error) {
        swal('Error', error.response?.data?.message || "Get Notes Data Failed!", 'error')
        setNotesData((res) => {
          return {
            ...res,
            loading: false,
            isSuccess: false,
            error_message: error.response?.data?.message || "Get Notes Data Failed!"
          }
        });
      }
    })();
  }, [render]);

  useEffect(() => {
    if (updateData) {
      setNotesData((res) => {
        return {
          ...res,
          response: updateData
        }
      });

      setUpdateData(null);
    }
  }, [updateData])

  const handleNotesUpdate = (response) => {
    setNotesData((res) => {
      return {
        ...res,
        response: response
      }
    });
  }

  function handleLeave() {
    console.log("left");
  }

  const calGrossCarryingAmt = (disclosures, type, subGrp) => {
    try {
      const findDis = disclosures.find(ele => ["Opening Accumulated Depreciation", "Opening Gross Carrying Amount"].includes(ele?.disclosures))
      if (findDis) {
        findDis.cy_amt = subGrp?.py_amt || 0
      }
      return disclosures.reduce((num, acc) => num + acc[type], 0)
    } catch (error) {
      return '*'
    }
  }

  const calNetDifference = (subGrp, type) => {
    try {
      return (subGrp[type] - calGrossCarryingAmt(subGrp.disclosures, type, subGrp));
    } catch (err) {
      return '*'
    }
  }

  const calTotalAmount = (subGrp, type) => {
    try {
      return subGrp.reduce((num, acc) => num + acc[type], 0);
    } catch (err) {
      return '*'
    }
  }

  const HandleOnChange = (event, noteIndex, index) => {
    const copy = {
      data: notesData?.response,
      project: notesData?.project,
      set_payload: notesData?.set_payload
    };
    copy.data.data[noteIndex].sub_grp[index][event.target.name] = Number(event.target.value);
    // dispatch({
    //   type: "TT_Notes_FETCH_DATA",
    //   payload: copy
    // })
    setNotesData((res) => {
      return {
        ...res,
        response: copy.data,
      }
    });

    socket.emit("request-updated-special-notes", {
      targetId: copy.data?._id,
      noteIndex,
      index,
      name: event.target.name,
      value: Number(event.target.value),
    });
  }

  socket.on('request-updated-special-notes', (data) => {
    if (!data.success) {
      swal("Error", (data.message || "Notes Data saved failed!"), "error")
    }
  });

  const HandleReference = (event, SGindex, disIndex) => {
    const { value } = event.target;
    const data = {
      project_id,
      note_no: notes_no,
      value,
      SGindex,
      disIndex
    }
    notesReferenceMapping(data).then(response => {
      setNotesData((res) => {
        return {
          ...res,
          loading: false,
          isSuccess: true,
          response: response.data.result,
          project: response.data.project
        }
      });
    }).catch(error => {
      swal("Error", (error.response.data?.error || "Reference Mapping Failed!"), "error")
    })
  }

  const SwitchReferenceTab = (isReference, refData) => {
    if (isReference === "ind_as_reference") {
      dispatch({ type: "IND_AS_1_FROM_BS_PNL", payload: refData[0].name });
      history.push("/library-indas-1")
    }
    if (isReference === "checklist_reference") {
      const index = reduxChecklistData.findIndex((item) => item.name.toLowerCase() === refData[0].name.toLowerCase());
      dispatch({ type: "NOTE_KEY", payload: 8 });
      dispatch({ type: "NOTE_INDEX", payload: index === -1 ? 0 : index });
    }
  }
  return (
    <React.Fragment>
      {notesData.loading ? (
        <Loader />
      ) : notesData.isSuccess ? (
        <Box>
          {notesData?.response?.data.map((notesGrp, index) => (
            <p className="m-0 p-0 py-1 pl-3 font-weight-bold font-size-18 font-sans">{notesGrp.fs_grp}</p>
          ))}
          {notesData?.response?.data.map((notesGrp, index) => (
            <TabPanel value={tabIndex} index={index}>
              <div className="notes">
                <table>
                  <thead>
                    <tr className="bg-secondary-400">
                      <th className="font-sans text-left pl-3"
                        style={{
                          width: "40%",
                          height: "70px",
                        }}
                      >
                        Particulars
                      </th>
                      <th className="font-sans" style={{ width: "10%" }}>{notesData.isSuccess && new Date(notesData?.project?.current_year).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</th>
                      <th className="font-sans" style={{ width: "10%" }}>{notesData.isSuccess && new Date(notesData?.project?.previous_year).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</th>
                      <th className="font-sans"> References </th>
                      <th className="font-sans"> IND AS References </th>
                      <th className="font-sans"> Checklist References </th>
                      <th
                        className="font-sans text-left pl-3"
                      >
                        Guidances
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <React.Fragment>
                      {notesGrp?.sub_grp?.map((subGrp, key) => (
                        <React.Fragment>
                          {
                            (notesData?.project?.company_type === "NBFC" && ["37"].includes(notesData?.response?.notes_no) && !["Equity share capital"].includes(subGrp.sub_grp)) ? (
                              <>
                                {
                                  notesData?.response?.notes_no === "37" && [0, 2].includes(key) && (
                                    <>
                                      {key === 2 && <tr>
                                        <td></td>
                                        <td className="text-right pr-2">{Number(notesGrp?.sub_grp[0]?.cy_amt) + Number(notesGrp?.sub_grp[1]?.cy_amt)}</td>
                                        <td className="text-right pr-2">{Number(notesGrp?.sub_grp[0]?.py_amt) + Number(notesGrp?.sub_grp[1]?.py_amt)}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                      </tr>}
                                      <tr>
                                        <td className="font-weight-bold text-left pl-3">{key === 0 ? "Authorised" : key === 2 ? "Issued, Subscribed and Paid up: " : ""}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                      </tr>
                                    </>
                                  )
                                }
                                <tr
                                  style={{
                                    color: "black",
                                    fontSize: "18px",
                                    borderTop: "none",
                                  }}
                                  key={0}
                                >
                                  <td
                                    style={{
                                      width: "40%",
                                      height: "20px",
                                      textAlign: "left",
                                      paddingLeft: "15px",
                                      color: "black",
                                    }}
                                    className=" font-weight-normal font-sans"
                                  >
                                    {subGrp.sub_grp}
                                  </td>
                                  <td style={{ width: "10%", textAlign: "end" }}>
                                    <input
                                      className="table-row-input font-weight-normal font-sans"
                                      type="number"
                                      value={Number(subGrp.cy_amt)}
                                      name="cy_amt"
                                      onChange={(e) => HandleOnChange(e, index, key)}
                                    />
                                  </td>
                                  <td style={{ width: "10%", textAlign: "end" }}>
                                    <input
                                      className="table-row-input font-weight-normal font-sans"
                                      type="number"
                                      value={Number(subGrp.py_amt)}
                                      name="py_amt"
                                      onChange={(e) => HandleOnChange(e, index, key)}
                                    />
                                  </td>
                                  <td>
                                    {
                                      <RenderChecklistReference
                                        data={subGrp?.refData}
                                        isReference={subGrp?.isReference}
                                        SwitchReferenceTab={() => {
                                          SwitchReferenceTab(subGrp?.isReference, subGrp?.refData)
                                        }}
                                      />
                                    }
                                  </td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      value={"ind_as_reference"}
                                      disabled={!subGrp?.refData}
                                      onChange={(e) => HandleReference(e, key)}
                                      checked={subGrp?.isReference === "ind_as_reference"}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      value={"checklist_reference"}
                                      disabled={!subGrp?.refData}
                                      onChange={(e) => HandleReference(e, key)}
                                      checked={subGrp?.isReference === "checklist_reference"}
                                    />
                                  </td>
                                  {/* {subGrp.disclosures.length > 0 &&
                                    <td
                                      style={{
                                        width: "25%",
                                        textAlign: "right",
                                        paddingLeft: "3rem",
                                      }}
                                    >
                                      {calNetDifference(subGrp, "cy_amt") !== 0 ||
                                        calNetDifference(subGrp, "py_amt") !== 0 ? (
                                        <Tooltip
                                          title="The net difference is not balancing."
                                          placement="top"
                                        >
                                          <i
                                            style={{
                                              cursor: "pointer",
                                              color: "darkorange",
                                              transform: "scale(1.2)",
                                            }}
                                            className="fas fa-info-circle"
                                          />
                                        </Tooltip>
                                      ) : null
                                      }
                                    </td>
                                  } */}
                                </tr>
                              </>
                            ) :
                              <tr
                                className="font-size-16"
                                style={{
                                  color: "black",
                                  fontWeight: "500",
                                  borderTop: "none",
                                }}
                                key={0}
                              >
                                <td className="font-sans"
                                  style={{
                                    width: "40%",
                                    height: "20px",
                                    textAlign: "left",
                                    paddingLeft: "15px",
                                    color: "black",
                                  }}
                                >
                                  {subGrp.sub_grp}
                                </td>
                                <td className="font-sans" style={{ width: "10%", textAlign: "end", paddingRight: "1rem", }}>
                                  {
                                    formatIndianCurrency(subGrp.cy_amt)
                                  }
                                  {
                                    ["Equity share capital"].includes(subGrp.sub_grp) &&
                                    <span className="ml-1">
                                      {
                                        Number(subGrp.cy_amt) !== Number(notesGrp?.sub_grp[2]?.cy_amt) &&
                                        <Tooltip
                                          title="CY amount is not balancing."
                                          placement="top"
                                        >
                                          <i
                                            style={{
                                              cursor: "pointer",
                                              color: "darkorange",
                                              transform: "scale(1.2)",
                                            }}
                                            className="fas fa-info-circle"
                                          />
                                        </Tooltip>
                                      }
                                    </span>
                                  }

                                </td>
                                <td className="font-sans" style={{ width: "10%", textAlign: "end", paddingRight: "1rem" }}>
                                  {
                                    formatIndianCurrency(subGrp.py_amt)
                                  }
                                  {
                                    ["Equity share capital"].includes(subGrp.sub_grp) &&
                                    <span className="ml-1">
                                      {
                                        Number(subGrp.py_amt) !== Number(notesGrp?.sub_grp[2]?.py_amt) &&
                                        <Tooltip
                                          title="PY amount is not balancing."
                                          placement="top"
                                        >
                                          <i
                                            style={{
                                              cursor: "pointer",
                                              color: "darkorange",
                                              transform: "scale(1.2)",
                                            }}
                                            className="fas fa-info-circle"
                                          />
                                        </Tooltip>
                                      }
                                    </span>
                                  }
                                </td>
                                <td>
                                  {
                                    <RenderChecklistReference
                                      data={subGrp?.refData}
                                      isReference={subGrp?.isReference}
                                      SwitchReferenceTab={() => {
                                        SwitchReferenceTab(subGrp?.isReference, subGrp?.refData)
                                      }}
                                    />
                                  }
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    value={"ind_as_reference"}
                                    disabled={!subGrp?.refData}
                                    onChange={(e) => HandleReference(e, key)}
                                    checked={subGrp?.isReference === "ind_as_reference"}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    value={"checklist_reference"}
                                    disabled={!subGrp?.refData}
                                    onChange={(e) => HandleReference(e, key)}
                                    checked={subGrp?.isReference === "checklist_reference"}
                                  />
                                </td>
                                {subGrp.disclosures.length > 0 && <td
                                >
                                  {calNetDifference(subGrp, "cy_amt") !== 0 ||
                                    calNetDifference(subGrp, "py_amt") !== 0 ? (
                                    <Tooltip
                                      title="The net difference is not balancing."
                                      placement="top"
                                    >
                                      <i
                                        style={{
                                          cursor: "pointer",
                                          color: "darkorange",
                                          transform: "scale(1.2)",
                                        }}
                                        className="fas fa-info-circle"
                                      />
                                    </Tooltip>
                                  ) : null}
                                </td>}
                              </tr>
                          }
                          {subGrp.disclosures.length > 0 &&
                            subGrp.disclosures.map((disclosure, dx) => (
                              <tr className="n4disclosure" key={dx}>
                                <td
                                  style={{
                                    width: "40%",
                                    height: "40px",
                                    textAlign: "left",
                                    paddingLeft: "1rem",
                                    color: "black",

                                  }}
                                >
                                  {disclosure.disclosures}
                                </td>
                                <td style={{ width: "10%", textAlign: "end" }}>
                                  {
                                    disclosure.disclosures === "Closing Gross Carrying Amount" ?
                                      <div className="pr-3">
                                        {formatIndianCurrency(calGrossCarryingAmt(
                                          subGrp.disclosures,
                                          "cy_amt",
                                          subGrp
                                        ))}
                                      </div> :
                                      <div style={inputDiv}>
                                        <InputDisclosure
                                          notesId={notesData.response._id}
                                          key={dx}
                                          handleLeave={handleLeave}
                                          subGroupId={key}
                                          disclosure={disclosure}
                                          type={'cy_amt'}
                                          subGroupData={subGrp}
                                          notes_payload={{ 'pid': project_id, 'tb_id': TB_ID, 'fs_grp': fs_grp, 'notes_no': notes_no, 'disIndex': dx, 'index': index }}
                                          handleNotesUpdate={handleNotesUpdate}
                                        />
                                      </div>
                                  }
                                </td>
                                <td style={{ width: "10%", textAlign: "end" }}>
                                  {
                                    disclosure.disclosures === "Closing Gross Carrying Amount" ?
                                      <div className="pr-3">
                                        {formatIndianCurrency(calGrossCarryingAmt(
                                          subGrp.disclosures,
                                          "py_amt",
                                          subGrp
                                        ))}
                                      </div> :
                                      <div style={inputDiv}>
                                        <InputDisclosure
                                          notesId={notesData.response._id}
                                          key={dx}
                                          handleLeave={handleLeave}
                                          subGroupId={key}
                                          disclosure={disclosure}
                                          subGroupData={subGrp}
                                          type={'py_amt'}
                                          notes_payload={{ 'pid': project_id, 'tb_id': TB_ID, 'fs_grp': fs_grp, 'notes_no': notes_no, 'disIndex': dx, 'index': index }}
                                          handleNotesUpdate={handleNotesUpdate}
                                        />
                                      </div>
                                  }
                                </td>
                                <td >
                                  {
                                    <RenderChecklistReference
                                      data={disclosure?.refData}
                                      isReference={disclosure?.isReference}
                                      SwitchReferenceTab={() => {
                                        SwitchReferenceTab(disclosure?.isReference, disclosure?.refData)
                                      }}
                                    />
                                  }
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    value={"ind_as_reference"}
                                    disabled={!disclosure?.refData}
                                    onChange={(e) => HandleReference(e, key, dx)}
                                    checked={disclosure?.isReference === "ind_as_reference"}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    value={"checklist_reference"}
                                    disabled={!disclosure?.refData}
                                    onChange={(e) => HandleReference(e, key, dx)}
                                    checked={disclosure?.isReference === "checklist_reference"}
                                  />
                                </td>
                                <td
                                ></td>
                              </tr>
                            ))}
                          {subGrp.disclosures.length > 0 && (
                            <>
                              <tr
                                className=""
                                style={{
                                  backgroundColor: "rgb(238, 233, 233)"
                                }}
                              >
                                <td
                                  style={{
                                    width: "40%",
                                    height: "33px",
                                    textAlign: "left",
                                    paddingLeft: "1rem",
                                    fontWeight: "600",
                                    fontSize: "14px",

                                  }}
                                >
                                  Net Difference
                                </td>
                                <td
                                  style={{
                                    width: "10%",
                                    textAlign: "end",
                                    paddingRight: "1rem",
                                    fontWeight: "600",
                                    fontSize: "14px",

                                  }}
                                >
                                  {formatIndianCurrency(calNetDifference(subGrp, "cy_amt"))}
                                </td>
                                <td
                                  style={{
                                    width: "10%",
                                    textAlign: "end",
                                    paddingRight: "1rem",
                                    fontWeight: "600",
                                    fontSize: "14px",
                                  }}
                                >
                                  {formatIndianCurrency(calNetDifference(subGrp, "py_amt"))}
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td
                                ></td>
                              </tr>
                              <tr className="n4addInput">
                                <td
                                  style={{
                                    width: "40%",
                                    height: "33px",
                                    textAlign: "left",
                                    paddingLeft: "15px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    console.log("subGrp", {subGrp, notesGrp:notesData?.response})
                                    props.setShowCreateInput(true);
                                    props.setNid(notesData?.response?.nlid);
                                    props.setSid(subGrp.sid);
                                    props.setNoteNum(props.note);
                                  }}
                                >
                                  + Create New Input
                                </td>
                                <td style={{ width: "10%" }}></td>
                                <td style={{ width: "10%" }}></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td
                                ></td>
                              </tr>
                            </>
                          )}
                          {notesGrp?.sub_grp.length - 1 === key && subGrp.disclosures.length < 1 && (
                            <tr
                              style={{
                                height: "50px",
                                backgroundColor: " #F0DBC8",
                                padding: "10px 0 0 2rem",
                                alignContent: "center",
                                fontSize: "1.1rem",
                                fontWeight: "600",
                              }}
                            >
                              <td className="font-sans"
                                style={{
                                  width: "40%",
                                  height: "33px",
                                  textAlign: "left",
                                  paddingLeft: "1rem",
                                  color: "#000000",
                                }}
                              >
                                Total amount
                              </td>
                              <td
                                className="font-sans"
                                style={{ width: "10%", textAlign: "end", paddingRight: "1rem" }}
                              >
                                {formatIndianCurrency(calTotalAmount(notesGrp?.sub_grp, 'cy_amt'))}
                              </td>
                              <td
                                className="font-sans"
                                style={{ width: "10%", textAlign: "end", paddingRight: "1rem" }}
                              >
                                {formatIndianCurrency(calTotalAmount(notesGrp?.sub_grp, 'py_amt'))}
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td
                              ></td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  </tbody>
                </table>

                {notesData.response.hasOwnProperty('extra_disclosure_note')
                  && <CWIPTable extraDisclosure={notesData.response.extra_disclosure_note} cyType={'cwip_cy'} pyType={'cwip_py'} />
                }
                {notesGrp.hasOwnProperty('ageing_table')
                  && <AgeingTable reduxProjectData={notesData?.project} response={notesData.response} projectType={notesData?.project?.company_type} extraDisclosure={notesGrp?.ageing_table} cyType={'ageing_cy'} pyType={'ageing_py'} />
                }

                <NoteRemark NoteResponse={notesData?.response} />
              </div>
            </TabPanel>
          ))}
        </Box>
      ) : notesData.isSuccess === false ? (
        <p className="text-danger ml-2">{notesData.error_message}</p>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default NotesData;

const InputDisclosure = (props) => {
  const { notesId, disclosure, subGroupId, type, subGroupData, handleNotesUpdate, notes_payload } = props;
  const [value, setValue] = useState(0);
  const specialDisclosure = ['Opening Gross Carrying Amount', 'Opening Accumulated Depreciation'];

  useState(() => {
    if (type === 'cy_amt' && specialDisclosure.includes(disclosure.disclosures)) {
      setValue(subGroupData.py_amt)
      // const update_payload = {
      //   "targetId": notesId,
      //   "subGroupId": subGroupId,
      //   "disclosureDidToUpdate": disclosure.did,
      //   "cyAmtToUpdate": subGroupData.py_amt,
      //   "pyAmtToUpdate": disclosure.py_amt
      // }
      // socket.emit('request-updated-notes-data', update_payload, notes_payload)
    } else {
      setValue(disclosure[type]);
    }
  }, [disclosure[type]])

  const handleChange = (e, disclosure) => {
    setValue(e.target.value)
    let cy_amt = disclosure.cy_amt;
    let py_amt = disclosure.py_amt;
    if (type === 'cy_amt') {
      cy_amt = e.target.value
    } else {
      py_amt = e.target.value
    }

    updateDisclosure(notesId, subGroupId, notes_payload?.disIndex, parseFloat(cy_amt), parseFloat(py_amt), notes_payload.fs_grp, notes_payload.index).then((resp) => {
      handleNotesUpdate(resp.data.result)
    }).catch((err) => {
      console.log("err", err)
    })
    // socket.emit('request-updated-notes-data', update_payload, notes_payload)
  }
  const isOpening = type === 'cy_amt' && specialDisclosure.includes(disclosure.disclosures);
  return (
    <input
      type={isOpening ? "string" : "number"}
      value={isOpening ? formatIndianCurrency(value) : value}
      name="disclosure"
      style={isOpening ? { ...input, paddingRight: "1rem" } : input}
      onChange={(e) => handleChange(e, disclosure)}
      disabled={isOpening ? true : false}
    />
  )
}

const CWIPTable = (props) => {
  const { extraDisclosure, cyType, pyType } = props;

  const disInp = {
    outline: "none",
    border: "none",
    width: "100%",
    height: "100%",
    backgroundColor: "#EBEBEB",
    textAlign: "end",
  }
  const level3 = {
    color: "black",
    fontSize: "18px",
    fontWeight: "500",
    borderTop: "none",
  };

  const calTotalRow = (cwipRowData) => {
    try {
      return parseInt(cwipRowData?.less_than_1_year) + parseInt(cwipRowData?.year_1_2) + parseInt(cwipRowData?.year_2_3) + parseInt(cwipRowData?.more_than_3_year)
    } catch (error) {
      return '*'
    }
  }

  const calTotalOfTotal = (cwipData) => {
    try {
      return cwipData.map(rowData => calTotalRow(rowData)).reduce((num, acc) => num + acc, 0)
    } catch (error) {
      return '*'
    }
  }

  return (
    <React.Fragment>
      {
        extraDisclosure.length > 0 &&
        <>
          <br />
          <table>
            <thead>
              <tr style={{ backgroundColor: "#A2C0C2" }}>
                <th
                  style={{
                    width: "40%",
                    height: "70px",
                    textAlign: "left",
                    paddingLeft: "2rem",
                  }}
                >
                  CWIP
                </th>
                <th style={{ width: "10%" }}>Less than 1 year</th>
                <th style={{ width: "10%" }}>1-2 year</th>
                <th style={{ width: "10%" }}>2-3 year</th>
                <th style={{ width: "10%" }}>more than 3 year</th>
                <th style={{ width: "25%", textAlign: "center" }}>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr
                style={{
                  height: "50px",
                  backgroundColor: "#EBEBEB",
                  padding: "10px 0 0 2rem",
                  alignContent: "center",
                  fontSize: "1.1rem",
                  fontWeight: "600",
                }}
              >
                <td
                  style={{
                    width: "40%",
                    height: "20px",
                    textAlign: "left",
                    paddingLeft: "2rem",
                    color: "black",
                  }}
                >
                  Current Year
                </td>
                <td
                  style={{ width: "10%", textAlign: "end", paddingRight: "1rem" }}
                ></td>
                <td
                  style={{ width: "10%", textAlign: "end", paddingRight: "1rem" }}
                ></td>
                <td
                  style={{ width: "10%", textAlign: "end", paddingRight: "1rem" }}
                ></td>
                <td
                  style={{ width: "10%", textAlign: "end", paddingRight: "1rem" }}
                ></td>
                <td style={{ width: "10%", textAlign: "center" }}></td>
              </tr>
              {extraDisclosure.hasOwnProperty(cyType) &&
                extraDisclosure[cyType].length > 0 &&
                extraDisclosure[cyType].map((cyData, index) => (
                  <React.Fragment>
                    <tr style={level3}>
                      <td
                        style={{
                          width: "40%",
                          height: "70px",
                          textAlign: "left",
                          paddingLeft: "2rem",
                          backgroundColor: "#EBEBEB",
                        }}
                      >
                        {cyData?.header}
                      </td>
                      <td
                        style={{
                          width: "10%",
                          textAlign: "end",
                          paddingRight: "1rem",
                          backgroundColor: "#EBEBEB",
                        }}
                      >
                        <input
                          style={disInp}
                          type={"number"}
                          value={cyData?.less_than_1_year}
                        // onChange={(e) =>
                        //   HandleDisChange(
                        //     e.target.value,
                        //     i,
                        //     "less_than_1_year",
                        //     "cwip_cy"
                        //   )
                        // }
                        />
                      </td>
                      <td
                        style={{
                          width: "10%",
                          textAlign: "end",
                          paddingRight: "1rem",
                          backgroundColor: "#EBEBEB",
                        }}
                      >
                        <input
                          style={disInp}
                          type={"number"}
                          value={cyData?.year_1_2}
                        // onChange={(e) => HandleDisChange(e.target.value, i,"year_1_2", "cwip_cy") }
                        />
                      </td>
                      <td
                        style={{
                          width: "10%",
                          textAlign: "end",
                          paddingRight: "1rem",
                          backgroundColor: "#EBEBEB",
                        }}
                      >
                        <input
                          style={disInp}
                          type={"number"}
                          value={cyData?.year_2_3}
                        // onChange={(e) => HandleDisChange(e.target.value, i, "year_2_3", "cwip_cy") }
                        />
                      </td>
                      <td
                        style={{
                          width: "10%",
                          textAlign: "end",
                          paddingRight: "1rem",
                          backgroundColor: "#EBEBEB",
                        }}
                      >
                        <input
                          style={disInp}
                          type={"number"}
                          value={cyData?.more_than_3_year}
                        // onChange={(e) => HandleDisChange(e.target.value, i, "more_than_3_year", "cwip_cy") }
                        />
                      </td>
                      <td
                        style={{
                          width: "25%",
                          textAlign: "center",
                          backgroundColor: "#EBEBEB",
                        }}
                      >
                        {calTotalRow(cyData)}
                      </td>
                    </tr>
                    {extraDisclosure[cyType].length - 1 === index && <tr
                      style={{
                        height: "50px",
                        backgroundColor: " #F0DBC8",
                        padding: "10px 0 0 2rem",
                        alignContent: "center",
                        fontSize: "1.1rem",
                        fontWeight: "600",
                      }}
                    >
                      <td
                        style={{
                          width: "40%",
                          height: "33px",
                          textAlign: "left",
                          paddingLeft: "2rem",
                        }}
                      >
                        {" "}
                        Total amount
                      </td>
                      <td
                        style={{ width: "10%", textAlign: "end", paddingRight: "1rem" }}
                      ></td>
                      <td
                        style={{ width: "10%", textAlign: "end", paddingRight: "1rem" }}
                      ></td>
                      <td
                        style={{ width: "10%", textAlign: "end", paddingRight: "1rem" }}
                      ></td>
                      <td
                        style={{ width: "10%", textAlign: "end", paddingRight: "1rem" }}
                      ></td>
                      <td
                        style={{ width: "10%", textAlign: "center" }}
                      >
                        {calTotalOfTotal(extraDisclosure[cyType])}
                        <span style={{ marginLeft: "0.6rem" }}>
                          {
                            false &&
                            (
                              <Tooltip title="The value is not matching with third level template mode." placement="top">
                                <i
                                  style={{
                                    cursor: "pointer",
                                    color: "darkorange",
                                    transform: "scale(1.2)",
                                  }}
                                  className="fas fa-info-circle"
                                />
                              </Tooltip>
                            )
                          }
                        </span>
                      </td>
                    </tr>}
                  </React.Fragment>
                ))}
              <tr
                style={{
                  height: "50px",
                  backgroundColor: "#EBEBEB",
                  padding: "10px 0 0 2rem",
                  alignContent: "center",
                  fontSize: "1.1rem",
                  fontWeight: "600",
                }}
              >
                <td
                  style={{
                    width: "40%",
                    height: "33px",
                    textAlign: "left",
                    paddingLeft: "2rem",
                    color: "black",
                  }}
                >
                  Previous Year
                </td>
                <td
                  style={{ width: "10%", textAlign: "end", paddingRight: "1rem" }}
                ></td>
                <td
                  style={{ width: "10%", textAlign: "end", paddingRight: "1rem" }}
                ></td>
                <td
                  style={{ width: "10%", textAlign: "end", paddingRight: "1rem" }}
                ></td>
                <td
                  style={{ width: "10%", textAlign: "end", paddingRight: "1rem" }}
                ></td>
                <td style={{ width: "10%", textAlign: "center" }}></td>
              </tr>
              {extraDisclosure.hasOwnProperty(pyType) &&
                extraDisclosure[pyType].length > 0 &&
                extraDisclosure[pyType].map((pyData, index) => (
                  <React.Fragment>
                    <tr style={level3}>
                      <td
                        style={{
                          width: "40%",
                          height: "70px",
                          textAlign: "left",
                          paddingLeft: "2rem",
                          backgroundColor: "#EBEBEB",
                        }}
                      >
                        {pyData?.header}
                      </td>
                      <td
                        style={{
                          width: "10%",
                          textAlign: "end",
                          paddingRight: "1rem",
                          backgroundColor: "#EBEBEB",
                        }}
                      >
                        <input
                          style={disInp}
                          type={"number"}
                          value={pyData?.less_than_1_year}
                        // onChange={(e) =>
                        //   HandleDisChange(
                        //     e.target.value,
                        //     i,
                        //     "less_than_1_year",
                        //     "cwip_cy"
                        //   )
                        // }
                        />
                      </td>
                      <td
                        style={{
                          width: "10%",
                          textAlign: "end",
                          paddingRight: "1rem",
                          backgroundColor: "#EBEBEB",
                        }}
                      >
                        <input
                          style={disInp}
                          type={"number"}
                          value={pyData?.year_1_2}
                        // onChange={(e) => HandleDisChange(e.target.value, i,"year_1_2", "cwip_cy") }
                        />
                      </td>
                      <td
                        style={{
                          width: "10%",
                          textAlign: "end",
                          paddingRight: "1rem",
                          backgroundColor: "#EBEBEB",
                        }}
                      >
                        <input
                          style={disInp}
                          type={"number"}
                          value={pyData?.year_2_3}
                        // onChange={(e) => HandleDisChange(e.target.value, i, "year_2_3", "cwip_cy") }
                        />
                      </td>
                      <td
                        style={{
                          width: "10%",
                          textAlign: "end",
                          paddingRight: "1rem",
                          backgroundColor: "#EBEBEB",
                        }}
                      >
                        <input
                          style={disInp}
                          type={"number"}
                          value={pyData?.more_than_3_year}
                        // onChange={(e) => HandleDisChange(e.target.value, i, "more_than_3_year", "cwip_cy") }
                        />
                      </td>
                      <td
                        style={{
                          width: "25%",
                          textAlign: "center",
                          backgroundColor: "#EBEBEB",
                        }}
                      >
                        {calTotalRow(pyData)}
                      </td>
                    </tr>
                    {extraDisclosure[pyType].length - 1 === index &&
                      <tr
                        style={{
                          height: "50px",
                          backgroundColor: " #F0DBC8",
                          padding: "10px 0 0 2rem",
                          alignContent: "center",
                          fontSize: "1.1rem",
                          fontWeight: "600",
                        }}
                      >
                        <td
                          style={{
                            width: "40%",
                            height: "33px",
                            textAlign: "left",
                            paddingLeft: "2rem",
                          }}
                        >
                          Total amount
                        </td>
                        <td
                          style={{ width: "10%", textAlign: "end", paddingRight: "1rem" }}
                        ></td>
                        <td
                          style={{ width: "10%", textAlign: "end", paddingRight: "1rem" }}
                        ></td>
                        <td
                          style={{ width: "10%", textAlign: "end", paddingRight: "1rem" }}
                        ></td>
                        <td
                          style={{ width: "10%", textAlign: "end", paddingRight: "1rem" }}
                        ></td>
                        <td
                          style={{ width: "10%", textAlign: "center" }}
                        >
                          {calTotalOfTotal(extraDisclosure[pyType])}
                          <span style={{ marginLeft: "0.6rem" }}>
                            {
                              false &&
                              (
                                <Tooltip title="The value is not matching with third level template mode." placement="top">
                                  <i
                                    style={{
                                      cursor: "pointer",
                                      color: "darkorange",
                                      transform: "scale(1.2)",
                                    }}
                                    className="fas fa-info-circle"
                                  />
                                </Tooltip>
                              )
                            }
                          </span>
                        </td>
                      </tr>}
                  </React.Fragment>
                ))}
            </tbody>
          </table>
        </>
      }
    </React.Fragment>

  );
}

const AgeingTable = ({ reduxProjectData, response, projectType, extraDisclosure, cyType, pyType }) => {
  const project_id = localStorage.getItem("project_id");
  const [ageingTableData, setAgeingTableData] = useState(extraDisclosure);

  const p_type = projectType === "Manufacturing";

  const checkCondition = (type, key = null, indexes = null) => {
    try {

      if (type === "patten_1") {
        const { cy_amt, py_amt } = response?.data[0]?.sub_grp.find(ele => ele.sub_grp === "Payments to Statutory auditors") || {};
        return calculateTotal(type, key) === (key === "cy" ? cy_amt : py_amt) ? false : true;
      }

      const { cyAmtSum, pyAmtSum } = response?.data[0]?.sub_grp
        .filter(ele => ele.sub_grp !== "Loss allowance - Trade receivable")
        .reduce((sums, ele) => {
          sums.cyAmtSum += parseFloat(ele.cy_amt) || 0;
          sums.pyAmtSum += parseFloat(ele.py_amt) || 0;
          return sums;
        }, { cyAmtSum: 0, pyAmtSum: 0 });

      if (type === "ageing_table_borrowing_1" && key) {
        return grandTotalOfBorrowing(key, type) === (key === "cy" ? cyAmtSum : pyAmtSum) ? false : true;
      } else if (type === "equity_share_capital_table" && key) {
        return calculateTotal(type, key) === (key === "cy_rupees" ? cyAmtSum : pyAmtSum) ? false : true;
      } else if (type === "subordinated_liabilities_1" && key) {
        return calculateTotal(type, key) === (key === "cy" ? cyAmtSum : pyAmtSum) ? false : true;
      }

      if (calTotalOfTotal(ageingTableData[type].data, type) === cyAmtSum) {
        return false
      } else if (calTotalOfTotal(ageingTableData[type].data, type) === pyAmtSum) {
        return false
      } else {
        return true
      }
    } catch (error) {
      return true
    }
  }

  const calTotalOfTotal = (cwipData, check_type) => {
    try {
      return cwipData.map(rowData => calculateTotal(check_type, rowData)).reduce((num, acc) => num + acc, 0)
    } catch (error) {
      return '*'
    }
  }

  const HandleChange = (event, type, index, subIndex = null) => {
    try {
      const notesArray = ["Loans", "Trade receivables", "Trade payables"];
      const copy = notesArray.includes(type) ? [...extraDisclosure] : { ...extraDisclosure };
      if (notesArray.includes(type)) {
        copy[index].data[subIndex][event.target.name] = convertStrToNumber(event.target.value)
      } else {
        if (index === "headerName") {
          copy[type].headerName = event
        } else {
          copy[type].data[index][event.target.name] = event.target.name === "header" ? event.target.value : convertStrToNumber(event.target.value);
        }
      }
      setAgeingTableData(copy);
      //? Socked code for update
      socket.emit("auto-save-note-ageing-table", {
        project_id: project_id,
        contents: copy,
        note_no: response?.notes_no,
        nlid: response?.nlid
      });
    } catch (error) {
      swal("Error", (error || "Update value failed !"), "error")
    }
  }

  socket.on('auto-save-note-ageing-table', (data) => {
    if (!data.success) {
      swal("Error", data.message, "error")
    }
  });

  const calculateTotal = (type, data, isSub = false, indexes) => {
    try {
      if (type === "ageing_cy" || type === "ageing_py") {
        return ['less_then_1_year', 'less_then_6_month', 'more_than_3_year', 'year_1_2', 'year_2_3']
          .reduce((total, key) => total + Number(data?.[key] || 0), 0);
      } if (type === "ageing_table_ageing_capital_work_progress" || type === "ageing_table_ageing_capital_work_progress_cwip") {
        return ['Less_than_1yr', '1_to_2yr', '2_to_3yr', 'More_than_3_yr']
          .reduce((total, key) => total + Number(data?.[key] || 0), 0);
      }
      else if (type === 'ageing_other') {
        return ageingTableData[type]?.data.reduce((sum, item) => sum + Number(item[data]), 0);
      } else if (type === 'debt_ageing_debt_securities') {
        if (isSub) {
          return ageingTableData[type]?.data
            ?.slice(indexes.ix_0, indexes.ix_1)
            .reduce((sum, item) => sum + Number(item?.[data] || 0), 0);
        } else {
          return ageingTableData[type]?.data.reduce((sum, item) => sum + Number(item[data]), 0);
        }
      } else if (type === 'debt_ageing_other') {
        if (isSub) {
          return ageingTableData[type]?.data
            ?.slice(indexes.ix_0, indexes.ix_1)
            .reduce((sum, item) => sum + Number(item?.[data] || 0), 0);
        } else {
          return ageingTableData[type]?.data.reduce((sum, item) => sum + Number(item[data]), 0);
        }
      } else if (type === 'ageing_other_pattern_2') {
        if (isSub) {
          return ageingTableData[type]?.data
            ?.slice(indexes.ix_0, indexes.ix_1)
            .reduce((sum, item) => sum + Number(item?.[data] || 0), 0);
        } else {
          return ageingTableData[type]?.data.reduce((sum, item) => sum + Number(item[data]), 0);
        }
      } else if (type === 'ageing_table_Netgain_fair_changes') {
        if (isSub) {
          return ageingTableData[type]?.data
            ?.slice(indexes.ix_0, indexes.ix_1)
            .reduce((sum, item) => sum + Number(item?.[data] || 0), 0);
        } else {
          return ageingTableData[type]?.data.reduce((sum, item) => sum + Number(item[data]), 0);
        }
      } else if (type === 'other_equity_1') {
        if (isSub) {
          return ageingTableData[type]?.data
            ?.slice(indexes.ix_0, indexes.ix_1)
            .reduce((sum, item) => sum + Number(item?.[data] || 0), 0);
        } else {
          return ageingTableData[type]?.data.reduce((sum, item) => sum + Number(item[data]), 0);
        }
      } else if (type === 'pattern_2') {
        if (isSub) {
          return ageingTableData[type]?.data
            ?.slice(indexes.ix_0, indexes.ix_1)
            .reduce((sum, item) => sum + Number(item?.[data] || 0), 0);
        } else {
          return ageingTableData[type]?.data.reduce((sum, item) => sum + Number(item[data]), 0);
        }
      } else if (type === 'pattern_3') {
        if (isSub) {
          return ageingTableData[type]?.data
            ?.slice(indexes.ix_0, indexes.ix_1)
            .reduce((sum, item) => sum + Number(item?.[data] || 0), 0);
        } else {
          return ageingTableData[type]?.data.reduce((sum, item) => sum + Number(item[data]), 0);
        }
      } else if (type === 'ageing_table_borrowing') {
        if (isSub) {
          return ageingTableData[type]?.data
            ?.slice(indexes.ix_0, indexes.ix_1)
            .reduce((sum, item) => sum + Number(item?.[data] || 0), 0);
        } else {
          return ageingTableData[type]?.data.reduce((sum, item) => sum + Number(item[data]), 0);
        }
      } else if (type === 'ageing_table_borrowing_1') {
        if (isSub) {
          return ageingTableData[type]?.data
            ?.slice(indexes.ix_0, indexes.ix_1)
            .reduce((sum, item) => sum + Number(item?.[data] || 0), 0);
        } else {
          return ageingTableData[type]?.data.reduce((sum, item) => sum + Number(item[data]), 0);
        }
      } else if (type === 'ageing_table_borrowing_4') {
        if (isSub) {
          return ageingTableData[type]?.data
            ?.slice(indexes.ix_0, indexes.ix_1)
            .reduce((sum, item) => sum + Number(item?.[data] || 0), 0);
        } else {
          return ageingTableData[type]?.data.reduce((sum, item) => sum + Number(item[data]), 0);
        }
      } else if (type === 'ageing_table_borrowing_2') {
        if (isSub) {
          return ageingTableData[type]?.data
            ?.slice(indexes.ix_0, indexes.ix_1)
            .reduce((sum, item) => sum + Number(item?.[data] || 0), 0);
        } else {
          return ageingTableData[type]?.data.reduce((sum, item) => sum + Number(item[data]), 0);
        }
      } else if (type === "subordinated_liabilities_1") {
        if (isSub) {
          return ageingTableData[type]?.data
            ?.slice(indexes.ix_0, indexes.ix_1)
            .reduce((sum, item) => sum + Number(item?.[data] || 0), 0);
        } else {
          return ageingTableData[type]?.data.reduce((sum, item) => sum + Number(item[data]), 0);
        }
      }
      else if (type === "equity_share_capital_table_1") {
        return ageingTableData[type]?.data.reduce((sum, item) => sum + Number(item[data]), 0);
      } else if (type === "equity_share_capital_table") {
        return ageingTableData[type]?.data.reduce((sum, item) => sum + Number(item[data]), 0);
      }
      else if (type === "pattern_1") {
        return ageingTableData[type]?.data.reduce((sum, item) => sum + Number(item[data]), 0);
      }
      else if (type === "patten_2") {
        return ageingTableData[type]?.data.reduce((sum, item) => sum + Number(item[data]), 0);
      } else if (type === "ageing_table_ageing_capital_work_progress") {
        return ageingTableData[type]?.data.reduce((sum, item) => sum + Number(item[data]), 0);
      }
      else if (type === "patten_1") {
        if (isSub) {
          return ageingTableData[type]?.data
            ?.slice(indexes.ix_0, indexes.ix_1)
            .reduce((sum, item) => sum + Number(item?.[data] || 0), 0);
        } else {
          return ageingTableData[type]?.data.reduce((sum, item) => sum + Number(item[data]), 0);
        }
      }
    } catch (error) {
      swal("Error", (error || "Calculation failed !"), "error")
    }
  }

  const grandTotalOfBorrowing = (type, key) => {
    try {
      if (key === "ageing_table_borrowing_1") {
        return (totalofBorrowings(type) + calculateTotal(key, type, true, { ix_0: 8, ix_1: 14 }))
      }
    } catch (error) {
      swal("Error", (error || "Calculation failed !"), "error")
    }
  }
  const totalofBorrowings = (type) => {
    const total1 = calculateTotal("ageing_table_borrowing_1", type, true, { ix_0: 1, ix_1: 6 });
    const total2 = calculateTotal("ageing_table_borrowing", type, true, { ix_0: 0, ix_1: 19 });

    return (total1 + total2) || 0;
  };
  const totalsofDeferred = (key, type) => {
    if (key === "pattern_2") {
      const total1 = calculateTotal(key, type, true, { ix_0: 2, ix_1: 12 });
      const total2 = calculateTotal(key, type, true, { ix_0: 12, ix_1: 17 });

      return (total1 - total2) || 0
    } else {
      const total1 = calculateTotal(key, type, true, { ix_0: 1, ix_1: 9 });
      const total2 = calculateTotal(key, type, true, { ix_0: 9, ix_1: 15 });

      return (total1 - total2) || 0
    }
  };

  const TotalOfaRow = obj => Object.values(obj).reduce((acc, value) => acc + (typeof value === 'number' ? value : 0), 0);

  return (
    <React.Fragment>
      {
        ageingTableData.hasOwnProperty(cyType) && (
          <>
            <br />
            <div>
              {
                ageingTableData[cyType]?.headerName && <CkEditor value={ageingTableData[cyType]?.headerName} onChange={(data) => { HandleChange(data, cyType, 'headerName') }} />
              }
              <p className="ml-3 font-weight-bold m-1 font-sans ">Current Year</p>
              <table>
                <thead>
                  <tr style={{ backgroundColor: "#A2C0C2" }}>
                    <th className="pl-3 text-left" style={{ width: "47%" }}>
                      Particular
                    </th>
                    <th className="w-auto font-sans">Less than 6 months</th>
                    <th className="w-auto font-sans">6 months - 1 year</th>
                    <th className="w-auto font-sans">1-2 year</th>
                    <th className="w-auto font-sans">2-3 year</th>
                    <th className="w-auto font-sans">more than 3 year</th>
                    <th className="w-auto font-sans">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {ageingTableData[cyType]?.data.length > 0 &&
                    ageingTableData[cyType]?.data.map((cyData, index) => (
                      <React.Fragment>
                        <tr>
                          <td>
                            <input
                              className='p-0 mt-0 w-100 border-0 pl-3 text-left bg-color-inherit font-sans'
                              type={"text"}
                              readOnly={p_type}
                              value={cyData?.header}
                              name="header"
                              onChange={(e) => HandleChange(e, cyType, index)}
                            />
                          </td>
                          <td>
                            <input
                              className='p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit font-sans'
                              type={"text"}
                              value={formatIndianCurrency(cyData?.less_then_6_month)}
                              name="less_then_6_month"
                              onChange={(e) => HandleChange(e, cyType, index)}
                            />
                          </td>
                          <td>
                            <input
                              className='p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit font-sans'
                              type={"text"}
                              value={formatIndianCurrency(cyData?.less_then_1_year)}
                              name="less_then_1_year"
                              onChange={(e) => HandleChange(e, cyType, index)}
                            />
                          </td>
                          <td>
                            <input
                              className='p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit font-sans'
                              type={"text"}
                              value={formatIndianCurrency(cyData?.year_1_2)}
                              name="year_1_2"
                              onChange={(e) => HandleChange(e, cyType, index)}
                            />
                          </td>
                          <td>
                            <input
                              className='p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit font-sans'
                              type={"text"}
                              value={formatIndianCurrency(cyData?.year_2_3)}
                              name="year_2_3"
                              onChange={(e) => HandleChange(e, cyType, index)}
                            />
                          </td>
                          <td>
                            <input
                              className='p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit font-sans'
                              type={"text"}
                              value={formatIndianCurrency(cyData?.more_than_3_year)}
                              name="more_than_3_year"
                              onChange={(e) => HandleChange(e, cyType, index)}
                            />
                          </td>
                          <td className="font-sans">
                            {formatIndianCurrency(calculateTotal(cyType, cyData))}
                          </td>
                        </tr>
                        {
                          ageingTableData[cyType].data.length - 1 === index &&
                          <tr style={{ backgroundColor: "#f0dbc8" }}>
                            <td className='text-left pl-3 font-weight-bold text-secondary font-sans '>
                              Total amount
                            </td>
                            <td className='text-left pl-3 font-weight-bold text-secondary font-sans'></td>
                            <td className='text-left pl-3 font-weight-bold text-secondary font-sans'></td>
                            <td className='text-left pl-3 font-weight-bold text-secondary font-sans'></td>
                            <td className='text-left pl-3 font-weight-bold text-secondary font-sans'></td>
                            <td className='text-left pl-3 font-weight-bold text-secondary font-sans'></td>
                            <td className=' font-weight-bold text-secondary font-sans'>
                              {formatIndianCurrency(calTotalOfTotal(ageingTableData[cyType].data, cyType))}
                              <span style={{ marginLeft: "0.6rem" }}>
                                {
                                  checkCondition(cyType) &&
                                  (
                                    <Tooltip title="The value is not matching with third level template mode." placement="top">
                                      <i
                                        style={{
                                          cursor: "pointer",
                                          color: "darkorange",
                                          transform: "scale(1.2)",
                                        }}
                                        className="fas fa-info-circle"
                                      />
                                    </Tooltip>
                                  )
                                }
                              </span>
                            </td>
                          </tr>
                        }
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </>
        )
      }
      {
        ageingTableData.hasOwnProperty(pyType) && (
          <>
            <br />
            <div>
              {
                ageingTableData[pyType]?.headerName && <CkEditor value={ageingTableData[pyType]?.headerName} onChange={(data) => { HandleChange(data, pyType, 'headerName') }} />
              }
              <p className="ml-3 font-weight-bold m-1">Previous Year</p>
              <table>
                <thead>
                  <tr style={{ backgroundColor: "#A2C0C2" }}>
                    <th className="pl-3 text-left" style={{ width: "47%" }}>
                      Particular
                    </th>
                    <th className="w-auto font-sans">Less than 6 months</th>
                    <th className="w-auto font-sans">6 months - 1 year</th>
                    <th className="w-auto font-sans">1-2 year</th>
                    <th className="w-auto font-sans">2-3 year</th>
                    <th className="w-auto font-sans">more than 3 year</th>
                    <th className="w-auto font-sans">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {ageingTableData[pyType]?.data.length > 0 &&
                    ageingTableData[pyType]?.data.map((pyData, index) => (
                      <React.Fragment>
                        <tr>
                          <td>
                            <input
                              className='p-0 mt-0 w-100 border-0 pl-3 text-left bg-color-inherit font-sans'
                              type={"text"}
                              readOnly={p_type}
                              value={pyData?.header}
                              name="header"
                              onChange={(e) => HandleChange(e, pyType, index)}
                            />
                          </td>
                          <td>
                            <input
                              className='p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit font-sans'
                              type={"text"}
                              value={formatIndianCurrency(pyData?.less_then_6_month)}
                              name="less_then_6_month"
                              onChange={(e) => HandleChange(e, pyType, index)}
                            />
                          </td>
                          <td>
                            <input
                              className='p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit font-sans'
                              type={"text"}
                              value={formatIndianCurrency(pyData?.less_then_1_year)}
                              name="less_then_1_year"
                              onChange={(e) => HandleChange(e, pyType, index)}
                            />
                          </td>
                          <td>
                            <input
                              className='p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit font-sans'
                              type={"text"}
                              value={formatIndianCurrency(pyData?.year_1_2)}
                              name="year_1_2"
                              onChange={(e) => HandleChange(e, pyType, index)}
                            />
                          </td>
                          <td>
                            <input
                              className='p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit font-sans'
                              type={"text"}
                              value={formatIndianCurrency(pyData?.year_2_3)}
                              name="year_2_3"
                              onChange={(e) => HandleChange(e, pyType, index)}
                            />
                          </td>
                          <td>
                            <input
                              className='p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit font-sans'
                              type={"text"}
                              value={formatIndianCurrency(pyData?.more_than_3_year)}
                              name="more_than_3_year"
                              onChange={(e) => HandleChange(e, pyType, index)}
                            />
                          </td>
                          <td className="font-sans">
                            {formatIndianCurrency(calculateTotal(pyType, pyData))}
                          </td>
                        </tr>

                        {
                          ageingTableData[pyType].data.length - 1 === index &&
                          <tr style={{ backgroundColor: "#f0dbc8" }}>
                            <td className='text-left pl-3 font-weight-bold text-secondary font-sans'>
                              Total amount
                            </td>
                            <td className='text-left pl-3 font-weight-bold text-secondary font-sans'></td>
                            <td className='text-left pl-3 font-weight-bold text-secondary font-sans'></td>
                            <td className='text-left pl-3 font-weight-bold text-secondary font-sans'></td>
                            <td className='text-left pl-3 font-weight-bold text-secondary font-sans'></td>
                            <td className='text-left pl-3 font-weight-bold text-secondary font-sans'></td>
                            <td className='font-weight-bold text-secondary'>
                              {formatIndianCurrency(calTotalOfTotal(ageingTableData[pyType].data, pyType))}
                              <span style={{ marginLeft: "0.6rem" }}>
                                {
                                  checkCondition(pyType) &&
                                  (
                                    <Tooltip className="font-sans" title="The value is not matching with third level template mode." placement="top">
                                      <i
                                        style={{
                                          cursor: "pointer",
                                          color: "darkorange",
                                          transform: "scale(1.2)",
                                        }}
                                        className="fas fa-info-circle"
                                      />
                                    </Tooltip>
                                  )
                                }
                              </span>
                            </td>
                          </tr>
                        }
                      </React.Fragment>
                    ))}

                </tbody>
              </table>
            </div>
          </>
        )
      }
      {
        ageingTableData?.ageing_other && (
          <>
            <br />
            <div>
              {
                ageingTableData?.ageing_other?.headerName && <CkEditor value={ageingTableData?.ageing_other?.headerName} onChange={(data) => { HandleChange(data, "ageing_other", 'headerName') }} />
              }
              <table>
                <thead >
                  <tr style={{ backgroundColor: "#A2C0C2" }}>
                    <th className="pl-3 w-75 text-left font-sans">Particulars</th>
                    <th className="w-auto font-sans">As at {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
                    <th className="w-auto font-sans">As at {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    ageingTableData?.ageing_other?.data && ageingTableData?.ageing_other?.data.length > 0 &&
                    ageingTableData?.ageing_other?.data?.map((ele, ix) => (
                      <tr >
                        <td>
                          <textarea
                            className='p-0 mt-0 w-100 border-0 pl-3 text-left bg-color-inherit font-sans'
                            type={"text"}
                            value={ele?.header}
                            name="header"
                            onChange={(e) => HandleChange(e, "ageing_other", ix)}
                          />
                        </td>
                        <td

                        >
                          <input
                            className='p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit font-sans'
                            type={"text"}
                            value={formatIndianCurrency(ele?.cy)}
                            name="cy"
                            onChange={(e) => HandleChange(e, 'ageing_other', ix)}
                          />
                        </td>
                        <td

                        >
                          <input
                            className='p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit font-sans'
                            type={"text"}
                            value={formatIndianCurrency(ele?.py)}
                            name="py"
                            onChange={(e) => HandleChange(e, 'ageing_other', ix)}
                          />
                        </td>
                      </tr>
                    ))
                  }
                  <tr style={{ backgroundColor: "#f0dbc8" }} >
                    <td className='text-left pl-3 font-weight-bold text-secondary font-sans'>Total</td>
                    <td className='text-right pr-2rem font-weight-bold text-secondary font-sans'> {formatIndianCurrency(calculateTotal('ageing_other', "cy"))} </td>
                    <td className='text-right pr-2rem font-weight-bold text-secondary font-sans'> {formatIndianCurrency(calculateTotal('ageing_other', "py"))} </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )
      }
      {/* debt securities */}
      {
        ageingTableData?.debt_ageing_debt_securities && (
          <>
            <br />
            <div>
              {
                ageingTableData?.debt_ageing_debt_securities?.headerName && <CkEditor value={ageingTableData?.debt_ageing_debt_securities?.headerName} onChange={(data) => { HandleChange(data, 'debt_ageing_debt_securities', 'headerName') }} />
              }
              <table>
                <thead >
                  <tr style={{ backgroundColor: "#A2C0C2" }}>
                    <th className="pl-3 w-50 text-left font-sans">Particulars</th>
                    <th className="w-auto font-sans">As at {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
                    <th className="w-auto font-sans">As at {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    ageingTableData?.debt_ageing_debt_securities?.data && ageingTableData?.debt_ageing_debt_securities?.data.length > 0 &&
                    ageingTableData?.debt_ageing_debt_securities?.data?.map((ele, ix) => (
                      <>
                        <tr >
                          <td>
                            <textarea
                              className={`p-0 mt-0 w-100 border-0 pl-3 text-left bg-color-inherit ${ele?.isHeading && "font-weight-bold"}`}
                              type={"text"}
                              value={ele?.header}
                              name="header"
                              onChange={(e) => HandleChange(e, "debt_ageing_debt_securities", ix)}
                            />
                          </td>
                          <td

                          >
                            <input
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                              readOnly={typeof ele?.cy !== "number"}
                              type={"text"}
                              value={formatIndianCurrency(ele?.cy)}
                              name="cy"
                              onChange={(e) => HandleChange(e, 'debt_ageing_debt_securities', ix)}
                            />
                          </td>
                          <td

                          >
                            <input
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                              readOnly={typeof ele?.py !== "number"}
                              type={"text"}
                              value={formatIndianCurrency(ele?.py)}
                              name="py"
                              onChange={(e) => HandleChange(e, 'debt_ageing_debt_securities', ix)}
                            />
                          </td>
                        </tr>
                        {
                          [5, ageingTableData?.debt_ageing_debt_securities?.data.length - 1].includes(ix) && (
                            <>
                              <tr className="font-weight-bold">
                                <td className='text-left pl-3 font-weight-bold text-secondary font-sans'>
                                  {
                                    ix === 5 ? "" :
                                      ix === ageingTableData?.debt_ageing_debt_securities?.data.length - 1 ? "" : ""
                                  }
                                </td>
                                <td className='text-right pr-3 font-weight-bold text-secondary font-sans'>
                                  {
                                    ix === 5 ? formatIndianCurrency(calculateTotal("debt_ageing_debt_securities", "cy", true, { ix_0: 2, ix_1: 6 })) :
                                      ix === ageingTableData?.debt_ageing_debt_securities?.data.length - 1 ? formatIndianCurrency(calculateTotal("debt_ageing_debt_securities", "cy", true, { ix_0: 6, ix_1: 8 })) : ""
                                  }
                                </td>
                                <td className='text-right pr-3 font-weight-bold text-secondary font-sans'>
                                  {
                                    ix === 5 ? formatIndianCurrency(calculateTotal("debt_ageing_debt_securities", "py", true, { ix_0: 2, ix_1: 6 })) :
                                      ix === ageingTableData?.debt_ageing_debt_securities?.data.length - 1 ? formatIndianCurrency(calculateTotal("debt_ageing_debt_securities", "py", true, { ix_0: 6, ix_1: 8 })) : ""
                                  }
                                </td>
                              </tr>
                            </>
                          )
                        }
                      </>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </>
        )
      }

      {
        ageingTableData?.debt_ageing_other && (
          <>
            <br />
            <div>
              {
                ageingTableData?.debt_ageing_other?.headerName && <CkEditor value={ageingTableData?.debt_ageing_other?.headerName} onChange={(data) => { HandleChange(data, 'debt_ageing_other', 'headerName') }} />
              }
              <table>
                <thead >
                  <tr style={{ backgroundColor: "#A2C0C2" }}>
                    <th className="pl-3 w-75 text-left font-sans">Security Clause</th>
                    <th className="w-auto font-sans">{moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
                    <th className="w-auto font-sans">{moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    ageingTableData?.debt_ageing_other?.data && ageingTableData?.debt_ageing_other?.data.length > 0 &&
                    ageingTableData?.debt_ageing_other?.data?.map((ele, ix) => (
                      <>
                        <tr >
                          <td>
                            <textarea
                              className={`p-0 mt-0 w-100 border-0 pl-3 text-left bg-color-inherit ${ele?.isHeading && "font-weight-bold"}`}
                              type={"text"}
                              value={ele?.header}
                              name="header"
                              onChange={(e) => HandleChange(e, "debt_ageing_other", ix)}
                            />
                          </td>
                          <td

                          >
                            <input
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit font-sans"
                              type={"text"}
                              value={formatIndianCurrency(ele?.cy)}
                              name="cy"
                              onChange={(e) => HandleChange(e, 'debt_ageing_other', ix)}
                            />
                          </td>
                          <td

                          >
                            <input
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit font-sans"
                              type={"text"}
                              value={formatIndianCurrency(ele?.py)}
                              name="py"
                              onChange={(e) => HandleChange(e, 'debt_ageing_other', ix)}
                            />
                          </td>
                        </tr>
                        {
                          [1, ageingTableData?.debt_ageing_other?.data.length - 1].includes(ix) && (
                            <>
                              <tr className="font-weight-bold">
                                <td className='text-left pl-3 font-weight-bold text-secondary font-sans'>
                                  {
                                    ix === 1 ? "Subtotal" :
                                      ix === ageingTableData?.debt_ageing_other?.data.length - 1 ? "Total" : ""
                                  }
                                </td>
                                <td className='text-right pr-3 font-weight-bold text-secondary font-sans'>
                                  {
                                    ix === 1 ? formatIndianCurrency(calculateTotal("debt_ageing_other", "cy", true, { ix_0: 0, ix_1: 2 })) :
                                      ix === ageingTableData?.debt_ageing_other?.data.length - 1 ? formatIndianCurrency(calculateTotal("debt_ageing_other", "cy")) : ""
                                  }
                                </td>
                                <td className='text-right pr-3 font-weight-bold text-secondary font-sans'>
                                  {
                                    ix === 1 ? formatIndianCurrency(calculateTotal("debt_ageing_other", "py", true, { ix_0: 0, ix_1: 2 })) :
                                      ix === ageingTableData?.debt_ageing_other?.data.length - 1 ? formatIndianCurrency(calculateTotal("debt_ageing_other", "py")) : ""
                                  }
                                </td>
                              </tr>
                            </>
                          )
                        }
                      </>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </>
        )
      }
      {
        ageingTableData?.ageing_other_pattern_2 && (
          <>
            <br />
            <div>
              {
                ageingTableData?.ageing_other_pattern_2?.headerName && <CkEditor value={ageingTableData?.ageing_other_pattern_2?.headerName} onChange={(data) => { HandleChange(data, 'ageing_other_pattern_2', 'headerName') }} />
              }
              <table>
                <thead >
                  <tr style={{ backgroundColor: "#A2C0C2" }}>
                    <th className="pl-3 w-50 text-left">Particulars</th>
                    <th className="w-auto">Interest Rate Range</th>
                    <th className="w-auto">{moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
                    <th className="w-auto">{moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    ageingTableData?.ageing_other_pattern_2?.data && ageingTableData?.ageing_other_pattern_2?.data.length > 0 &&
                    ageingTableData?.ageing_other_pattern_2?.data?.map((ele, ix) => (
                      <>
                        <tr>
                          <td>
                            <textarea
                              className={`p-0 mt-0 w-100 border-0 pl-3 text-left bg-color-inherit ${ele?.isHeading && "font-weight-bold"}`}
                              type={"text"}
                              value={ele?.header}
                              name="header"
                              onChange={(e) => HandleChange(e, "ageing_other_pattern_2", ix)}
                            />
                          </td>
                          <td

                          >
                            <input
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                              type={"text"}
                              readOnly={typeof ele?.interest_rate_range !== "number"}
                              value={formatIndianCurrency(ele?.interest_rate_range)}
                              name="interest_rate_range"
                              onChange={(e) => HandleChange(e, 'ageing_other_pattern_2', ix)}
                            />

                          </td>

                          <td

                          >
                            <input
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                              type={"text"}
                              readOnly={typeof ele?.cy !== "number"}
                              value={formatIndianCurrency(ele?.cy)}
                              name="cy"
                              onChange={(e) => HandleChange(e, 'ageing_other_pattern_2', ix)}
                            />

                          </td>
                          <td

                          >
                            <input
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                              type={"text"}
                              readOnly={typeof ele?.py !== "number"}
                              value={formatIndianCurrency(ele?.py)}
                              name="py"
                              onChange={(e) => HandleChange(e, 'ageing_other_pattern_2', ix)}
                            />
                          </td>

                        </tr>
                        {
                          [6, 8, 14, 16, ageingTableData?.ageing_other_pattern_2?.data.length - 1].includes(ix) && (
                            <>
                              <tr className="font-weight-bold">
                                <td className="text-left pl-3 font-weight-bold text-secondary">
                                  {
                                    ix === 6 ? "Total Payable on Maturity" :
                                      ix === 8 ? "Total Carrying amount" :
                                        ix === 14 ? "Total Payable on Maturity" :
                                          ix === ageingTableData?.ageing_other_pattern_2?.data.length - 1 ? "Total carrying amount" : ""
                                  }
                                </td>
                                <td></td>
                                <td className="text-right pr-3 font-weight-bold text-secondary">
                                  {
                                    ix === 6 ? formatIndianCurrency(calculateTotal("ageing_other_pattern_2", "cy", true, { ix_0: 3, ix_1: 7 })) :
                                      ix === 8 ? formatIndianCurrency(calculateTotal("ageing_other_pattern_2", "cy", true, { ix_0: 3, ix_1: 10 })) :
                                        ix === 14 ? formatIndianCurrency(calculateTotal("ageing_other_pattern_2", "cy", true, { ix_0: 11, ix_1: 15 })) :
                                          ix === ageingTableData?.ageing_other_pattern_2?.data.length - 1 ?
                                          formatIndianCurrency(calculateTotal("ageing_other_pattern_2", "cy", true, { ix_0: 11, ix_1: 17 })) : ""
                                  }
                                </td>
                                <td className="text-right pr-3 font-weight-bold text-secondary">
                                  {
                                    ix === 6 ? formatIndianCurrency(calculateTotal("ageing_other_pattern_2", "py", true, { ix_0: 3, ix_1: 7 })) :
                                      ix === 8 ? formatIndianCurrency(calculateTotal("ageing_other_pattern_2", "py", true, { ix_0: 3, ix_1: 10 })) :
                                        ix === 14 ? formatIndianCurrency(calculateTotal("ageing_other_pattern_2", "py", true, { ix_0: 11, ix_1: 15 })) :
                                          ix === ageingTableData?.ageing_other_pattern_2?.data.length - 1 ?
                                          formatIndianCurrency(calculateTotal("ageing_other_pattern_2", "py", true, { ix_0: 11, ix_1: 17 })) : ""
                                  }
                                </td>
                              </tr>
                            </>
                          )
                        }
                      </>
                    ))
                  }
                  <tr className="font-weight-bold">
                    <td className="text-left pl-3 font-weight-bold text-secondary" >Total Amount</td>
                    <td></td>
                    <td className="text-right pr-3 font-weight-bold text-secondary"> {formatIndianCurrency(calculateTotal("ageing_other_pattern_2", "cy"))} </td>
                    <td className="text-right pr-3 font-weight-bold text-secondary"> {formatIndianCurrency(calculateTotal("ageing_other_pattern_2", "py"))} </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )
      }

      {/* Borrowings (other than debt securities)  */}

      {
        ageingTableData?.ageing_table_borrowing_4 && (
          <>
            <br />
            <div>
              {
                ageingTableData?.ageing_table_borrowing_4?.headerName && <CkEditor value={ageingTableData?.ageing_table_borrowing_4?.headerName} onChange={(data) => { HandleChange(data, 'ageing_table_borrowing_4', 'headerName') }} />
              }
              <table>
                <thead >
                  <tr style={{ backgroundColor: "#A2C0C2" }}>
                    <th className="pl-3 w-50 text-left">Particulars</th>
                    <th className="w-auto">As at<br />{moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
                    <th className="w-auto">As at<br />{moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    ageingTableData?.ageing_table_borrowing_4?.data && ageingTableData?.ageing_table_borrowing_4?.data.length > 0 &&
                    ageingTableData?.ageing_table_borrowing_4?.data?.map((ele, ix) => (
                      <>
                        <tr>
                          <td>
                            <textarea
                              className={`p-0 mt-1 w-100 border-0 pl-3 text-left bg-color-inherit ${ele?.isHeading && "font-weight-bold"}`}
                              type={"text"}
                              value={ele?.header}
                              name="header"
                              onChange={(e) => HandleChange(e, "ageing_table_borrowing_4", ix)}
                            />
                          </td>
                          <td>
                            <input
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                              type={"text"}
                              value={formatIndianCurrency(ele?.cy)}
                              readOnly={typeof ele?.cy !== "number"}
                              name="cy"
                              onChange={(e) => HandleChange(e, 'ageing_table_borrowing_4', ix)}
                            />

                          </td>
                          <td>
                            <input
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                              type={"text"}
                              value={formatIndianCurrency(ele?.py)}
                              readOnly={typeof ele?.py !== "number"}
                              name="py"
                              onChange={(e) => HandleChange(e, 'ageing_table_borrowing_4', ix)}
                            />
                          </td>
                        </tr>
                        {
                          [4, 7, 12, ageingTableData?.ageing_table_borrowing_4?.data.length - 1].includes(ix) && (
                            <>
                              <tr className="font-weight-bold">
                                <td className="text-left pl-3 font-weight-bold text-secondary">
                                  {
                                    ix === 4 ? "" :
                                      ix === 7 ? "" :
                                        ix === 12 ? "" :
                                          ix === ageingTableData?.ageing_table_borrowing_4?.data.length - 1 ? "" : ""
                                  }
                                </td>
                                <td className="text-right pr-3 font-weight-bold text-secondary">
                                  {
                                    ix === 4 ? formatIndianCurrency(calculateTotal("ageing_table_borrowing_4", "cy", true, { ix_0: 2, ix_1: 5 })) :
                                      ix === 7 ? formatIndianCurrency(calculateTotal("ageing_table_borrowing_4", "cy", true, { ix_0: 6, ix_1: 8 })) :
                                        ix === 12 ? formatIndianCurrency(calculateTotal("ageing_table_borrowing_4", "cy", true, { ix_0: 2, ix_1: 13 })) :
                                          ix === ageingTableData?.ageing_table_borrowing_4?.data.length - 1 ?
                                          formatIndianCurrency(calculateTotal("ageing_table_borrowing_4", "cy", true, { ix_0: 13, ix_1: 15 })) : ""
                                  }
                                </td>
                                <td className="text-right pr-3 font-weight-bold text-secondary">
                                  {
                                    ix === 4 ? formatIndianCurrency(calculateTotal("ageing_table_borrowing_4", "py", true, { ix_0: 2, ix_1: 5 })) :
                                      ix === 7 ? formatIndianCurrency(calculateTotal("ageing_table_borrowing_4", "py", true, { ix_0: 6, ix_1: 8 })) :
                                        ix === 12 ? formatIndianCurrency(calculateTotal("ageing_table_borrowing_4", "py", true, { ix_0: 2, ix_1: 13 })) :
                                          ix === ageingTableData?.ageing_table_borrowing_4?.data.length - 1 ?
                                          formatIndianCurrency(calculateTotal("ageing_table_borrowing_4", "py", true, { ix_0: 13, ix_1: 15 })) : ""
                                  }
                                </td>
                              </tr>
                            </>
                          )
                        }
                      </>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </>
        )
      }

      {
        ageingTableData?.ageing_table_borrowing && (
          <>
            <br />
            <div>
              {
                ageingTableData?.ageing_table_borrowing?.headerName && <CkEditor value={ageingTableData?.ageing_table_borrowing?.headerName} onChange={(data) => { HandleChange(data, 'ageing_table_borrowing', 'headerName') }} />
              }
              <table>
                <thead >
                  <tr style={{ backgroundColor: "#A2C0C2" }}>
                    <th className="pl-3 w-50 text-left">Particulars</th>
                    <th className="w-auto">Interest Rate Range</th>
                    <th className="w-auto">{moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
                    <th className="w-auto">{moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    ageingTableData?.ageing_table_borrowing?.data && ageingTableData?.ageing_table_borrowing?.data.length > 0 &&
                    ageingTableData?.ageing_table_borrowing?.data?.map((ele, ix) => (
                      <>
                        <tr>
                          <td>
                            <textarea
                              className={`p-0 mt-1 w-100 border-0 pl-3 text-left bg-color-inherit ${ele?.isHeading && "font-weight-bold"}`}
                              type={"text"}
                              value={ele?.header}
                              name="header"
                              onChange={(e) => HandleChange(e, "ageing_table_borrowing", ix)}
                            />
                          </td>
                          <td>
                            <input
                              readOnly={typeof ele?.interest_rate_range !== "number"}
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                              type={"text"}
                              value={formatIndianCurrency(ele?.interest_rate_range)}
                              name="interest_rate_range"
                              onChange={(e) => HandleChange(e, 'ageing_table_borrowing', ix)}
                            />
                          </td>
                          <td>
                            <input
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                              type={"text"}
                              value={formatIndianCurrency(ele?.cy)}
                              readOnly={typeof ele?.cy !== "number"}
                              name="cy"
                              onChange={(e) => HandleChange(e, 'ageing_table_borrowing', ix)}
                            />

                          </td>
                          <td>
                            <input
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                              type={"text"}
                              value={formatIndianCurrency(ele?.py)}
                              readOnly={typeof ele?.py !== "number"}
                              name="py"
                              onChange={(e) => HandleChange(e, 'ageing_table_borrowing', ix)}
                            />
                          </td>
                        </tr>
                        {
                          [6, 11, 14, 18].includes(ix) && (
                            <>
                              <tr className="font-weight-bold">
                                <td className="text-left pl-3 font-weight-bold text-secondary">
                                  {
                                    ix === 6 ? "Subtotal (A)" :
                                      ix === 11 ? "Subtotal (B)" :
                                        ix === 14 ? "Subtotal (C)" :
                                          ix === 18 ? "Subtotal (D)" : ""
                                  }
                                </td>
                                <td></td>
                                <td className="text-right pr-3 font-weight-bold text-secondary">
                                  {
                                    ix === 6 ? formatIndianCurrency(calculateTotal("ageing_table_borrowing", "cy", true, { ix_0: 3, ix_1: 7 })) :
                                      ix === 11 ? formatIndianCurrency(calculateTotal("ageing_table_borrowing", "cy", true, { ix_0: 8, ix_1: 12 })) :
                                        ix === 14 ? formatIndianCurrency(calculateTotal("ageing_table_borrowing", "cy", true, { ix_0: 13, ix_1: 15 })) :
                                          ix === 18 ? formatIndianCurrency(calculateTotal("ageing_table_borrowing", "cy", true, { ix_0: 16, ix_1: 19 })) : ""
                                  }
                                </td>
                                <td className="text-right pr-3 font-weight-bold text-secondary">
                                  {
                                    ix === 6 ? formatIndianCurrency(calculateTotal("ageing_table_borrowing", "py", true, { ix_0: 3, ix_1: 7 })) :
                                      ix === 11 ? formatIndianCurrency(calculateTotal("ageing_table_borrowing", "py", true, { ix_0: 8, ix_1: 12 })) :
                                        ix === 14 ? formatIndianCurrency(calculateTotal("ageing_table_borrowing", "py", true, { ix_0: 13, ix_1: 15 })) :
                                          ix === 18 ? formatIndianCurrency(calculateTotal("ageing_table_borrowing", "py", true, { ix_0: 16, ix_1: 19 })) : ""
                                  }
                                </td>
                              </tr>
                            </>
                          )
                        }
                      </>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </>
        )
      }
      {
        ageingTableData?.ageing_table_borrowing_1 && (
          <>
            <br />
            <div>
              {
                ageingTableData?.ageing_table_borrowing_1?.headerName && <CkEditor value={ageingTableData?.ageing_table_borrowing_1?.headerName} onChange={(data) => { HandleChange(data, 'ageing_table_borrowing_1', 'headerName') }} />
              }
              <table>
                <thead >
                  <tr style={{ backgroundColor: "#A2C0C2" }}>
                    <th className="pl-3 w-50 text-left">Particulars</th>
                    <th className="w-auto">Interest Rate Range</th>
                    <th className="w-auto">{moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
                    <th className="w-auto">{moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    ageingTableData?.ageing_table_borrowing_1?.data && ageingTableData?.ageing_table_borrowing_1?.data.length > 0 &&
                    ageingTableData?.ageing_table_borrowing_1?.data?.map((ele, ix) => (
                      <>
                        <tr >
                          <td>
                            <textarea
                              className={`p-0 mt-1 w-100 border-0 pl-3 text-left bg-color-inherit ${ele?.isHeading && "font-weight-bold"}`}
                              type={"text"}
                              value={ele?.header}
                              name="header"
                              onChange={(e) => HandleChange(e, "ageing_table_borrowing_1", ix)}
                            />
                          </td>
                          <td>
                            <input
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                              type={"text"}
                              readOnly={typeof ele?.interest_rate_range !== "number"}
                              value={formatIndianCurrency(ele?.interest_rate_range)}
                              name="interest_rate_range"
                              onChange={(e) => HandleChange(e, 'ageing_table_borrowing_1', ix)}
                            />
                          </td>
                          <td
                          >
                            <input
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                              type={"text"}
                              readOnly={typeof ele?.cy !== "number"}
                              value={formatIndianCurrency(ele?.cy)}
                              name="cy"
                              onChange={(e) => HandleChange(e, 'ageing_table_borrowing_1', ix)}
                            />

                          </td>
                          <td>
                            <input
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                              type={"text"}
                              readOnly={typeof ele?.py !== "number"}
                              value={formatIndianCurrency(ele?.py)}
                              name="py"
                              onChange={(e) => HandleChange(e, 'ageing_table_borrowing_1', ix)}
                            />
                          </td>
                        </tr>
                        {
                          [3, 5, ageingTableData?.ageing_table_borrowing_1?.data.length - 1].includes(ix) && (
                            <>
                              <tr className="font-weight-bold">
                                <td className="text-left pl-3 font-weight-bold text-secondary">
                                  {
                                    ix === 3 ? "Subtotal (C)" :
                                      ix === 5 ? "Total Secured" :
                                        ix === ageingTableData?.ageing_table_borrowing_1?.data.length - 1 ? "Total Unsecured" : ""
                                  }
                                </td>
                                <td></td>
                                <td className='text-right pr-3 font-weight-bold text-secondary'>
                                  {
                                    ix === 3 ? formatIndianCurrency(calculateTotal("ageing_table_borrowing_1", "cy", true, { ix_0: 1, ix_1: 4 })) :
                                      ix === 5 ? formatIndianCurrency(totalofBorrowings("cy")) : ""
                                  }
                                  {
                                    ix === ageingTableData?.ageing_table_borrowing_1?.data.length - 1 &&
                                    <>
                                      {formatIndianCurrency(calculateTotal("ageing_table_borrowing_1", "cy", true, { ix_0: 8, ix_1: 14 }))}
                                      {/* {
                                        checkCondition("ageing_table_borrowing_1", "cy", {start:8, end:14}) && 
                                        <CommonToolTip
                                          variant="primary"
                                          title={"The value is not balancing." }
                                          placement="top"
                                        >
                                            <ErrorIcon
                                style={{
                                    color: "darkorange",
                                    cursor: "pointer",
                                }}
                                className="financialInstrument-hoverIcon"
                            />
                                        </CommonToolTip> 
                                      } */}
                                    </>
                                  }
                                </td>
                                <td className='text-right pr-3 font-weight-bold text-secondary'>
                                  {
                                    ix === 3 ? formatIndianCurrency(calculateTotal("ageing_table_borrowing_1", "py", true, { ix_0: 1, ix_1: 4 })) :
                                      ix === 5 ? formatIndianCurrency(totalofBorrowings("py")) : ""
                                  }
                                  {
                                    ix === ageingTableData?.ageing_table_borrowing_1?.data.length - 1 &&
                                    <>
                                      {formatIndianCurrency(calculateTotal("ageing_table_borrowing_1", "py", true, { ix_0: 8, ix_1: 14 }))}
                                      {/* {
                                        checkCondition("ageing_table_borrowing_1", "py", {start:8, end:14}) && 
                                        <CommonToolTip
                                          variant="primary"
                                          title={"The value is not balancing." }
                                          placement="top"
                                        >
                                            <ErrorIcon
                                style={{
                                    color: "darkorange",
                                    cursor: "pointer",
                                }}
                                className="financialInstrument-hoverIcon"
                            />
                                        </CommonToolTip> 
                                      } */}
                                    </>
                                  }
                                </td>
                              </tr>
                            </>
                          )
                        }

                      </>
                    ))
                  }
                  <tr className="font-weight-bold">
                    <td className="text-left pl-3 font-weight-bold text-secondary" >Total Amount</td>
                    <td></td>
                    <td className="text-right pr-3 font-weight-bold text-secondary">
                      {
                        formatIndianCurrency(grandTotalOfBorrowing("cy", 'ageing_table_borrowing_1'))
                      }
                      {
                        checkCondition("ageing_table_borrowing_1", "cy") &&
                        <CommonToolTip
                          variant="primary"
                          title={"The value is not balancing."}
                          placement="top"
                        >
                          <ErrorIcon
                            style={{
                              color: "darkorange",
                              cursor: "pointer",
                            }}
                            className="financialInstrument-hoverIcon"
                          />
                        </CommonToolTip>
                      }
                    </td>
                    <td className="text-right pr-3 font-weight-bold text-secondary">
                      {formatIndianCurrency(grandTotalOfBorrowing("py", 'ageing_table_borrowing_1'))}
                      {
                        checkCondition("ageing_table_borrowing_1", "py") &&
                        <CommonToolTip
                          variant="primary"
                          title={"The value is not balancing."}
                          placement="top"
                        >
                          <ErrorIcon
                            style={{
                              color: "darkorange",
                              cursor: "pointer",
                            }}
                            className="financialInstrument-hoverIcon"
                          />
                        </CommonToolTip>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )
      }

      {
        ageingTableData?.ageing_table_borrowing_3 && (
          <>
            <br />
            {
              ageingTableData?.ageing_table_borrowing_3?.headerName && <CkEditor value={ageingTableData?.ageing_table_borrowing_3?.headerName} onChange={(data) => { HandleChange(data, 'ageing_table_borrowing_3', 'headerName') }} />
            }
          </>
        )
      }
      {
        ageingTableData?.ageing_table_borrowing_2 && (
          <>
            <br />
            <div>
              {
                ageingTableData?.ageing_table_borrowing_2?.headerName && <CkEditor value={ageingTableData?.ageing_table_borrowing_2?.headerName} onChange={(data) => { HandleChange(data, 'ageing_table_borrowing_2', 'headerName') }} />
              }
              <table>
                <thead >
                  <tr style={{ backgroundColor: "#A2C0C2" }}>
                    <th className="pl-3 w-75 text-left" >Security Clause</th>
                    <th className="w-auto">{moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
                    <th className="w-auto">{moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    ageingTableData?.ageing_table_borrowing_2?.data && ageingTableData?.ageing_table_borrowing_2?.data.length > 0 &&
                    ageingTableData?.ageing_table_borrowing_2?.data?.map((ele, ix) => (
                      <>
                        <tr>
                          <td>
                            <textarea
                              className="p-0 mt-1 w-100 border-0 pl-3 text-left bg-color-inherit"
                              type={"text"}
                              value={ele?.header}
                              name="header"
                              onChange={(e) => HandleChange(e, "ageing_table_borrowing_2", ix)}
                            />
                          </td>


                          <td>
                            <input
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                              type={"text"}
                              value={formatIndianCurrency(ele?.cy)}
                              name="cy"
                              onChange={(e) => HandleChange(e, 'ageing_table_borrowing_2', ix)}
                            />

                          </td>
                          <td>
                            <input
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                              type={"text"}
                              value={formatIndianCurrency(ele?.py)}
                              name="py"
                              onChange={(e) => HandleChange(e, 'ageing_table_borrowing_2', ix)}
                            />
                          </td>

                        </tr>
                        {
                          [0, ageingTableData?.ageing_table_borrowing_2?.data.length - 1].includes(ix) && (
                            <>
                              <tr className="font-weight-bold">
                                <td className="text-left pl-3 font-weight-bold text-secondary">
                                  {
                                    ix === 0 ? "Subtotal" :
                                      ix === ageingTableData?.ageing_table_borrowing_2?.data.length - 1 ? "Total" : ""
                                  }
                                </td>
                                <td className="text-right pr-3 font-weight-bold text-secondary">
                                  {
                                    ix === 0 ? formatIndianCurrency(calculateTotal("ageing_table_borrowing_2", "cy", true, { ix_0: 0, ix_1: 1 })) :
                                      ix === ageingTableData?.ageing_table_borrowing_2?.data.length - 1 ?
                                      formatIndianCurrency(calculateTotal("ageing_table_borrowing_2", "cy")) : ""
                                  }
                                </td>
                                <td className="text-right pr-3 font-weight-bold text-secondary">
                                  {
                                    ix === 0 ? formatIndianCurrency(calculateTotal("ageing_table_borrowing_2", "py", true, { ix_0: 0, ix_1: 1 })) :
                                      ix === ageingTableData?.ageing_table_borrowing_2?.data.length - 1 ?
                                      formatIndianCurrency(calculateTotal("ageing_table_borrowing_2", "py")) : ""
                                  }
                                </td>
                              </tr>
                            </>
                          )
                        }
                      </>
                    ))
                  }

                </tbody>
              </table>
            </div>
          </>
        )
      }

      {/* (2) Deferred tax (credit) */}
      {
        ageingTableData?.pattern_1 && (
          <>
            <br />
            <div>
              {
                ageingTableData?.pattern_1?.headerName && <CkEditor value={ageingTableData?.pattern_1?.headerName} onChange={(data) => { HandleChange(data, 'pattern_1', 'headerName') }} />
              }
              <table>
                <thead >
                  <tr style={{ backgroundColor: "#A2C0C2" }}>
                    <th style={{
                      width: "80%",
                      height: "70px",
                      textAlign: "left",
                      paddingLeft: "2rem",
                    }}>Particulars</th>
                    <th style={{ width: "10%" }}>For the year ended {moment(reduxProjectData.current_year).format("DD MMMM YYYY")} </th>
                    <th style={{ width: "10%" }}>For the year ended {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")} </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    ageingTableData?.pattern_1?.data && ageingTableData?.pattern_1?.data.length > 0 &&
                    ageingTableData?.pattern_1?.data?.map((ele, ix) => (
                      <tr style={{
                        padding: "10px 0 0 2rem",
                        alignContent: "center",
                        fontSize: "1.1rem",
                        fontWeight: "600",
                      }}>
                        <td
                          style={{
                            width: "40%",
                            textAlign: "left",
                            paddingLeft: "2rem",
                          }}
                        >
                          <input
                            className="table-row-header-input"
                            type={"text"}
                            value={ele?.header}
                            name="header"
                            onChange={(e) => HandleChange(e, "pattern_1", ix)}
                          />
                        </td>

                        <td
                          className="table-row"
                        >
                          <input
                            className="table-row-input"
                            type={"text"}
                            value={formatIndianCurrency(ele?.cy)}
                            name="cy"
                            onChange={(e) => HandleChange(e, 'pattern_1', ix)}
                          />

                        </td>
                        <td
                          className="table-row"
                        >
                          <input
                            className="table-row-input"
                            type={"text"}
                            value={formatIndianCurrency(ele?.py)}
                            name="py"
                            onChange={(e) => HandleChange(e, 'pattern_1', ix)}
                          />
                        </td>

                      </tr>

                    ))
                  }
                  <tr style={{ backgroundColor: "#f0dbc8" }}>
                    <td style={{
                      width: "70%",
                      textAlign: "left",
                      paddingLeft: "2rem",
                      fontWeight: "bold",
                      color: "black"
                    }}>Income tax expense</td>
                    <td className="table-row-sub-total" > {formatIndianCurrency(calculateTotal("pattern_1", "cy"))} </td>
                    <td className="table-row-sub-total"> {formatIndianCurrency(calculateTotal("pattern_1", "py"))} </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )
      }
      {
        ageingTableData?.pattern_2 && (
          <>
            <br />
            <div>
              {
                ageingTableData?.pattern_2?.headerName && <CkEditor value={ageingTableData?.pattern_2?.headerName} onChange={(data) => { HandleChange(data, 'pattern_2', 'headerName') }} />
              }
              <table>
                <thead >
                  <tr style={{ backgroundColor: "#A2C0C2" }}>
                    <th className="pl-3 w-50 text-left">For the year ended {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
                    <th className="w-auto"> Deferred tax asset/liability Opening</th>
                    <th className="w-auto"> In Profit or Loss </th>
                    <th className="w-auto">In OCI</th>
                    <th className="w-auto">Directly in Equity</th>
                    <th className="w-auto"> Total Movement</th>
                    <th className="w-auto"> Deferred tax asset/ liability Closing</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    ageingTableData?.pattern_2?.data && ageingTableData?.pattern_2?.data.length > 0 &&
                    ageingTableData?.pattern_2?.data?.map((ele, ix) => (
                      <>
                        <tr >
                          <td>
                            <input
                              className={`p-0 mt-1 w-100 border-0 pl-3 text-left bg-color-inherit ${ele?.isHeading && "font-weight-bold"}`}
                              type={"text"}
                              value={ele?.header}
                              name="header"
                              onChange={(e) => HandleChange(e, "pattern_2", ix)}
                            />
                          </td>
                          <td>
                            <input
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                              type={"text"}
                              value={formatIndianCurrency(ele?.deferred_tax_opening)}
                              readOnly={typeof ele?.deferred_tax_opening !== "number"}
                              name="deferred_tax_opening"
                              onChange={(e) => HandleChange(e, 'pattern_2', ix)}
                            />
                          </td>
                          <td>
                            <input
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                              type={"text"}
                              value={formatIndianCurrency(ele?.in_profit_loss)}
                              readOnly={typeof ele?.in_profit_loss !== "number"}
                              name="in_profit_loss"
                              onChange={(e) => HandleChange(e, 'pattern_2', ix)}
                            />
                          </td>
                          <td>
                            <input
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                              type={"text"}
                              value={formatIndianCurrency(ele?.in_oci)}
                              readOnly={typeof ele?.in_oci !== "number"}
                              name="in_oci"
                              onChange={(e) => HandleChange(e, 'pattern_2', ix)}
                            />
                          </td>
                          <td>
                            <input
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                              type={"text"}
                              value={formatIndianCurrency(ele?.directly_in_equity)}
                              readOnly={typeof ele?.directly_in_equity !== "number"}
                              name="directly_in_equity"
                              onChange={(e) => HandleChange(e, 'pattern_2', ix)}
                            />
                          </td>
                          <td>
                            <input
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                              type={"text"}
                              value={formatIndianCurrency(ele?.total_movement)}
                              readOnly={typeof ele?.total_movement !== "number"}
                              name="total_movement"
                              onChange={(e) => HandleChange(e, 'pattern_2', ix)}
                            />
                          </td>
                          <td>
                            <input
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                              type={"text"}
                              value={formatIndianCurrency(ele?.deferred_tax_closing)}
                              readOnly={typeof ele?.deferred_tax_closing !== "number"}
                              name="deferred_tax_closing"
                              onChange={(e) => HandleChange(e, 'pattern_2', ix)}
                            />
                          </td>

                        </tr>
                        {
                          [11, ageingTableData?.pattern_2?.data.length - 1].includes(ix) && (

                            <tr className="font-weight-bold">
                              <td className="text-left pl-3 font-weight-bold text-secondary">
                                {
                                  ix === 11 ? "Total Deferred Tax Assets" :
                                    ix === ageingTableData?.pattern_2?.data.length - 1 ? "Total Deferred Tax Liabilities" : ""
                                }
                              </td>
                              <td className="text-right pr-3 font-weight-bold text-secondary">
                                {
                                  ix === 11 ? formatIndianCurrency(calculateTotal("pattern_2", "deferred_tax_opening", true, { ix_0: 2, ix_1: 12 })) :
                                    ix === ageingTableData?.pattern_2?.data.length - 1 ? formatIndianCurrency(calculateTotal("pattern_2", "deferred_tax_opening", true, { ix_0: 12, ix_1: 17 })) : ""
                                }
                              </td>
                              <td className="text-right pr-3 font-weight-bold text-secondary">
                                {
                                  ix === 11 ? formatIndianCurrency(calculateTotal("pattern_2", "in_profit_loss", true, { ix_0: 2, ix_1: 12 })) :
                                    ix === ageingTableData?.pattern_2?.data.length - 1 ? formatIndianCurrency(calculateTotal("pattern_2", "in_profit_loss", true, { ix_0: 12, ix_1: 17 })) : ""
                                }
                              </td>
                              <td className="text-right pr-3 font-weight-bold text-secondary">
                                {
                                  ix === 11 ? formatIndianCurrency(calculateTotal("pattern_2", "in_oci", true, { ix_0: 2, ix_1: 12 })) :
                                    ix === ageingTableData?.pattern_2?.data.length - 1 ? formatIndianCurrency(calculateTotal("pattern_2", "in_oci", true, { ix_0: 12, ix_1: 17 })) : ""
                                }
                              </td>
                              <td className="text-right pr-3 font-weight-bold text-secondary">
                                {
                                  ix === 11 ? formatIndianCurrency(calculateTotal("pattern_2", "directly_in_equity", true, { ix_0: 2, ix_1: 12 })) :
                                    ix === ageingTableData?.pattern_2?.data.length - 1 ? formatIndianCurrency(calculateTotal("pattern_2", "directly_in_equity", true, { ix_0: 12, ix_1: 17 })) : ""
                                }
                              </td>
                              <td className="text-right pr-3 font-weight-bold text-secondary">
                                {
                                  ix === 11 ? formatIndianCurrency(calculateTotal("pattern_2", "total_movement", true, { ix_0: 2, ix_1: 12 })) :
                                    ix === ageingTableData?.pattern_2?.data.length - 1 ? formatIndianCurrency(calculateTotal("pattern_2", "total_movement", true, { ix_0: 12, ix_1: 17 })) : ""
                                }
                              </td>
                              <td className="text-right pr-3 font-weight-bold text-secondary">
                                {
                                  ix === 11 ? formatIndianCurrency(calculateTotal("pattern_2", "deferred_tax_closing", true, { ix_0: 2, ix_1: 12 })) :
                                    ix === ageingTableData?.pattern_2?.data.length - 1 ? formatIndianCurrency(calculateTotal("pattern_2", "deferred_tax_closing", true, { ix_0: 12, ix_1: 17 })) : ""
                                }
                              </td>
                            </tr>
                          )}
                      </>
                    ))
                  }
                  <tr className="font-weight-bold">
                    <td className="text-left pl-3 font-weight-bold text-secondary">Total</td>
                    <td className="text-right pr-3 font-weight-bold text-secondary">{formatIndianCurrency(totalsofDeferred("pattern_2", "deferred_tax_opening"))}</td>
                    <td className="text-right pr-3 font-weight-bold text-secondary">{formatIndianCurrency(totalsofDeferred("pattern_2", "in_profit_loss"))}</td>
                    <td className="text-right pr-3 font-weight-bold text-secondary">{formatIndianCurrency(totalsofDeferred("pattern_2", "in_oci"))}</td>
                    <td className="text-right pr-3 font-weight-bold text-secondary">{formatIndianCurrency(totalsofDeferred("pattern_2", "directly_in_equity"))}</td>
                    <td className="text-right pr-3 font-weight-bold text-secondary">{formatIndianCurrency(totalsofDeferred("pattern_2", "total_movement"))}</td>
                    <td className="text-right pr-3 font-weight-bold text-secondary">{formatIndianCurrency(totalsofDeferred("pattern_2", "deferred_tax_closing"))}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )
      }
      {
        ageingTableData?.pattern_3 && (
          <>
            <br />
            <div>
              {
                ageingTableData?.pattern_3?.headerName && <CkEditor value={ageingTableData?.pattern_3?.headerName} onChange={(data) => { HandleChange(data, 'pattern_3', 'headerName') }} />
              }
              <table>
                <thead >
                  <tr style={{ backgroundColor: "#A2C0C2" }}>
                    <th className="pl-3 w-50 text-left">For the year ended {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
                    <th className="w-auto"> Deferred tax asset/liability Opening</th>
                    <th className="w-auto"> In Profit or Loss </th>
                    <th className="w-auto">In OCI</th>
                    <th className="w-auto">Directly in Equity</th>
                    <th className="w-auto"> Total Movement</th>
                    <th className="w-auto"> Deferred tax asset/ liability Closing</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    ageingTableData?.pattern_3?.data && ageingTableData?.pattern_3?.data.length > 0 &&
                    ageingTableData?.pattern_3?.data?.map((ele, ix) => (
                      <>
                        <tr >
                          <td>
                            <input
                              className={`p-0 mt-1 w-100 border-0 pl-3 text-left bg-color-inherit ${ele?.isHeading && "font-weight-bold"}`}
                              type={"text"}
                              value={ele?.header}
                              name="header"
                              onChange={(e) => HandleChange(e, "pattern_3", ix)}
                            />
                          </td>
                          <td>
                            <input
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                              type={"text"}
                              value={formatIndianCurrency(ele?.deferred_tax_opening)}
                              readOnly={typeof ele?.deferred_tax_opening !== "number"}
                              name="deferred_tax_opening"
                              onChange={(e) => HandleChange(e, 'pattern_3', ix)}
                            />
                          </td>
                          <td>
                            <input
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                              type={"text"}
                              value={formatIndianCurrency(ele?.in_profit_loss)}
                              readOnly={typeof ele?.in_profit_loss !== "number"}
                              name="in_profit_loss"
                              onChange={(e) => HandleChange(e, 'pattern_3', ix)}
                            />
                          </td>
                          <td>
                            <input
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                              type={"text"}
                              value={formatIndianCurrency(ele?.in_oci)}
                              readOnly={typeof ele?.in_oci !== "number"}
                              name="in_oci"
                              onChange={(e) => HandleChange(e, 'pattern_3', ix)}
                            />
                          </td>
                          <td>
                            <input
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                              type={"text"}
                              value={formatIndianCurrency(ele?.directly_in_equity)}
                              readOnly={typeof ele?.directly_in_equity !== "number"}
                              name="directly_in_equity"
                              onChange={(e) => HandleChange(e, 'pattern_3', ix)}
                            />
                          </td>
                          <td>
                            <input
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                              type={"text"}
                              value={formatIndianCurrency(ele?.total_movement)}
                              readOnly={typeof ele?.total_movement !== "number"}
                              name="total_movement"
                              onChange={(e) => HandleChange(e, 'pattern_3', ix)}
                            />
                          </td>
                          <td>
                            <input
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                              type={"text"}
                              value={formatIndianCurrency(ele?.deferred_tax_closing)}
                              readOnly={typeof ele?.deferred_tax_closing !== "number"}
                              name="deferred_tax_closing"
                              onChange={(e) => HandleChange(e, 'pattern_3', ix)}
                            />
                          </td>

                        </tr>
                        {
                          [8, ageingTableData?.pattern_3?.data.length - 1].includes(ix) && (

                            <tr className="font-weight-bold">
                              <td className="text-left pl-3 font-weight-bold text-secondary">
                                {
                                  ix === 8 ? "Total Deferred Tax Assets" :
                                    ix === ageingTableData?.pattern_3?.data.length - 1 ? "Total Deferred Tax Liabilities" : ""
                                }
                              </td>
                              <td className="text-right pr-3 font-weight-bold text-secondary">
                                {
                                  ix === 8 ? formatIndianCurrency(calculateTotal("pattern_3", "deferred_tax_opening", true, { ix_0: 1, ix_1: 9 })) :
                                    ix === ageingTableData?.pattern_3?.data.length - 1 ? formatIndianCurrency(calculateTotal("pattern_3", "deferred_tax_opening", true, { ix_0: 9, ix_1: 15 })) : ""
                                }
                              </td>
                              <td className="text-right pr-3 font-weight-bold text-secondary">
                                {
                                  ix === 8 ? formatIndianCurrency(calculateTotal("pattern_3", "in_profit_loss", true, { ix_0: 1, ix_1: 9 })) :
                                    ix === ageingTableData?.pattern_3?.data.length - 1 ? formatIndianCurrency(calculateTotal("pattern_3", "in_profit_loss", true, { ix_0: 9, ix_1: 15 })) : ""
                                }
                              </td>
                              <td className="text-right pr-3 font-weight-bold text-secondary">
                                {
                                  ix === 8 ? formatIndianCurrency(calculateTotal("pattern_3", "in_oci", true, { ix_0: 1, ix_1: 9 })) :
                                    ix === ageingTableData?.pattern_3?.data.length - 1 ? formatIndianCurrency(calculateTotal("pattern_3", "in_oci", true, { ix_0: 9, ix_1: 15 })) : ""
                                }
                              </td>
                              <td className="text-right pr-3 font-weight-bold text-secondary">
                                {
                                  ix === 8 ? formatIndianCurrency(calculateTotal("pattern_3", "directly_in_equity", true, { ix_0: 1, ix_1: 9 })) :
                                    ix === ageingTableData?.pattern_3?.data.length - 1 ? formatIndianCurrency(calculateTotal("pattern_3", "directly_in_equity", true, { ix_0: 9, ix_1: 15 })) : ""
                                }
                              </td>
                              <td className="text-right pr-3 font-weight-bold text-secondary">
                                {
                                  ix === 8 ? formatIndianCurrency(calculateTotal("pattern_3", "total_movement", true, { ix_0: 1, ix_1: 9 })) :
                                    ix === ageingTableData?.pattern_3?.data.length - 1 ? formatIndianCurrency(calculateTotal("pattern_3", "total_movement", true, { ix_0: 9, ix_1: 15 })) : ""
                                }
                              </td>
                              <td className="text-right pr-3 font-weight-bold text-secondary">
                                {
                                  ix === 8 ? formatIndianCurrency(calculateTotal("pattern_3", "deferred_tax_closing", true, { ix_0: 1, ix_1: 9 })) :
                                    ix === ageingTableData?.pattern_3?.data.length - 1 ? formatIndianCurrency(calculateTotal("pattern_3", "deferred_tax_closing", true, { ix_0: 9, ix_1: 15 })) : ""
                                }
                              </td>
                            </tr>
                          )}
                      </>
                    ))
                  }
                  <tr className="font-weight-bold">
                    <td className="text-left pl-3 font-weight-bold text-secondary">Total</td>
                    <td className="text-right pr-3 font-weight-bold text-secondary">{formatIndianCurrency(totalsofDeferred("pattern_3", "deferred_tax_opening"))}</td>
                    <td className="text-right pr-3 font-weight-bold text-secondary">{formatIndianCurrency(totalsofDeferred("pattern_3", "in_profit_loss"))}</td>
                    <td className="text-right pr-3 font-weight-bold text-secondary">{formatIndianCurrency(totalsofDeferred("pattern_3", "in_oci"))}</td>
                    <td className="text-right pr-3 font-weight-bold text-secondary">{formatIndianCurrency(totalsofDeferred("pattern_3", "directly_in_equity"))}</td>
                    <td className="text-right pr-3 font-weight-bold text-secondary">{formatIndianCurrency(totalsofDeferred("pattern_3", "total_movement"))}</td>
                    <td className="text-right pr-3 font-weight-bold text-secondary">{formatIndianCurrency(totalsofDeferred("pattern_3", "deferred_tax_closing"))}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )
      }
      {/* Others expenses */}
      {
        ageingTableData?.patten_1 && (
          <>
            <br />
            <div>
              {
                ageingTableData?.patten_1?.headerName && <CkEditor value={ageingTableData?.patten_1?.headerName} onChange={(data) => { HandleChange(data, 'patten_1', 'headerName') }} />
              }
              <table>
                <thead >
                  <tr style={{ backgroundColor: "#A2C0C2" }}>
                    <th className="pl-3 w-75 text-left">Particulars</th>
                    <th className="w-auto">For the year ended {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
                    <th className="w-auto">For the year ended {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    ageingTableData?.patten_1?.data && ageingTableData?.patten_1?.data.length > 0 &&
                    ageingTableData?.patten_1?.data?.map((ele, ix) => (
                      <>
                        <tr>
                          <td>
                            <textarea
                              className={`p-0 mt-1 w-100 border-0 pl-3 text-left bg-color-inherit ${ele?.isHeading && "font-weight-bold"}`}
                              type={"text"}
                              value={ele?.header}
                              name="header"
                              onChange={(e) => HandleChange(e, "patten_1", ix)}
                            />
                          </td>
                          <td>
                            <input
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                              type={"text"}
                              value={formatIndianCurrency(ele?.cy)}
                              name="cy"
                              onChange={(e) => HandleChange(e, 'patten_1', ix)}
                            />
                          </td>
                          <td>
                            <input
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                              type={"text"}
                              value={formatIndianCurrency(ele?.py)}
                              name="py"
                              onChange={(e) => HandleChange(e, 'patten_1', ix)}
                            />
                          </td>
                        </tr>
                        {
                          ix === 3 && (
                            <tr>
                              <td> </td>
                              <td className="text-right pr-3 font-weight-bold text-secondary">
                                {formatIndianCurrency(calculateTotal("patten_1", "cy", true, { ix_0: 0, ix_1: 4 }))}
                              </td>
                              <td className="text-right pr-3 font-weight-bold text-secondary">
                                {formatIndianCurrency(calculateTotal("patten_1", "py", true, { ix_0: 0, ix_1: 4 }))}
                              </td>
                            </tr>
                          )
                        }
                      </>
                    ))
                  }
                  <tr>
                    <td className="text-left pl-3 font-weight-bold text-secondary">Total</td>
                    <td className="text-right pr-3 font-weight-bold text-secondary" >
                      {formatIndianCurrency(calculateTotal("patten_1", "cy"))}
                      {
                        checkCondition("patten_1", "cy") &&
                        <CommonToolTip
                          variant="primary"
                          title={"The value is not balancing."}
                          placement="top"
                        >
                          <ErrorIcon
                            style={{
                              color: "darkorange",
                              cursor: "pointer",
                            }}
                            className="financialInstrument-hoverIcon"
                          />
                        </CommonToolTip>
                      }
                    </td>
                    <td className="text-right pr-3 font-weight-bold text-secondary" >
                      {formatIndianCurrency(calculateTotal("patten_1", "py"))}
                      {
                        checkCondition("patten_1", "py") &&
                        <CommonToolTip
                          variant="primary"
                          title={"The value is not balancing."}
                          placement="top"
                        >
                          <ErrorIcon
                            style={{
                              color: "darkorange",
                              cursor: "pointer",
                            }}
                            className="financialInstrument-hoverIcon"
                          />
                        </CommonToolTip>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )
      }

      {
        ageingTableData?.patten_2 && (
          <>
            <br />
            <div>
              {
                ageingTableData?.patten_2?.headerName && <CkEditor value={ageingTableData?.patten_2?.headerName} onChange={(data) => { HandleChange(data, 'patten_2', 'headerName') }} />
              }
              <table>
                <thead >
                  <tr style={{ backgroundColor: "#A2C0C2" }}>
                    <th className="pl-3 w-75 text-left">Particulars</th>
                    <th className="w-auto">For the year ended {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
                    <th className="w-auto">For the year ended {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    ageingTableData?.patten_2?.data && ageingTableData?.patten_2?.data.length > 0 &&
                    ageingTableData?.patten_2?.data?.map((ele, ix) => (
                      <tr>
                        <td>
                          <textarea
                            className={`p-0 mt-1 w-100 border-0 pl-3 text-left bg-color-inherit ${ele?.isHeading && "font-weight-bold"}`}
                            type={"text"}
                            value={ele?.header}
                            name="header"
                            onChange={(e) => HandleChange(e, "patten_2", ix)}
                          />
                        </td>
                        <td>
                          <input
                            className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                            type={"text"}
                            value={formatIndianCurrency(ele?.cy)}
                            name="cy"
                            onChange={(e) => HandleChange(e, 'patten_2', ix)}
                          />
                        </td>
                        <td>
                          <input
                            className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                            type={"text"}
                            value={formatIndianCurrency(ele?.py)}
                            name="py"
                            onChange={(e) => HandleChange(e, 'patten_2', ix)}
                          />
                        </td>
                      </tr>
                    ))
                  }
                  <tr>
                    <td className="text-left pl-3 font-weight-bold text-secondary">Amount required to be contributed to specified fund u/s 135(6)</td>
                    <td className="text-right pr-3 font-weight-bold text-secondary">
                      {formatIndianCurrency(calculateTotal("patten_2", "cy"))}
                    </td>
                    <td className="text-right pr-3 font-weight-bold text-secondary">
                      {formatIndianCurrency(calculateTotal("patten_2", "py"))}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )
      }


      {/*Equity share capital */}
      {
        ageingTableData?.equity_share_capital_table && (
          <>
            <br />
            <div>
              {
                ageingTableData?.equity_share_capital_table?.headerName && <CkEditor value={ageingTableData?.equity_share_capital_table?.headerName} onChange={(data) => { HandleChange(data, 'equity_share_capital_table', 'headerName') }} />
              }
              <table>
                <thead >
                  <tr>
                    <th
                      rowSpan={2}
                      className="pl-3 w-50 text-left">
                      Particulars
                    </th>
                    <th className="w-auto" colSpan={2}>
                      As at {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}
                    </th>
                    <th className="w-auto" colSpan={2}>
                      As at {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}
                    </th>
                  </tr>
                  <tr>
                    <th className="w-auto">Number of Shares</th>
                    <th className="w-auto">Rupees in Lakhs</th>
                    <th className="w-auto">Number of Shares</th>
                    <th className="w-auto">Rupees in Lakhs</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    ageingTableData?.equity_share_capital_table?.data && ageingTableData?.equity_share_capital_table?.data.length > 0 &&
                    ageingTableData?.equity_share_capital_table?.data?.map((ele, ix) => (
                      <tr>
                        <td>
                          <textarea
                            className={`p-0 mt-1 w-100 border-0 pl-3 text-left bg-color-inherit ${ele?.isHeading && "font-weight-bold"}`}
                            type={"text"}
                            value={ele?.header}
                            name="header"
                            onChange={(e) => HandleChange(e, "equity_share_capital_table", ix)}
                          />
                        </td>

                        <td>
                          <input
                            className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                            type={"text"}
                            value={formatIndianCurrency(ele?.cy_share)}
                            name="cy_share"
                            onChange={(e) => HandleChange(e, 'equity_share_capital_table', ix)}
                          />

                        </td>
                        <td>
                          <input
                            className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                            type={"text"}
                            value={formatIndianCurrency(ele?.cy_rupees)}
                            name="cy_rupees"
                            onChange={(e) => HandleChange(e, 'equity_share_capital_table', ix)}
                          />
                        </td>
                        <td>
                          <input
                            className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                            type={"text"}
                            value={formatIndianCurrency(ele?.py_share)}
                            name="py_share"
                            onChange={(e) => HandleChange(e, 'equity_share_capital_table', ix)}
                          />
                        </td>
                        <td>
                          <input
                            className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                            type={"text"}
                            value={formatIndianCurrency(ele?.py_rupees)}
                            name="py_rupees"
                            onChange={(e) => HandleChange(e, 'equity_share_capital_table', ix)}
                          />
                        </td>
                      </tr>
                    ))
                  }
                  <tr>
                    <td className="text-left pl-3 font-weight-bold text-secondary">Balance at the end of the year</td>
                    <td className="text-right pr-2rem font-weight-bold text-secondary">{formatIndianCurrency(calculateTotal("equity_share_capital_table", "cy_share"))}</td>
                    <td className="text-right pr-2rem font-weight-bold text-secondary">
                      {formatIndianCurrency(calculateTotal("equity_share_capital_table", "cy_rupees"))}
                      {
                        checkCondition("equity_share_capital_table", "cy_rupees") &&
                        <CommonToolTip
                          variant="primary"
                          title={"The value is not balancing."}
                          placement="top"
                        >
                          <ErrorIcon
                            style={{
                              color: "darkorange",
                              cursor: "pointer",
                            }}
                            className="financialInstrument-hoverIcon"
                          />
                        </CommonToolTip>
                      }
                    </td>
                    <td className="text-right pr-2rem font-weight-bold text-secondary"> {formatIndianCurrency(calculateTotal("equity_share_capital_table", "py_share"))} </td>
                    <td className="text-right pr-2rem font-weight-bold text-secondary">
                      {formatIndianCurrency(calculateTotal("equity_share_capital_table", "py_rupees"))}
                      {
                        checkCondition("equity_share_capital_table", "py_rupees") &&
                        <CommonToolTip
                          variant="primary"
                          title={"The value is not balancing."}
                          placement="top"
                        >
                          <ErrorIcon
                            style={{
                              color: "darkorange",
                              cursor: "pointer",
                            }}
                            className="financialInstrument-hoverIcon"
                          />
                        </CommonToolTip>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )
      }
      {
        ageingTableData?.equity_share_capital_table_1 && (
          <>
            <br />
            <div>
              {
                ageingTableData?.equity_share_capital_table_1?.headerName && <CkEditor value={ageingTableData?.equity_share_capital_table_1?.headerName} onChange={(data) => { HandleChange(data, 'equity_share_capital_table_1', 'headerName') }} />
              }
              <table>
                <thead >
                  <tr>
                    <th
                      rowSpan={2} className="pl-3 w-75 text-left">
                      Particulars
                    </th>
                    <th className="w-auto" colSpan={2}>
                      As at {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}
                    </th>
                    <th className="w-auto" colSpan={2}>
                      As at {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}
                    </th>
                  </tr>
                  <tr>
                    <th className="w-auto">Number of Shares</th>
                    <th className="w-auto">Rupees in Lakhs</th>
                    <th className="w-auto">Number of Shares</th>
                    <th className="w-auto">Rupees in Lakhs</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    ageingTableData?.equity_share_capital_table_1?.data && ageingTableData?.equity_share_capital_table_1?.data.length > 0 &&
                    ageingTableData?.equity_share_capital_table_1?.data?.map((ele, ix) => (
                      <tr>
                        <td>
                          <textarea
                            className={`p-0 mt-1 w-100 border-0 pl-3 text-left bg-color-inherit ${ele?.isHeading && "font-weight-bold"}`}
                            type={"text"}
                            value={ele?.header}
                            name="header"
                            onChange={(e) => HandleChange(e, "equity_share_capital_table_1", ix)}
                          />
                        </td>

                        <td>
                          <input
                            className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                            type={"text"}
                            value={formatIndianCurrency(ele?.cy_share)}
                            name="cy_share"
                            onChange={(e) => HandleChange(e, 'equity_share_capital_table_1', ix)}
                          />

                        </td>
                        <td>
                          <input
                            className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                            type={"text"}
                            value={formatIndianCurrency(ele?.cy_rupees)}
                            name="cy_rupees"
                            onChange={(e) => HandleChange(e, 'equity_share_capital_table_1', ix)}
                          />
                        </td>
                        <td>
                          <input
                            className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                            type={"text"}
                            value={formatIndianCurrency(ele?.py_share)}
                            name="py_share"
                            onChange={(e) => HandleChange(e, 'equity_share_capital_table_1', ix)}
                          />
                        </td>
                        <td>
                          <input
                            className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                            type={"text"}
                            value={formatIndianCurrency(ele?.py_rupees)}
                            name="py_rupees"
                            onChange={(e) => HandleChange(e, 'equity_share_capital_table_1', ix)}
                          />
                        </td>

                      </tr>

                    ))
                  }
                  <tr>
                    <td className="text-left pl-3 font-weight-bold text-secondary">Balance at the end of the year</td>
                    <td className="text-right pr-2rem font-weight-bold text-secondary"> {formatIndianCurrency(calculateTotal("equity_share_capital_table_1", "cy_share"))} </td>
                    <td className="text-right pr-2rem font-weight-bold text-secondary"> {formatIndianCurrency(calculateTotal("equity_share_capital_table_1", "cy_rupees"))} </td>
                    <td className="text-right pr-2rem font-weight-bold text-secondary"> {formatIndianCurrency(calculateTotal("equity_share_capital_table_1", "py_share"))} </td>
                    <td className="text-right pr-2rem font-weight-bold text-secondary"> {formatIndianCurrency(calculateTotal("equity_share_capital_table_1", "py_rupees"))} </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )
      }
      {
        ageingTableData?.equity_share_capital_table_2 && (
          <>
            <br />
            <div>
              {
                ageingTableData?.equity_share_capital_table_2?.headerName && <CkEditor value={ageingTableData?.equity_share_capital_table_2?.headerName} onChange={(data) => { HandleChange(data, 'equity_share_capital_table_1', 'headerName') }} />
              }
              <table>
                <thead >
                  <tr>
                    <th
                      rowSpan={2} className="pl-3 w-75 text-left">
                      Particulars
                    </th>
                    <th className="w-auto" colSpan={2}>
                      As at {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}
                    </th>
                    <th className="w-auto" colSpan={2}>
                      As at {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}
                    </th>
                  </tr>
                  <tr>
                    <th className="w-auto">Number of Shares</th>
                    <th className="w-auto">Rupees in Lakhs</th>
                    <th className="w-auto">Number of Shares</th>
                    <th className="w-auto">Rupees in Lakhs</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    ageingTableData?.equity_share_capital_table_2?.data && ageingTableData?.equity_share_capital_table_2?.data.length > 0 &&
                    ageingTableData?.equity_share_capital_table_2?.data?.map((ele, ix) => (
                      <tr>
                        <td>
                          <textarea
                            className={`p-0 mt-1 w-100 border-0 pl-3 text-left bg-color-inherit ${ele?.isHeading && "font-weight-bold"}`}
                            type={"text"}
                            value={ele?.header}
                            name="header"
                            onChange={(e) => HandleChange(e, "equity_share_capital_table_2", ix)}
                          />
                        </td>

                        <td>
                          <input
                            className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                            type={"text"}
                            value={formatIndianCurrency(ele?.cy_share)}
                            name="cy_share"
                            onChange={(e) => HandleChange(e, 'equity_share_capital_table_2', ix)}
                          />

                        </td>
                        <td>
                          <input
                            className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                            type={"text"}
                            value={formatIndianCurrency(ele?.cy_rupees)}
                            name="cy_rupees"
                            onChange={(e) => HandleChange(e, 'equity_share_capital_table_2', ix)}
                          />
                        </td>
                        <td>
                          <input
                            className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                            type={"text"}
                            value={formatIndianCurrency(ele?.py_share)}
                            name="py_share"
                            onChange={(e) => HandleChange(e, 'equity_share_capital_table_2', ix)}
                          />
                        </td>
                        <td>
                          <input
                            className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                            type={"text"}
                            value={formatIndianCurrency(ele?.py_rupees)}
                            name="py_rupees"
                            onChange={(e) => HandleChange(e, 'equity_share_capital_table_2', ix)}
                          />
                        </td>

                      </tr>

                    ))
                  }

                </tbody>
              </table>
            </div>
          </>
        )
      }
      {/* Subordinated liabilities */}

      {
        ageingTableData?.subordinated_liabilities && (
          <>
            <br />
            {
              ageingTableData?.subordinated_liabilities?.headerName && <CkEditor value={ageingTableData?.subordinated_liabilities?.headerName} onChange={(data) => { HandleChange(data, 'subordinated_liabilities', 'headerName') }} />
            }


          </>
        )
      }

      {
        ageingTableData?.subordinated_liabilities_1 && (
          <>
            <br />
            <div>
              {
                ageingTableData?.subordinated_liabilities_1?.headerName && <CkEditor value={ageingTableData?.subordinated_liabilities_1?.headerName} onChange={(data) => { HandleChange(data, 'subordinated_liabilities_1 ', 'headerName') }} />
              }
              <table>
                <thead>
                  <tr style={{ backgroundColor: "#A2C0C2" }}>
                    <th className="pl-3 w-50 text-left">Particulars</th>
                    <th className="w-auto">Interest Rate Range</th>
                    <th className="w-auto">{moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
                    <th className="w-auto">{moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    ageingTableData?.subordinated_liabilities_1?.data && ageingTableData?.subordinated_liabilities_1?.data.length > 0 &&
                    ageingTableData?.subordinated_liabilities_1?.data?.map((ele, ix) => (
                      <>
                        <tr>
                          <td>
                            <textarea
                              className={`p-0 mt-1 w-100 border-0 pl-3 text-left bg-color-inherit ${ele?.isHeading && "font-weight-bold"}`}
                              type={"text"}
                              value={ele?.header}
                              name="header"
                              onChange={(e) => HandleChange(e, "subordinated_liabilities_1", ix)}
                            />
                          </td>
                          <td>
                            <input
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                              type={"text"}
                              value={formatIndianCurrency(ele?.interest_rate_range)}
                              readOnly={typeof ele?.interest_rate_range !== "number"}
                              name="interest_rate_range"
                              onChange={(e) => HandleChange(e, 'subordinated_liabilities_1', ix)}
                            />
                          </td>
                          <td>
                            <input
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                              type={"text"}
                              value={formatIndianCurrency(ele?.cy)}
                              readOnly={typeof ele?.cy !== "number"}
                              name="cy"
                              onChange={(e) => HandleChange(e, 'subordinated_liabilities_1', ix)}
                            />
                          </td>
                          <td>
                            <input
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                              type={"text"}
                              value={formatIndianCurrency(ele?.py)}
                              readOnly={typeof ele?.py !== "number"}
                              name="py"
                              onChange={(e) => HandleChange(e, 'subordinated_liabilities_1', ix)}
                            />
                          </td>

                        </tr>
                        {
                          [5, ageingTableData?.subordinated_liabilities_1?.data.length - 1].includes(ix) && (
                            <>
                              <tr className="font-weight-bold">
                                <td className="text-left pl-3 font-weight-bold text-secondary">
                                  {
                                    ix === 5 ? "Total Payable on Maturity" :
                                      ix === ageingTableData?.subordinated_liabilities_1?.data.length - 1 ? "Total Carrying amount" : ""
                                  }
                                </td>
                                <td></td>
                                <td className="text-right pr-3 font-weight-bold text-secondary">
                                  {
                                    ix === 5 ? formatIndianCurrency(calculateTotal("subordinated_liabilities_1", "cy", true, { ix_0: 2, ix_1: 6 })) : ""

                                  }
                                  {
                                    ix === ageingTableData?.subordinated_liabilities_1?.data.length - 1 &&
                                    <>
                                      {formatIndianCurrency(calculateTotal("subordinated_liabilities_1", "cy"))}
                                      {
                                        checkCondition("subordinated_liabilities_1", "cy") &&
                                        <CommonToolTip
                                          variant="primary"
                                          title={"The value is not balancing."}
                                          placement="top"
                                        >
                                          <ErrorIcon
                                            style={{
                                              color: "darkorange",
                                              cursor: "pointer",
                                            }}
                                            className="financialInstrument-hoverIcon"
                                          />
                                        </CommonToolTip>
                                      }
                                    </>
                                  }
                                </td>
                                <td className="text-right pr-3 font-weight-bold text-secondary">
                                  {
                                    ix === 5 ? formatIndianCurrency(calculateTotal("subordinated_liabilities_1", "py", true, { ix_0: 2, ix_1: 6 })) : ""
                                  }
                                  {
                                    ix === ageingTableData?.subordinated_liabilities_1?.data.length - 1 &&
                                    <>
                                      {formatIndianCurrency(calculateTotal("subordinated_liabilities_1", "py"))}
                                      {
                                        checkCondition("subordinated_liabilities_1", "py") &&
                                        <CommonToolTip
                                          variant="primary"
                                          title={"The value is not balancing."}
                                          placement="top"
                                        >
                                          <ErrorIcon
                                            style={{
                                              color: "darkorange",
                                              cursor: "pointer",
                                            }}
                                            className="financialInstrument-hoverIcon"
                                          />
                                        </CommonToolTip>
                                      }
                                    </>
                                  }
                                </td>
                              </tr>
                            </>
                          )
                        }
                      </>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </>
        )
      }

      {/* Right Of Use Assets */}
      {
        ageingTableData?.ageing_table_right_use && (
          <>
            <br />
            <div>
              {
                ageingTableData?.ageing_table_right_use?.headerName && <CkEditor value={ageingTableData?.ageing_table_right_use?.headerName} onChange={(data) => { HandleChange(data, "ageing_table_right_use", 'headerName') }} />
              }
              <table>
                <thead >
                  <tr style={{ backgroundColor: "#A2C0C2" }}>
                    <th className="pl-3 w-50 text-left">Particulars</th>
                    <th className="w-auto">ROU</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    ageingTableData?.ageing_table_right_use?.data && ageingTableData?.ageing_table_right_use?.data.length > 0 &&
                    ageingTableData?.ageing_table_right_use?.data?.map((ele, ix) => (
                      <tr >
                        <td>
                          <textarea
                            className={`p-0 mt-1 w-100 border-0 pl-3 text-left bg-color-inherit ${ele?.isHeading && "font-weight-bold"}`}
                            type={"text"}
                            value={ele?.header}
                            name="header"
                            onChange={(e) => HandleChange(e, "ageing_table_right_use", ix)}
                          />
                        </td>
                        <td>
                          <input
                            className='p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit'
                            type={"text"}
                            value={formatIndianCurrency(ele?.rou)}
                            name="rou"
                            onChange={(e) => HandleChange(e, 'ageing_table_right_use', ix)}
                          />
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </>
        )
      }
      {/* Capital work in progress */}
      {
        ageingTableData?.ageing_table_capital_work_progress && (
          <>
            <br />
            <div>
              {
                ageingTableData?.ageing_table_capital_work_progress?.headerName && <CkEditor value={ageingTableData?.ageing_table_capital_work_progress?.headerName} onChange={(data) => { HandleChange(data, "ageing_table_capital_work_progress", 'headerName') }} />
              }
              <table>
                <thead >
                  <tr style={{ backgroundColor: "#A2C0C2" }}>
                    <th className="pl-3 w-50 text-left">Particulars</th>
                    <th className="w-auto">CWIP</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    ageingTableData?.ageing_table_capital_work_progress?.data && ageingTableData?.ageing_table_capital_work_progress?.data.length > 0 &&
                    ageingTableData?.ageing_table_capital_work_progress?.data?.map((ele, ix) => (
                      <tr >
                        <td>
                          <textarea
                            className={`p-0 mt-1 w-100 border-0 pl-3 text-left bg-color-inherit ${ele?.isHeading && "font-weight-bold"}`}
                            type={"text"}
                            value={ele?.header}
                            name="header"
                            onChange={(e) => HandleChange(e, "ageing_table_capital_work_progress", ix)}
                          />
                        </td>
                        <td>
                          <input
                            className='p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit'
                            type={"text"}
                            value={formatIndianCurrency(ele?.cwip)}
                            name="cwip"
                            onChange={(e) => HandleChange(e, 'ageing_table_capital_work_progress', ix)}
                          />
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </>
        )
      }
      {
        ageingTableData?.ageing_table_ageing_capital_work_progress && (
          <>
            <br />
            <div>
              {
                ageingTableData?.ageing_table_ageing_capital_work_progress?.headerName && <CkEditor value={ageingTableData?.ageing_table_ageing_capital_work_progress?.headerName} onChange={(data) => { HandleChange(data, "ageing_table_ageing_capital_work_progress", 'headerName') }} />
              }
              <table>
                <thead >
                  <>
                    <tr style={{ backgroundColor: "#A2C0C2" }}>
                      <th className="pl-3 w-50 text-left" rowSpan={2}>CWIP</th>
                      <th className="w-auto" colSpan={4}>Amount in CWIP - March 31, 2023</th>
                      <th className="w-auto" rowSpan={2}>Total</th>
                    </tr>
                    <tr style={{ backgroundColor: "#A2C0C2" }}>
                      <th className="w-auto" rowSpan={2}>Less than 1year</th>
                      <th className="w-auto" rowSpan={2}>1-2 years</th>
                      <th className="w-auto" rowSpan={2}>2-3 years</th>
                      <th className="w-auto" rowSpan={2}>More than 3years</th>
                    </tr>
                  </>
                </thead>
                <tbody>
                  {
                    ageingTableData?.ageing_table_ageing_capital_work_progress?.data && ageingTableData?.ageing_table_ageing_capital_work_progress?.data.length > 0 &&
                    ageingTableData?.ageing_table_ageing_capital_work_progress?.data?.map((ele, ix) => (
                      <tr >
                        <td>
                          <textarea
                            className={`p-0 mt-1 w-100 border-0 pl-3 text-left bg-color-inherit ${ele?.isHeading && "font-weight-bold"}`}
                            type={"text"}
                            value={ele?.header}
                            name="header"
                            onChange={(e) => HandleChange(e, "ageing_table_ageing_capital_work_progress", ix)}
                          />
                        </td>
                        <td>
                          <input
                            className='p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit'
                            type={"text"}
                            value={formatIndianCurrency(ele?.Less_than_1yr)}
                            name="Less_than_1yr"
                            onChange={(e) => HandleChange(e, 'ageing_table_ageing_capital_work_progress', ix)}
                          />
                        </td>
                        <td>
                          <input
                            className='p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit'
                            type={"text"}
                            value={formatIndianCurrency(ele['1_to_2yr'])}
                            name="1_to_2yr"
                            onChange={(e) => HandleChange(e, 'ageing_table_ageing_capital_work_progress', ix)}
                          />
                        </td>
                        <td>
                          <input
                            className='p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit'
                            type={"text"}
                            value={formatIndianCurrency(ele['2_to_3yr'])}
                            name="2_to_3yr"
                            onChange={(e) => HandleChange(e, 'ageing_table_ageing_capital_work_progress', ix)}
                          />
                        </td>
                        <td>
                          <input
                            className='p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit'
                            type={"text"}
                            value={formatIndianCurrency(ele?.More_than_3_yr)}
                            name="More_than_3_yr"
                            onChange={(e) => HandleChange(e, 'ageing_table_ageing_capital_work_progress', ix)}
                          />
                        </td>
                        <td>{formatIndianCurrency(calculateTotal("ageing_table_ageing_capital_work_progress", ele))}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </>
        )
      }
      {
        ageingTableData?.ageing_table_ageing_capital_work_progress_cwip && (
          <>
            <br />
            <div>
              {
                ageingTableData?.ageing_table_ageing_capital_work_progress_cwip?.headerName && <CkEditor value={ageingTableData?.ageing_table_ageing_capital_work_progress_cwip?.headerName} onChange={(data) => { HandleChange(data, "ageing_table_ageing_capital_work_progress_cwip", 'headerName') }} />
              }
              <table>
                <thead >
                  <>
                    <tr style={{ backgroundColor: "#A2C0C2" }}>
                      <th className="pl-3 w-50 text-left" rowSpan={2}>CWIP</th>
                      <th className="w-auto" colSpan={4}>Amount in CWIP - March 31, 2022</th>
                      <th className="w-auto" rowSpan={2}>Total</th>
                    </tr>
                    <tr style={{ backgroundColor: "#A2C0C2" }}>
                      <th className="w-auto" rowSpan={2}>Less than 1year</th>
                      <th className="w-auto" rowSpan={2}>1-2 years</th>
                      <th className="w-auto" rowSpan={2}>2-3 years</th>
                      <th className="w-auto" rowSpan={2}>More than 3years</th>
                    </tr>
                  </>
                </thead>
                <tbody>
                  {
                    ageingTableData?.ageing_table_ageing_capital_work_progress_cwip?.data && ageingTableData?.ageing_table_ageing_capital_work_progress_cwip?.data.length > 0 &&
                    ageingTableData?.ageing_table_ageing_capital_work_progress_cwip?.data?.map((ele, ix) => (
                      <tr >
                        <td>
                          <textarea
                            className={`p-0 mt-1 w-100 border-0 pl-3 text-left bg-color-inherit ${ele?.isHeading && "font-weight-bold"}`}
                            type={"text"}
                            value={ele?.header}
                            name="header"
                            onChange={(e) => HandleChange(e, "ageing_table_ageing_capital_work_progress_cwip", ix)}
                          />
                        </td>
                        <td>
                          <input
                            className='p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit'
                            type={"text"}
                            value={formatIndianCurrency(ele?.Less_than_1yr)}
                            name="Less_than_1yr"
                            onChange={(e) => HandleChange(e, 'ageing_table_ageing_capital_work_progress_cwip', ix)}
                          />
                        </td>
                        <td>
                          <input
                            className='p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit'
                            type={"text"}
                            value={formatIndianCurrency(ele['1_to_2yr'])}
                            name="1_to_2yr"
                            onChange={(e) => HandleChange(e, 'ageing_table_ageing_capital_work_progress_cwip', ix)}
                          />
                        </td>
                        <td>
                          <input
                            className='p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit'
                            type={"text"}
                            value={formatIndianCurrency(ele['2_to_3yr'])}
                            name="2_to_3yr"
                            onChange={(e) => HandleChange(e, 'ageing_table_ageing_capital_work_progress_cwip', ix)}
                          />
                        </td>
                        <td>
                          <input
                            className='p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit'
                            type={"text"}
                            value={formatIndianCurrency(ele?.More_than_3_yr)}
                            name="More_than_3_yr"
                            onChange={(e) => HandleChange(e, 'ageing_table_ageing_capital_work_progress_cwip', ix)}
                          />
                        </td>
                        <td>{formatIndianCurrency(calculateTotal("ageing_table_ageing_capital_work_progress_cwip", ele))}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </>
        )
      }
      {/* Net gain on fair value changes */}
      {
        ageingTableData?.ageing_table_Netgain_fair_changes && (
          <>
            <br />
            <div>
              {
                ageingTableData?.ageing_table_Netgain_fair_changes?.headerName && <CkEditor value={ageingTableData?.ageing_table_Netgain_fair_changes?.headerName} onChange={(data) => { HandleChange(data, "ageing_table_Netgain_fair_changes", 'headerName') }} />
              }
              <table>
                <thead >
                  <tr style={{ backgroundColor: "#A2C0C2" }}>
                    <th className="pl-3 w-50 text-left">Particulars</th>
                    <th className="w-auto">For the year ended <br /> {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
                    <th className="w-auto">For the year ended <br /> {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    ageingTableData?.ageing_table_Netgain_fair_changes?.data && ageingTableData?.ageing_table_Netgain_fair_changes?.data.length > 0 &&
                    ageingTableData?.ageing_table_Netgain_fair_changes?.data?.map((ele, ix) => (
                      <>
                        <tr >
                          <td>
                            <textarea
                              className={`p-0 mt-1 w-100 border-0 pl-3 text-left bg-color-inherit ${ele?.isHeading && "font-weight-bold"}`}
                              type={"text"}
                              value={ele?.header}
                              name="header"
                              onChange={(e) => HandleChange(e, "ageing_table_Netgain_fair_changes", ix)}
                            />
                          </td>
                          <td>
                            <input
                              className='p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit'
                              type={"text"}
                              value={formatIndianCurrency(ele?.cy)}
                              name="cy"
                              onChange={(e) => HandleChange(e, 'ageing_table_Netgain_fair_changes', ix)}
                            />
                          </td>
                          <td>
                            <input
                              className='p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit'
                              type={"text"}
                              value={formatIndianCurrency(ele?.py)}
                              name="py"
                              onChange={(e) => HandleChange(e, 'ageing_table_Netgain_fair_changes', ix)}
                            />
                          </td>
                        </tr>
                        {
                          [0, ageingTableData?.ageing_table_Netgain_fair_changes?.data.length - 1].includes(ix) && (
                            <>
                              <tr className="font-weight-bold">
                                <td className='text-left pl-3 font-weight-bold text-secondary'>
                                  {
                                    ix === 0 ? "" :
                                      ix === ageingTableData?.ageing_table_Netgain_fair_changes?.data.length - 1 ? "Total Net gain on fair value changes" : ""
                                  }
                                </td>
                                <td className='text-right pr-3 font-weight-bold text-secondary'>
                                  {
                                    ix === 0 ? formatIndianCurrency(calculateTotal("ageing_table_Netgain_fair_changes", "cy", true, { ix_0: 0, ix_1: 1 })) :
                                      ix === ageingTableData?.ageing_table_Netgain_fair_changes?.data.length - 1 ? formatIndianCurrency(calculateTotal("ageing_table_Netgain_fair_changes", "cy", true, { ix_0: 2, ix_1: 4 })) : ""
                                  }
                                </td>
                                <td className='text-right pr-3 font-weight-bold text-secondary'>
                                  {
                                    ix === 0 ? formatIndianCurrency(calculateTotal("ageing_table_Netgain_fair_changes", "py", true, { ix_0: 0, ix_1: 1 })) :
                                      ix === ageingTableData?.ageing_table_Netgain_fair_changes?.data.length - 1 ? formatIndianCurrency(calculateTotal("ageing_table_Netgain_fair_changes", "py", true, { ix_0: 2, ix_1: 4 })) : ""
                                  }
                                </td>
                              </tr>
                            </>
                          )
                        }
                      </>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </>
        )
      }
      {/* Loans */}
      {
        ageingTableData && ageingTableData?.length > 0 &&
        ageingTableData?.map((ele, ix) => (
          <React.Fragment key={ix}>
            <div className="w-100" style={{ overflowX: "auto", overflowY: "hidden" }} >
              {
                ele?.period && <p className="custom-common-secondary-bg m-0 p-2 pl-3 font-size-16 custom-font-600 w-100" > {ele?.period === "current" ? "Current Year" : "Previous Year"} </p>
              }
              {
                ele?.tableName && <p className="custom-bg-warning m-0 p-2 pl-3 font-size-16 custom-font-600 w-100" > {ele?.tableName} </p>
              }
              <table>
                <thead >
                  <tr style={{ backgroundColor: "#A2C0C2" }}>
                    {
                      ele?.tableHeader && ele?.tableHeader.length > 0 && ele?.tableHeader.map((ele, index) => (
                        <React.Fragment key={index}>
                          <th className={`w-auto ${index === 0 ? "text-left pl-3" : "text-right pr-3"} `}
                            style={{ width: `${index === 0 ? "300px" : "160px"}` }}
                          >{ele}</th>
                        </React.Fragment>
                      ))
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    ele?.data && ele?.data?.length > 0 && ele?.data?.map((el, i) => {
                      const keys = Object.keys(el);
                      return (
                        <tr>
                          <React.Fragment key={i}>
                            {keys.map((keyName, kx) => (
                              <React.Fragment key={kx} >
                                {
                                  keyName === "isInput" ? <></> :
                                    (response?.notes_grp !== "Loans" && keyName === "Total") ? <td className="text-right pr-3" > {formatIndianCurrency(TotalOfaRow(el))} </td> :
                                      <td key={kx} className={`${kx === 0 ? "text-left pl-3" : "text-right pr-3"}`}
                                        style={{ width: `${kx === 0 ? "350px" : "170px"}` }}
                                      >
                                        {kx === 0 ?
                                          el[keyName] && el[keyName].length > 25 ? (
                                            <div className="rtooltip font-sans cursor-pointer" >{el[keyName].substring(0, 25).concat('...')}
                                              <span className="rtooltiptext font-sans">{el[keyName]}</span>
                                            </div>
                                          ) : (
                                            <span>{el[keyName]}</span>
                                          )
                                          : el?.isInput ?
                                            (
                                              <input
                                                className="border-0 w-100 bg-color-inherit m-0 p-0 text-right"
                                                type="text"
                                                name={keyName}
                                                value={formatIndianCurrency(el[keyName])}
                                                onChange={(e) => HandleChange(e, response?.notes_grp, ix, i)}
                                              />
                                            )
                                            : typeof el[keyName] === "number" ? formatIndianCurrency(el[keyName]) : el[keyName]
                                        }
                                      </td>
                                }
                              </React.Fragment>
                            ))}
                          </React.Fragment>
                        </tr>
                      )
                    }
                    )
                  }
                </tbody>
              </table>
            </div>
          </React.Fragment>
        ))
      }
      {/*  Other equity */}
      {
        ageingTableData?.other_equity && (
          <>
            <div>
              {
                ageingTableData?.other_equity?.headerName && <CkEditor value={ageingTableData?.other_equity?.headerName} onChange={(data) => { HandleChange(data, 'other_equity', 'headerName') }} />
              }
            </div>
          </>
        )
      }
      {
        ageingTableData?.other_equity_1 && (
          <>
            <br />
            <div>
              {
                ageingTableData?.other_equity_1?.headerName && <CkEditor value={ageingTableData?.other_equity_1?.headerName} onChange={(data) => { HandleChange(data, 'other_equity_1', 'headerName') }} />
              }
              <table>
                <thead >
                  <tr style={{ backgroundColor: "#A2C0C2" }}>
                    <th className="pl-3 w-75 text-left">Particulars</th>
                    <th className="w-auto">As at {moment(reduxProjectData.current_year).format("DD MMMM YYYY")}</th>
                    <th className="w-auto">As at {moment(reduxProjectData.previous_year).format("DD MMMM YYYY")} </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    ageingTableData?.other_equity_1?.data && ageingTableData?.other_equity_1?.data.length > 0 &&
                    ageingTableData?.other_equity_1?.data?.map((ele, ix) => (
                      <>
                        <tr >
                          <td>
                            <input
                              className={`p-0 mt-1 w-100 border-0 pl-3 text-left bg-color-inherit ${ele?.isHeading && "font-weight-bold"}`}
                              type={"text"}
                              value={ele?.header}
                              name="header"
                              onChange={(e) => HandleChange(e, "other_equity_1", ix)}
                            />
                          </td>
                          <td>
                            <input
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                              type={"text"}
                              value={formatIndianCurrency(ele?.cy)}
                              readOnly={typeof ele?.cy !== "number"}
                              name="cy"
                              onChange={(e) => HandleChange(e, 'other_equity_1', ix)}
                            />
                          </td>
                          <td>
                            <input
                              className="p-0 m-0 w-100 border-0 pr-3 text-right bg-color-inherit"
                              type={"text"}
                              value={formatIndianCurrency(ele?.py)}
                              readOnly={typeof ele?.py !== "number"}
                              name="py"
                              onChange={(e) => HandleChange(e, 'other_equity_1', ix)}
                            />
                          </td>
                        </tr>
                        {
                          [2, 4, 6, 8, 10, 12, 14, 16, ageingTableData?.other_equity_1?.data.length - 1].includes(ix) && (
                            <>
                              <tr className="font-weight-bold">
                                <td className="text-left pl-3 font-weight-bold text-secondary">
                                  {
                                    ix === 2 ? "" :
                                      ix === 4 ? "" :
                                        ix === 6 ? "" :
                                          ix === 8 ? "" :
                                            ix === 10 ? "" :
                                              ix === 12 ? "" :
                                                ix === 14 ? "" :
                                                  ix === 16 ? "" :
                                                    ix === ageingTableData?.other_equity_1?.data.length - 1 ? "" : ""
                                  }
                                </td>
                                <td className="text-right pr-3 font-weight-bold text-secondary">
                                  {
                                    ix === 2 ? formatIndianCurrency(calculateTotal("other_equity_1", "cy", true, { ix_0: 0, ix_1: 3 })) :
                                      ix === 4 ? formatIndianCurrency(calculateTotal("other_equity_1", "cy", true, { ix_0: 3, ix_1: 5 })) :
                                        ix === 6 ? formatIndianCurrency(calculateTotal("other_equity_1", "cy", true, { ix_0: 5, ix_1: 7 })) :
                                          ix === 8 ? formatIndianCurrency(calculateTotal("other_equity_1", "cy", true, { ix_0: 7, ix_1: 9 })) :
                                            ix === 10 ? formatIndianCurrency(calculateTotal("other_equity_1", "cy", true, { ix_0: 9, ix_1: 11 })) :
                                              ix === 12 ? formatIndianCurrency(calculateTotal("other_equity_1", "cy", true, { ix_0: 11, ix_1: 13 })) :
                                                ix === 14 ? formatIndianCurrency(calculateTotal("other_equity_1", "cy", true, { ix_0: 13, ix_1: 15 })) :
                                                  ix === 16 ? formatIndianCurrency(calculateTotal("other_equity_1", "cy", true, { ix_0: 15, ix_1: 17 })) :
                                                    ix === ageingTableData?.other_equity_1?.data.length - 1 ? formatIndianCurrency(calculateTotal("other_equity_1", "cy", true, { ix_0: 17, ix_1: 22 })) : ""
                                  }
                                </td>
                                <td className="text-right pr-3 font-weight-bold text-secondary">
                                  {
                                    ix === 2 ? formatIndianCurrency(calculateTotal("other_equity_1", "py", true, { ix_0: 0, ix_1: 3 })) :
                                      ix === 4 ? formatIndianCurrency(calculateTotal("other_equity_1", "py", true, { ix_0: 3, ix_1: 5 })) :
                                        ix === 6 ? formatIndianCurrency(calculateTotal("other_equity_1", "py", true, { ix_0: 5, ix_1: 7 })) :
                                          ix === 8 ? formatIndianCurrency(calculateTotal("other_equity_1", "py", true, { ix_0: 7, ix_1: 9 })) :
                                            ix === 10 ? formatIndianCurrency(calculateTotal("other_equity_1", "py", true, { ix_0: 9, ix_1: 11 })) :
                                              ix === 12 ? formatIndianCurrency(calculateTotal("other_equity_1", "py", true, { ix_0: 11, ix_1: 13 })) :
                                                ix === 14 ? formatIndianCurrency(calculateTotal("other_equity_1", "py", true, { ix_0: 13, ix_1: 15 })) :
                                                  ix === 16 ? formatIndianCurrency(calculateTotal("other_equity_1", "py", true, { ix_0: 15, ix_1: 17 })) :
                                                    ix === ageingTableData?.other_equity_1?.data.length - 1 ? formatIndianCurrency(calculateTotal("other_equity_1", "py", true, { ix_0: 17, ix_1: 22 })) : ""
                                  }
                                </td>
                              </tr>
                            </>
                          )
                        }
                      </>
                    ))
                  }
                  <tr className="font-weight-bold">
                    <td className="text-left pl-3 font-weight-bold text-secondary">Other Equity</td>
                    <td className="text-right pr-3 font-weight-bold text-secondary">{formatIndianCurrency(calculateTotal("other_equity_1", "cy"))}</td>
                    <td className="text-right pr-3 font-weight-bold text-secondary">{formatIndianCurrency(calculateTotal("other_equity_1", "py"))}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )
      }
    </React.Fragment>

  );
}