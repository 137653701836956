import React, {useState, useEffect} from "react"
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/system';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { getSoftDeletedNotes, restoreSoftDeletedNotes } from "../../../services/api/apiHandler";
import swal from "sweetalert";
import { socket } from "../../../services/socket/socket";
import { fetchTTBSData } from "../../../redux/actions/TemplateTabs/actionBS";
import { useDispatch } from "react-redux";



const RestoreSoftDelete = (props) => {
    const user = JSON.parse(localStorage.getItem('user'));
     const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [deletedNotes, setDeletedNotes] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const project_id = localStorage.getItem("project_id");
    const tb_id = localStorage.getItem("tb_id");

    const handleToggleSelectAll = () => {
        if (selectedRows.length < deletedNotes.length) {
          // If no rows are selected, select all rows
          setSelectedRows([...deletedNotes]);
        } else {
          // If some rows are selected, unselect all rows
          setSelectedRows([]);
        }
      };
    
      const handleRowCheckboxChange = (row) => {
        if (selectedRows.find((item)=>item.notes_no === row.notes_no)) {
          // If row is already selected, remove it from selectedRows
          setSelectedRows(selectedRows.filter((item) => item.notes_no !== row.notes_no));
        } else {
          // If row is not selected, add it to selectedRows
          setSelectedRows([...selectedRows, row]);
        }
      };

    useEffect(()=> {
        fetchSoftDeletedData();
    }, [])

    const fetchSoftDeletedData = () => {
        getSoftDeletedNotes(project_id).then((response)=> {
            if(typeof response.data.result !== 'string'){
                setDeletedNotes(response.data.result);
            }
        }).catch((err)=> {
            swal("Error!", err.message, "danger", {
                button: "OK",
            });
        })
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleRestore = () => {
        // Handle restore action here
        if(selectedRows.length === 0){
            return swal("Warning!","select at-least one row for note restore.", "warning", {
                button: "OK",
            });
        }

        swal({
            title: "Are you sure to restore the notes?",
            text: "you can again delete this note!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                const payload = {
                    selectedNotes: selectedRows
                }
                restoreSoftDeletedNotes(project_id, payload).then(()=> {
                    dispatch(fetchTTBSData(project_id, tb_id))
                    fetchSoftDeletedData();
                    setTimeout(()=>{
                        socket.emit("request-mapped-grouping", {project_id, tb_id});
                        socket.emit("request-balance-sheet", {project_id, tb_id})
                        socket.emit("request-pnl-statement", {project_id, tb_id});
                        socket.emit("request-fetch-console-notes", { pid: project_id });
                        
                        handleClose();
                    }, [2000])
                    swal("Poof! Selected Notes has been restored!", {
                        icon: "success",
                    });
                }).catch((err)=> {
                    swal("Error!", err.message, "error", {
                        button: "OK",
                    });
                })
            }
          });
        
      };
    
      const handleCancel = () => {
        // Handle cancel action here
        setSelectedRows([]);
        handleClose();
      };

    return (
        <div>
            <Tooltip title="Notes Soft Delete">
                <IconButton onClick={handleOpen}>
                    <RestoreFromTrashIcon/>
                </IconButton>
            </Tooltip>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 800, // Adjust the width of the modal
                height: 600,
                bgcolor: 'white', // Set background color to white
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
                }}>
                <h2 id="modal-modal-title">Restore Deleted Notes</h2>
                <p id="modal-modal-description">Please select the rows and restore those notes.</p>
                <div style={{height: "25rem", overflowY: 'auto'}}>
                    <Table>
                        <TableHead>
                        <TableRow>
                            <TableCell>
                            <input
                            type="checkbox"
                            checked={selectedRows.length > 0 && selectedRows.length === deletedNotes.length}
                            onChange={handleToggleSelectAll}
                            />
                            </TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>FS Group</TableCell>
                            <TableCell>Notes Group</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {deletedNotes.map((note, key)=> (
                                <TableRow key={key}>
                                    <TableCell>
                                        <input
                                        type="checkbox" 
                                        checked={selectedRows.find((item)=>item.notes_no === note.notes_no)}
                                        onChange={() => handleRowCheckboxChange(note)}
                                        />
                                    </TableCell>
                                    <TableCell>{note.type}</TableCell>
                                    <TableCell>{note.group}</TableCell>
                                    <TableCell>{note.notes_grp}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
                    <Box sx={{ mt: 2, textAlign: 'center' }}>
                        <button className="custom-common-theme-bg-color px-2 py-1 text-white custom-font-600 radius-6px primary-border" onClick={handleRestore} disabled={user.responsibilities === "approver" || user.responsibilities === "view"}>
                        Restore
                        </button>
                        <button className="bg-danger px-2 py-1 text-white custom-font-600 radius-6px ml-2 border-danger" onClick={handleCancel}>
                        Cancel
                        </button>
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}


export default RestoreSoftDelete;

